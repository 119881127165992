export default function Minicard({title, number, rate}) {
  return (
    <div className="minicard">
        <svg
          width="43"
          height="43"
          viewBox="0 0 43 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1243_3028)">
            <path
              d="M21.6095 42.5615C33.027 42.5615 42.2828 33.3057 42.2828 21.8882C42.2828 10.4706 33.027 1.21484 21.6095 1.21484C10.1919 1.21484 0.936157 10.4706 0.936157 21.8882C0.936157 33.3057 10.1919 42.5615 21.6095 42.5615Z"
              stroke="#EFF3FA"
              stroke-width="0.558738"
            />
            <path
              d="M12.8258 19.445C13.9426 19.445 14.8479 18.5397 14.8479 17.423C14.8479 16.3062 13.9426 15.4009 12.8258 15.4009C11.709 15.4009 10.8037 16.3062 10.8037 17.423C10.8037 18.5397 11.709 19.445 12.8258 19.445Z"
              fill="#134271"
            />
            <path
              d="M31.0245 19.445C32.1413 19.445 33.0466 18.5397 33.0466 17.423C33.0466 16.3062 32.1413 15.4009 31.0245 15.4009C29.9078 15.4009 29.0024 16.3062 29.0024 17.423C29.0024 18.5397 29.9078 19.445 31.0245 19.445Z"
              fill="#134271"
            />
            <path
              d="M17.3755 27.0278V29.0499C17.3755 29.6666 17.0014 30.2024 16.4757 30.4249C16.2836 30.5159 16.0814 30.5664 15.859 30.5664H13.8369C12.9977 30.5664 12.3203 29.889 12.3203 29.0499V27.0278C12.3203 26.1886 12.9977 25.5112 13.8369 25.5112H15.859C16.6981 25.5112 17.3755 26.1886 17.3755 27.0278Z"
              fill="#134271"
            />
            <path
              d="M31.5301 27.0278V29.0499C31.5301 29.889 30.8527 30.5664 30.0135 30.5664H27.9914C27.769 30.5664 27.5668 30.5159 27.3747 30.4249C26.8489 30.2024 26.4749 29.6666 26.4749 29.0499V27.0278C26.4749 26.1886 27.1523 25.5112 27.9914 25.5112H30.0135C30.8527 25.5112 31.5301 26.1886 31.5301 27.0278Z"
              fill="#134271"
            />
            <path
              d="M24.9583 15.9062V18.9393C24.9583 19.7684 24.2708 20.4559 23.4418 20.4559H20.4087C19.5796 20.4559 18.8921 19.7684 18.8921 18.9393V15.9062C18.8921 15.0772 19.5796 14.3896 20.4087 14.3896H23.4418C24.2708 14.3896 24.9583 15.0772 24.9583 15.9062Z"
              fill="#134271"
            />
            <g opacity="0.4">
              <path
                opacity="0.4"
                d="M18.892 16.6646H14.8479C14.4334 16.6646 14.0896 17.0083 14.0896 17.4228C14.0896 17.8374 14.4334 18.1811 14.8479 18.1811H17.4463C15.3736 19.7179 14.0896 22.2657 14.0896 25.0056C14.0896 25.2078 14.0997 25.4 14.13 25.6022C14.1705 25.9864 14.5041 26.2694 14.8782 26.2694C14.9085 26.2694 14.9389 26.2694 14.9692 26.2694C15.3837 26.2189 15.687 25.8448 15.6365 25.4303C15.6163 25.2887 15.6163 25.1573 15.6163 25.0158C15.6163 22.3871 17.0418 19.9605 19.2459 18.8484C19.62 18.6563 19.7717 18.2014 19.5796 17.8273C19.5694 17.8172 19.5593 17.807 19.5593 17.7969C19.62 17.6857 19.6705 17.5644 19.6705 17.433C19.6503 17.0083 19.3066 16.6646 18.892 16.6646Z"
                fill="#134271"
              />
              <path
                opacity="0.4"
                d="M26.404 18.1811H29.0024C29.4169 18.1811 29.7607 17.8374 29.7607 17.4228C29.7607 17.0083 29.4169 16.6646 29.0024 16.6646H24.9582C24.5437 16.6646 24.2 17.0083 24.2 17.4228C24.2 17.5543 24.2404 17.6756 24.3112 17.7868C24.3011 17.7969 24.2909 17.807 24.2909 17.8172C24.0988 18.1912 24.2505 18.6462 24.6246 18.8383C26.8287 19.9505 28.2542 22.3769 28.2542 25.0056C28.2542 25.1472 28.2441 25.2786 28.234 25.4201C28.1835 25.8347 28.4868 26.2088 28.9013 26.2593C28.9316 26.2593 28.962 26.2593 28.9923 26.2593C29.3765 26.2593 29.7 25.9763 29.7405 25.5921C29.7607 25.3899 29.7809 25.1977 29.7809 24.9955C29.7607 22.2657 28.4767 19.7179 26.404 18.1811Z"
                fill="#134271"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_1243_3028">
              <rect
                width="41.9054"
                height="41.9054"
                fill="white"
                transform="translate(0.656738 0.935059)"
              />
            </clipPath>
          </defs>
        </svg>
      <div>
        <h4>{title}</h4>
        <div className="minicard-start"> 
          <p>{number} skills</p>
          <svg
            width="20"
            height="20"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3493 3.60505L16.1287 7.16392C16.3714 7.65933 17.0184 8.13452 17.5644 8.22551L20.7896 8.76136C22.8521 9.10512 23.3374 10.6015 21.8512 12.0776L19.3438 14.585C18.9192 15.0096 18.6866 15.8285 18.8181 16.4149L19.5359 19.5188C20.1021 21.9757 18.7979 22.9261 16.6241 21.642L13.6011 19.8525C13.0551 19.529 12.1553 19.529 11.5992 19.8525L8.57623 21.642C6.4126 22.9261 5.09825 21.9656 5.66443 19.5188L6.38227 16.4149C6.51371 15.8285 6.28117 15.0096 5.85653 14.585L3.34915 12.0776C1.87303 10.6015 2.34822 9.10512 4.41074 8.76136L7.63596 8.22551C8.17182 8.13452 8.81888 7.65933 9.06153 7.16392L10.841 3.60505C11.8116 1.67397 13.3888 1.67397 14.3493 3.60505Z"
              fill="#F4C520"
            />
          </svg> 

          <p>{rate}</p>
        </div>
      </div>
    </div>
  );
}
