import { useNavigate, useParams } from "react-router-dom";
import { ProfileCertification } from "../../../components/dashboard/profile_details/ProfileCertification";
import ProfileDetails from "../../../components/dashboard/profile_details/ProfileDetails";
import MainLayout from "../../../layouts/mainLayout";
import "../../../styles/dashboard/dashboardProfile.scss";
import { AiOutlineLeft } from "react-icons/ai";
import { BounceLoader } from "react-spinners";

export default function ProfileDetail() {
  const { applicantId } = useParams();
  const navigate = useNavigate();

  return (
    <MainLayout navTitle={"Profile"}>
      <div
        className="breadcrumb pointer"
        onClick={() => navigate(-1)}
        style={{ marginTop: "30px" }}
      >
        <div className="icon">
          <AiOutlineLeft />
        </div>
        <div className="text">Back</div>
      </div>
      {/* {isLoading ? (
        <div className="postJob-preview-loader">
          <BounceLoader size="100px" color="#134271" />
        </div>
      ) : ( */}
      <div className="dashboard-profile-container">
        <ProfileDetails applicantId={applicantId} />
        <ProfileCertification applicantId={applicantId} />
      </div>
      {/* )} */}
    </MainLayout>
  );
}
