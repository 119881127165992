import React, { useState } from "react";
import "./styles.scss";

export default function BorderIdentityTag({
  image,
  header,
  description,
  onClick,
  isSelected,
  onSelect,
}) {
  // const [check, setCheck] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // function handleRadioChange() {
  //   const radioChecked = document.querySelector(
  //     'input[name="identity"]:checked'
  //   );
  //   if (radioChecked) {
  //     setCheck(true);
  //   } else {
  //     setCheck(false);
  //   }
  // }

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  const handleRadioChange = () => {
    onSelect();
  };

  return (
    <div
      style={{
        border: isSelected || isHovered ? "2px solid #134271" : "",
      }}
      className="borderIdentityTag_container"
      onClick={onSelect}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="borderIdentityTag_img">
        <img src={image} alt="" />
      </div>
      <div className="borderIdentityTag_body">
        <div> {header}</div>
        <span> {description} </span>
      </div>
      <div className="borderIdentityTag_check">
        <input type="radio" name="identity" onChange={handleRadioChange} />
      </div>
    </div>
  );
}
