import React from "react";

const Filter = ({
  icon,
  filterCount,
  filterName,
  color,
  bgColor,
  border,
  onClick,
  countColor,
  countBg,
}) => {
  return (
    <div
      className="single-filter-container"
      onClick={onClick}
      style={{ color: color, backgroundColor: bgColor, border: border }}
    >
      <img src={icon} alt="filter icon" />
      <p>{filterName}</p>
      <p
        style={{
          color: countColor,
          backgroundColor: countBg,
        }}
      >
        {filterCount}
      </p>
    </div>
  );
};

export default Filter;
