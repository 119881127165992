import { useState } from "react";
import NavBar from "../../components/dashboard/NavBar";
import MenuOptions from "../../components/dashboard/navigation/MenuOptions";
import Sidebar from "../../components/dashboard/sidebar";

export default function MainLayout({ children, navTitle }) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div onClick={() => setShowMenu(false)}>
      {
        <NavBar
          navTitle={navTitle}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      }
      {<Sidebar />}

      <div className="app-body">{children}</div>
      <div className="modals">{showMenu && <MenuOptions />}</div>
    </div>
  );
}
