import Footer from "../../common/website/Footer";
import Navigation from "../../common/website/Navbar";
import AnyQuestionsWeCanHelpWith from "../../components/website/AnyQuestion";
import StillHaveQuestions from "../../components/website/StillHaveQuestions";

export default function Faq() {
  return (
    <div>
      <Navigation />
      <AnyQuestionsWeCanHelpWith />
      <StillHaveQuestions />
      <Footer />
    </div>
  );
}
