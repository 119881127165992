import { useEffect, useState } from "react";
import OutlinedButton from "../../common/button/outlinedButton";
import emptyState from "../../../assets/dashboard/icons/EmptyState.svg";
import EmptyState from "../saved/EmptyState";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";

export const ProfileCertification = ({ applicantId }) => {
  const [certifications, setCertifications] = useState([]);

  useEffect(() => {
    const getCertifications = async () => {
      try {
        const response = await axiosInstance.get(
          `/applicants/user/${applicantId}`
        );
        const res = response.data.data.cerificates;
        setCertifications(res);
        // const successMessage = response.data.message || "Certificates fetched";
        // ShowToast({ type: "success", text: successMessage });
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Error fetching Certificates";
        // setIsLoading(false);
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    getCertifications();
  }, [applicantId]);

  return (
    <div className="dashboard-profile-certifications-container">
      <div className="dashboard-profile-add-certifications-container">
        <div className="dashboard-profile-add-certifications">
          <h3>Certifications</h3>
          {/* <img src={AddCertificate} alt="add certificate" /> */}
        </div>
      </div>

      {/* CERTIFICATION LIST */}

      {certifications?.length ? (
        certifications.map((cert, i) => (
          <div
            key={i}
            className="dashboard-profile-list-certifications-container"
          >
            <div className="dashboard-profile-list-certifications">
              <div>
                <img src={cert.image} alt={cert.name} />
              </div>
              <div className="dashboard-profile-list-certifications-details">
                <h4>{cert.name}</h4>
                <p>{cert.type}</p>
                <p>{cert.credential_id}</p>
                <OutlinedButton text="Show credential" padding="10px 23px" />
              </div>
              {/* <OutlinedButton text="Remove" icon={Trash} padding="10px 17px" /> */}
            </div>
          </div>
        ))
      ) : (
        <EmptyState
          title={"No certifications"}
          subtitle={"All Certifications will appear here"}
          img={emptyState}
        />
      )}
    </div>
  );
};
