import React from "react";
import VerticalLabelInput from "../common/input/verticalLabelInput";
import BackButton from "../common/button/backButton";
import Button from "../common/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";
import ShowToast from "../../common/website/Toast";

export default function DisplayName({ onNextStep }) {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(userDetails.individualHiring);

  const handleCheckboxChange = () => {
    dispatch(
      setUserDetails({
        ...userDetails,
        individualHiring: !userDetails.individualHiring,
      })
    );
  };

  const navigateTo = () => {
    navigate("/register/{goal}");
  };

  const handleNext = () => {
    if (userDetails.displayName.trim() === "") {
      ShowToast({ type: "error", text: "Please fill in a Display Name" });
      return;
    }
    onNextStep();
  };
  return (
    <div>
      {userDetails.type === "Talent" ? (
        <div className="enterDetails_container">
          <div className="steps">Step 2/5</div>
          <h1>Choose the name you wish to showcase</h1>
          <span>Showcase a name you want client to see</span>
          <VerticalLabelInput
            label="Display name"
            placeholder="Enter display name"
            value={userDetails.displayName}
            onChange={(e) =>
              dispatch(
                setUserDetails({ ...userDetails, displayName: e.target.value })
              )
            }
          />
          <div className="enterDetails_buttons">
            <BackButton fn={navigateTo} text="Back" />
            <Button fn={handleNext} text="Next" />
          </div>
        </div>
      ) : (
        // CLIENT
        <div className="enterDetails_container">
          <div className="steps">Step 1/3</div>
          <h1>Complete profile setup</h1>
          <span>Provide additional information to complete profile</span>
          <VerticalLabelInput
            label="Company name"
            placeholder="Enter company name"
            value={userDetails.displayName}
            onChange={(e) =>
              dispatch(
                setUserDetails({ ...userDetails, displayName: e.target.value })
              )
            }
          />
          <div style={{ marginBottom: "10px" }}>
            <label
              style={{
                fontWeight: 500,
                fontSize: "18px",
                color: "#848FAC",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                checked={userDetails.individualHiring}
                onChange={handleCheckboxChange}
                style={{
                  width: "24px",
                  height: "24px",
                  border: `1px solid ${
                    !userDetails.individualHiring ? "#EFF3FA" : ""
                  }`,
                  marginRight: "8px",
                  position: "relative",
                  backgroundColor: `${
                    userDetails.individualHiring ? "red" : "transparent"
                  }`,
                  color: `${
                    userDetails.individualHiring ? "white" : "transparent"
                  }`,
                }}
              />
              Hiring as an individual
            </label>
          </div>
          <div className="enterDetails_buttons">
            <Button fn={handleNext} text="Next" />
          </div>
        </div>
      )}
    </div>
  );
}
