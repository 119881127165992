import "../../styles/website/SingleBlogPage.css";
import SingleBlogPageWriteupImage from "../../assets/website/images/Learning-About-Data-Is-Easier-Than-You-Think 1.svg";
export default function SingleBlogPageWriteup() {
  return (
    <div className="single-blog-writeup">
      <div className="written-by-PSP-team">
        <p>Written by the PSP team </p>
        <svg
          width="10"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="6.3" cy="7" rx="6.3" ry="6.75" fill="#F47C20" />
        </svg>
        <p>August 10, 2023.</p>
      </div>
      <p>
        On average, it takes our Ignite teams eight to 12 weeks from onboarding
        to our final readout. There’s no time to waste. So, the first 48 hours
        are crucial to understanding our customer’s business, their strategic
        context, and competition. But what if we can cut that time to only 48
        seconds?
      </p>
      <h4>AI assistant as a research wrangler</h4>
      <p>
        One of the most important aspects of our work is primary research. It
        helps us understand our customers’ challenges. Having a research
        assistant synthesize interview transcripts, identify top themes, and
        pull out key quotes would be a useful tool in that process. Typically,
        we conduct about 15 to 20 – or more – interviews per project.
        Transcripts are already automated, thanks to tools such as Otter.ai or
        Google Hangouts. But synthesizing these transcripts can be tedious work.
        Synthesizing an entire research stream can take many hours up to
        multiple days. There are a few gotchas, however:
      </p>

      <p>
        AI Sidekick Score: 8 – Provided a fast and easy way to summarize and
        synthesize transcripts that is on par with a human result. However, the
        level of insights was sub-par compared to a human. The quotes it chose
        weren’t always relevant and sometimes misattributed. Summary: Overall,
        we’ve been very happy with our AI research assistant. We were able to
        get synthesized summaries minutes after we got the transcripts. But it’s
        no replacement for a human researcher. To get real, useful insights, we
        still need a human brain. It’s a time saver, but not a replacement.
      </p>

      <p>
        AI Sidekick Score: 8 – Provided a fast and easy way to summarize and
        synthesize transcripts that is on par with a human result. However, the
        level of insights was sub-par compared to a human. The quotes it chose
        weren’t always relevant and sometimes misattributed. Summary: Overall,
        we’ve been very happy with our AI research assistant. We were able to
        get synthesized summaries minutes after we got the transcripts. But it’s
        no replacement for a human researcher. To get real, useful insights, we
        still need a human brain. It’s a time saver, but not a replacement.
      </p>

      <figure>
        <img src={SingleBlogPageWriteupImage} alt="content-img" />
      </figure>
      <p>
        AI Sidekick Score: 8 – Provided a fast and easy way to summarize and
        synthesize transcripts that is on par with a human result. However, the
        level of insights was sub-par compared to a human. The quotes it chose
        weren’t always relevant and sometimes misattributed. Summary: Overall,
        we’ve been very happy with our AI research assistant. We were able to
        get synthesized summaries minutes after we got the transcripts. But it’s
        no replacement for a human researcher. To get real, useful insights, we
        still need a human brain. It’s a time saver, but not a replacement.
      </p>
    </div>
  );
}
