import React from "react";
import "./styles.scss";

export default function ColoredButton({
  text,
  onClick,
  backgroundColor,
  padding,
  color,
  fontWeight,
  lineHeight,
  width,
}) {
  return (
    <div className="colored-button-container">
      <button
        onClick={onClick}
        type="button"
        className="button"
        style={{
          padding,
          backgroundColor,
          color,
          fontWeight,
          lineHeight,
          width,
        }}
      >
        {text}
      </button>
    </div>
  );
}
