export const formatTime = (createdAt) => {
  const currentTime = new Date();
  const createdTime = new Date(createdAt);
  const timeDiff = Math.abs(currentTime - createdTime);
  const minutes = Math.floor(timeDiff / (1000 * 60));
  const hours = Math.floor(minutes / 60);

  if (hours < 1) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  } else if (hours < 24) {
    return `${hours} ${hours === 1 ? "hour" : "hours"}`;
  } else if (hours < 24 * 7) {
    return `${Math.floor(hours / 24)} ${
      Math.floor(hours / 24) === 1 ? "day" : "days"
    }`;
  } else if (hours < 24 * 7 * 4) {
    return `${Math.floor(hours / (24 * 7))} ${
      Math.floor(hours / (24 * 7)) === 1 ? "week" : "weeks"
    }`;
  } else {
    return `${Math.floor(hours / (24 * 7 * 4))} ${
      Math.floor(hours / (24 * 7 * 4)) === 1 ? "month" : "months"
    }`;
  }
};
