import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import * as Yup from "yup";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";

const CertificateModal = (props) => {
  const [currentSkill, setCurrentSkill] = useState("");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const generatePastYearOptions = (startYear, endYear) => {
    const options = [];
    for (let year = startYear; year >= endYear; year--) {
      options.push({
        label: year.toString(),
        value: year.toString(),
      });
    }
    return options;
  };

  // Usage
  const currentYear = new Date().getFullYear();
  const pastYearRange = 40; // or any desired number of past years
  const startYear = currentYear - pastYearRange;
  const pastYearOptions = generatePastYearOptions(currentYear, startYear);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    issuingOrg: Yup.string().required("Issuing organization is required"),
    certImage: Yup.string(),
    issueDateMonth: Yup.string().required("Issue date month is required"),
    issueDateYear: Yup.string().required("Issue date year is required"),
    expirationDateMonth: Yup.string().required(
      "Expiration date month is required"
    ),
    expirationDateYear: Yup.string().required(
      "Expiration date year is required"
    ),
    credentialId: Yup.string().required("Credential ID is required"),
    credentialUrl: Yup.string().required("Credential URL is required"),
    skills: Yup.array()
      .of(Yup.string())
      .min(1, "At least one skill is required"),
  });

  const initialValues = {
    name: "",
    issuingOrg: "",
    certImage: "",
    issueDateMonth: "",
    issueDateYear: "",
    expirationDateMonth: "",
    expirationDateYear: "",
    credentialId: "",
    credentialUrl: "",
    skills: [],
  };

  // Cert upload
  const handleUploadCertifications = async (values, { resetForm }) => {
    try {
      //   setIsLoading(true);
      const response = await axiosInstance.post(`/profile/certificate`, {
        name: values.name,
        type: values.issuingOrg,
        image: values.certImage,
        start_month: values.issueDateMonth,
        start_year: values.issueDateYear,
        end_month: values.expirationDateMonth,
        end_year: values.expirationDateYear,
        credential_id: values.credentialId,
        credential_url: values.credentialUrl,
        skills: values.skills,
      });
      props.onClose();
      props.getCert();

      const successMessage =
        response.data.message || "You have successfully uploaded a certificate";
      ShowToast({ type: "success", text: successMessage });
      //   setIsLoading(false);
    } catch (error) {
      props.onClose();
      const errorMessage =
        error?.response?.data?.error || "Error uploading certificate";
      //   setIsLoading(false);
      ShowToast({ type: "error", text: errorMessage });
    }

    resetForm();
  };

  return (
    <div className="certificate-modal-container" onClick={props.handleClick}>
      <div
        className="certificate-modal-content"
        // style={{ maxWidth: "664px !important", width: "100% !important" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="close-certificate-modal">
          <button onClick={props.handleClick} className="pointer">
            <IoClose />
          </button>
        </div>
        <div className="certification-modal-form-container">
          <p>Add license or certification</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleUploadCertifications}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="certificate-form-container">
                  {/* Name */}
                  <div className="certificate-input-container">
                    <label htmlFor="name">Name</label>
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      className="certificate-form-input"
                      placeholder="Ex. Sales force"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error"
                    />
                  </div>

                  {/* Issuing organization */}
                  <div className="certificate-input-container">
                    <label htmlFor="issuingOrg">Issuing organisation</label>
                    <Field
                      type="text"
                      name="issuingOrg"
                      id="issuingOrg"
                      placeholder="Ex. Microsoft"
                      className="certificate-form-input"
                      value={values.issuingOrg}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="issuingOrg"
                      component="div"
                      className="error"
                    />
                  </div>

                  {/* Certificate Image */}
                  {/* <div className="certificate-input-container">
                    <label htmlFor="certImage">Certificate Image</label>
                    <Field
                      type="text"
                      name="certImage"
                      id="certImage"
                      placeholder="www.cloud.mycertimage.com"
                      className="certificate-form-input"
                      value={values.certImage}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="certImage"
                      component="div"
                      className="error"
                    />
                  </div> */}

                  {/* Issue Date */}
                  <div className="certificate-input-container-flex">
                    <div className="certificate-input-container">
                      <label htmlFor="issueDateMonth">Issue date</label>
                      <Field
                        as="select"
                        name="issueDateMonth"
                        id="issueDateMonth"
                        placeholder="Month"
                        className="certificate-form-input"
                        value={values.issueDateMonth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled hidden>
                          Month
                        </option>
                        {months.map((m, i) => (
                          <option key={i} value={m}>
                            {m}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="issueDateMonth"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div
                      className="certificate-input-container"
                      style={{ marginTop: "20px" }}
                    >
                      {/* <label htmlFor="issueDateYear">Confirm Password</label> */}
                      <Field
                        as="select"
                        name="issueDateYear"
                        id="issueDateYear"
                        placeholder="Year"
                        className="certificate-form-input"
                        value={values.issueDateYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled hidden>
                          Year
                        </option>
                        {pastYearOptions.map((year, index) => (
                          <option key={index} value={year.value}>
                            {year.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="issueDateYear"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  {/* Expiry Date */}
                  <div className="certificate-input-container-flex">
                    <div className="certificate-input-container">
                      <label htmlFor="expirationDateMonth">
                        Expiration date
                      </label>
                      <Field
                        as="select"
                        name="expirationDateMonth"
                        id="expirationDateMonth"
                        placeholder="Month"
                        className="certificate-form-input"
                        value={values.expirationDateMonth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled hidden>
                          Month
                        </option>
                        {months.map((m, i) => (
                          <option key={i} value={m}>
                            {m}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="expirationDateMonth"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div
                      className="certificate-input-container"
                      style={{ marginTop: "20px" }}
                    >
                      {/* <label htmlFor="issueDateYear">Confirm Password</label> */}
                      <Field
                        as="select"
                        name="expirationDateYear"
                        id="expirationDateYear"
                        placeholder="Year"
                        className="certificate-form-input"
                        value={values.expirationDateYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled hidden>
                          Year
                        </option>
                        {pastYearOptions.map((year, index) => (
                          <option key={index} value={year.value}>
                            {year.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="expirationDateYear"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  {/* Credential ID */}
                  <div className="certificate-input-container">
                    <label htmlFor="credentialId">Credential ID</label>
                    <Field
                      type="text"
                      name="credentialId"
                      id="credentialId"
                      className="certificate-form-input"
                      value={values.credentialId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="credentialId"
                      component="div"
                      className="error"
                    />
                  </div>

                  {/* Credential URL */}
                  <div className="certificate-input-container">
                    <label htmlFor="credentialUrl">Credential URL</label>
                    <Field
                      type="text"
                      name="credentialUrl"
                      id="credentialUrl"
                      className="certificate-form-input"
                      value={values.credentialUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="credentialUrl"
                      component="div"
                      className="error"
                    />
                  </div>

                  {/* Skills */}
                  <div className="certificate-input-container">
                    <label htmlFor="skills">Skills</label>
                    <p>
                      Associate at least 1 skill to this license or
                      certification. It’ll also appear in your skills section.
                    </p>
                    {/* Display the list of skills */}
                    {values.skills.length > 0 && (
                      <div>{values.skills.join(", ")}</div>
                    )}
                    <Field
                      type="text"
                      name="skills"
                      id="skills"
                      placeholder="Ex. Project management"
                      className="certificate-form-input"
                      value={currentSkill}
                      onChange={(e) => setCurrentSkill(e.target.value)}
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && currentSkill.trim() !== "") {
                          e.preventDefault(); // Prevent form submission on "Enter"
                          const newSkills = [
                            ...values.skills,
                            currentSkill.trim(),
                          ];
                          handleChange({
                            target: { name: "skills", value: newSkills },
                          });
                          setCurrentSkill("");
                        }
                      }}
                    />
                    <ErrorMessage
                      name="skills"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div className="change-password-submit-button-container">
                  <button type="submit" className="cursor">
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CertificateModal;
