import React from 'react'
import "./styles.scss"
import EnterDetails from './enterDetails'
import DisplayDetails from './displayDetails'

export default function RegisterUserDetails() {
  return (
    <div className="registerUserDetails_container">
      <div style={{width: "40%"}}>
        <EnterDetails />
      </div>
      <div style={{ width: "50%", }}>
        <DisplayDetails />
      </div>
    </div>
  );
}
