import Footer from "../../common/website/Footer"
import Navigation from "../../common/website/Navbar"
import FourthCard from "../../components/website/FourthCard"
import FrequentlyAskedQuestions from "../../components/website/FrequentlyAskedQuestions"
// import Hero3 from "../../components/website/Hero3"
import Hire from "../../components/website/Hire"
import OurBlog from "../../components/website/OurBlog"
import TalentPageHero from "../../components/website/TalentPageHero"


export default function SecondPageFindTalent2(){
    return(
        <div>
        <Navigation/>
        <TalentPageHero/>
        <FourthCard/>
        <FrequentlyAskedQuestions/>
        <Hire/>
        <OurBlog leftHeader="Our Blog" rightHeader="See more"/>
        <Footer/>
        </div>
    )
}