import Navigation from "../../common/website/Navbar";
import FindTalent from "../../components/website/FindTalent";
import Hero from "../../components/website/Hero";
import OurBlog from "../../components/website/OurBlog";
import ProductManager from "../../components/website/ProductManager";
import ProfPerformance from "../../components/website/ProfPerormance";
import TalentCategory from "../../components/website/TalentCategory";
import Trustedby from "../../components/website/Trustedby";
import WhyClientAndTalent from "../../components/website/WhyClientAndTalent";
import Hire from "../../components/website/Hire";
import Footer from "../../common/website/Footer";
import HireNow from "../../components/website/HireNow";
// import ImgInput from "../../components/common/input/imgInput";
// import { useSelector } from "react-redux";
// import { selectUserDetails } from "../../features/userSlice";

function Home() {
  // const userDetails = useSelector(selectUserDetails);
  return (
    <div>
      <HireNow />
      <Navigation />
      <Hero />
      <Trustedby />
      <ProfPerformance />
      <FindTalent title="Find" SpanTitle="around" secondTitle="the world" />
      <TalentCategory />
      <WhyClientAndTalent />
      <ProductManager />
      <OurBlog leftHeader="Our Blog" rightHeader="See more" />
      <Hire />
      {/* <ImgInput />
      <img src={userDetails.profilePic} alt="" /> */}
      <Footer />
    </div>
  );
}
export default Home;
