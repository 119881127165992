import React, { useState } from "react";
import Modal from "./modal";
import axiosInstance from "../../services/AxiosConfig";
import ShowToast from "../../common/website/Toast";
import { useSelector } from "react-redux";
import { selectJobDetails } from "../../features/jobSlice";
import { BounceLoader } from "react-spinners";
import { GoDotFill } from "react-icons/go";

const StepThree = ({ onPrevStep }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const jobDetails = useSelector(selectJobDetails);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handlePostJob = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post("/job", {
        role: jobDetails.role,
        location: jobDetails.location,
        currency: jobDetails.currency,
        min: jobDetails.min,
        max: jobDetails.max,
        description: jobDetails.description,
        responsibilities: jobDetails.responsibilities,
        requirements: jobDetails.requirements,
        tags: jobDetails.tags,
        brief: jobDetails.brief,
        notice: jobDetails.notice,
        duration: jobDetails.duration,
      });
      const successMessage = response.data.message || "Job posted Successfully";
      ShowToast({ type: "success", text: successMessage });
      setIsLoading(false);
      setModalVisible(true);
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        "An error occured";
      setIsLoading(false);
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // const handleEditJob = async () => {
  //   try {
  //     const response = await axiosInstance.put(`/job/${jobId}`, {
  //       role: jobDetails.role,
  //       location: jobDetails.location,
  //       currency: jobDetails.currency,
  //       min: jobDetails.min,
  //       max: jobDetails.max,
  //       description: jobDetails.description,
  //       responsibilities: jobDetails.responsibilities,
  //       requirements: jobDetails.requirements,
  //       tags: jobDetails.tags,
  //       brief: jobDetails.brief,
  //       notice: jobDetails.notice,
  //       duration: jobDetails.duration,
  //     });
  //     const successMessage =
  //       response.data.message || "Job updated Successfully";
  //     ShowToast({ type: "success", text: successMessage });
  //     setIsLoading(false);
  //   } catch (error) {
  //     const errorMessage =
  //       (error.response &&
  //         error.response.data &&
  //         error.response.data.message) ||
  //       "An error occured";
  //     setIsLoading(false);
  //     ShowToast({ type: "error", text: errorMessage });
  //   }
  // };

  return (
    <>
      {isModalVisible && <Modal handleClick={closeModal} />}
      {!isLoading ? (
        <div className="postJob-preview-container">
          <div className="postJob-preview-header">
            <p>{jobDetails.role}</p>
            <div>
              <div>
                <p>{jobDetails.duration}</p>
                <GoDotFill />
                <p>{jobDetails.notice}</p>
              </div>
              <span>
                <p>
                  {jobDetails.currency.toUpperCase()}{" "}
                  {Number(jobDetails?.min)?.toLocaleString()} -{" "}
                  {jobDetails.currency.toUpperCase()}{" "}
                  {Number(jobDetails?.max)?.toLocaleString()}
                </p>
              </span>
            </div>
            <div>
              <span>
                <p>{jobDetails.tags}</p>
              </span>
              <span>
                <p>Associates</p>
              </span>
              <span>
                <p>Commerce</p>
              </span>
            </div>
          </div>
          <hr />
          <div className="postJob-preview-description-container">
            <div className="postJob-preview-description">
              <p>Description</p>
              <p>{jobDetails.description}</p>
            </div>
            <div className="postJob-preview-description">
              <p>Responsibilities</p>
              <p>{jobDetails.responsibilities}</p>
            </div>
            <div className="postJob-preview-description">
              <p>Requirements</p>
              <p>{jobDetails.requirements}</p>
            </div>
            <div className="postJob-preview-description">
              <p>The Brief</p>
              <p>{jobDetails.brief}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="postJob-preview-loader">
          <BounceLoader size="100px" color="#134271" />
        </div>
      )}
      <div className="postJob-submit-button-container-2">
        <button onClick={onPrevStep} className="cursor">
          Back
        </button>
        <button onClick={handlePostJob} className="cursor">
          Submit
        </button>
      </div>
    </>
  );
};

export default StepThree;
