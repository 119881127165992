import React, { Fragment, useState } from "react";
import "../../styles/postJob/postJob.scss";
import PostJobHeader from "../../components/postJob/postJobHeader";
import StepOne from "../../components/postJob/stepOne";
import StepTwo from "../../components/postJob/stepTwo";
import StepThree from "../../components/postJob/stepThree";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";

const PostJob = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { jobId } = useParams();
  const navigate = useNavigate();

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <StepOne jobId={jobId} onNextStep={handleNextStep} />;
      case 1:
        return (
          <StepTwo
            jobId={jobId}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      case 2:
        return <StepThree onPrevStep={handlePrevStep} />;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <PostJobHeader />
      {/* <p 
        onClick={() => navigate(-1)} 
        style={{  minWidth: '40%', margin: '20px auto -20px auto', maxWidth: '100%', width: '40%' }} 
        className="cursor"
        >
        &larr; &nbsp; Back
      </p> */}
      <div className="postJob-container">
        <div
          className="breadcrumb pointer"
          style={{ marginTop: "30px" }}
          onClick={() => navigate(-1)}
        >
          <div className="icon">
            <AiOutlineLeft />
          </div>
          <div className="text">Back</div>
        </div>
        <div className="postJob-step-container">
          <div>
            <p>Post Job</p>
            <p>Hire the best person for the job</p>
          </div>
          <p>
            Step <span>{activeStep + 1}/3</span>
          </p>
        </div>
        <div>{renderStep()}</div>
      </div>
    </Fragment>
  );
};

export default PostJob;
