import React, { useState } from "react";
import "./auth.scss";
import { InlineBorderLabel } from "../inlineBorderLabel";
import {
  AUTH_BANNER,
  AUTH_BANNER_LABEL,
  GOOGLE_ICON,
  EYE_ICON,
  EYE_SLASH_ICON,
} from "../../assets/website/constants";
import { useNavigate } from "react-router-dom";
import ShowToast from "../../common/website/Toast";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";
import axiosInstance from "../../services/AxiosConfig";
import { ScaleLoader } from "react-spinners";
import { setActiveUser } from "../../features/activeUserSlice";

export default function AuthForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [emptyField, setEmptyField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  // Login
  async function handleLogin() {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("/authentication/login", {
        email: userDetails.email,
        password: userDetails.password,
      });
      const token = response.data.token;
      localStorage.setItem("pspUserToken", token);

      // SET USER TYPE
      // dispatch(
      //   setUserDetails({ ...userDetails, type: response.data.user.type })
      // );

      dispatch(setUserDetails(response?.data?.user));

      const successMessage = response.data.message || "Login Successful";
      dispatch(setActiveUser(response?.data?.user));

      ShowToast({ type: "success", text: successMessage });
      if (response?.data?.user?.type?.toLowerCase() === "client") {
        navigate("/client-dashboard");
      } else {
        navigate("/dashboard");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error?.response?.data?.error || "An error occurred during login";
      ShowToast({ type: "error", text: errorMessage });
    }
  }

  function handleValidation() {
    if (!userDetails.email || !userDetails.password) {
      setEmptyField(true);
    } else {
      handleLogin();
    }
  }

  return (
    <div className="auth_container">
      <div className="authBanner">
        <header className="bannerHeader">
          <h1>Your journey with us has started</h1>
          <p>Discover the world’s best freelancer and businesses</p>
        </header>
        <img src={AUTH_BANNER} alt="" />
        <img src={AUTH_BANNER_LABEL} alt="" className="authBannerLabel" />
      </div>
      <form className="authForm">
        <header className="authForm_header">Welcome back</header>

        {/* <Input type="text" placeholder="Email" /> */}
        <div className="input">
          <input
            type={"email"}
            placeholder={"Email"}
            value={userDetails.email}
            onChange={(e) =>
              dispatch(
                setUserDetails({ ...userDetails, email: e.target.value })
              )
            }
          />
        </div>
        {emptyField && !userDetails.email ? (
          <div className="error text-start my-2">Email is required</div>
        ) : null}

        {/* <PasswordInput placeholder="password"  /> */}

        <div className="input">
          <input
            type={showPassword ? "text" : "password"}
            placeholder={"Password"}
            value={userDetails.password}
            onChange={(e) =>
              dispatch(
                setUserDetails({ ...userDetails, password: e.target.value })
              )
            }
          />
          <div>
            <img
              src={showPassword ? EYE_ICON : EYE_SLASH_ICON}
              alt=""
              draggable={"false"}
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        </div>

        {emptyField && !userDetails.password ? (
          <div className="error text-start my-2">Password is required</div>
        ) : null}

        <div className="button-container">
          <button
            onClick={() => handleValidation()}
            type="button"
            className="button"
            disabled={isLoading}
          >
            {isLoading ? <ScaleLoader height={15} color="#ffffff" /> : "Login"}
          </button>
        </div>

        {/* <Button
          text={isLoading ? <Spinner size="sm" /> : "Login"}
          fn={() => handleValidation()}
        /> */}

        <section>
          <div>
            <div
              className="forgotPassword"
              onClick={() => navigate("/auth/password/reset")}
            >
              Forget password
            </div>
          </div>
          <InlineBorderLabel label="or" />
          <div className="withGoogle cursor">
            {" "}
            <img src={GOOGLE_ICON} alt="" /> Continue with google
          </div>
          <div className="options">
            Don't have an account?{" "}
            <span onClick={() => navigate("/auth/register")}>Sign up</span>
          </div>
        </section>
      </form>
    </div>
  );
}
