import Navigation from "../../common/website/Navbar";
import "../../styles/website/PrivacyPolicy.css"
function PrivacyPolicy(){
    return(
        <div>
         <Navigation/>
         <div className="privacy-policy">
                 <h2>Privacy Policy</h2>
         </div>
     </div>
    )
}

export default PrivacyPolicy;