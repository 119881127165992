import HeroImage from "../../assets/website/images/hero 3.svg";

export default function TalentPageHero() {
  return (
    <div className="findTalentPage2-hero">
      <div className="findTalentPage2-left">
        <div className="findTalentPage2-findTalent">
          <p>Find talents </p>
          <p>/</p>
          <p>Web designer </p>
        </div>
        <div className="tlent-at-your-finngertip-details">
          <h1 className="findTalentPage2-header">
            Hire 27 top <span>Adminstrators on PSP</span>{" "}
          </h1>

          <p className="findTalentPage2-ptags">
            {" "}
            Looking to hire Web Designer freelancer with the perfect set of
            skills for your next project, look no future
          </p>
        </div>

        <button className="findTalentPage2-button cursor">
          Hire freelancers
        </button>
      </div>

      <figure className="talent-at-your-finngertip-right">
        <img src={HeroImage} alt="fingerTip" />
      </figure>
    </div>
  );
}
