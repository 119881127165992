import { useState } from "react";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
export default function BySkill() {
  const [show, setShow] = useState();
  const [openItemIndex, setOpenItemIndex] = useState(null);
  const Navigate = useNavigate();

  let list = [
    {
      title: "Admin",
      content: ["Aminstator", "Advanced Adminstrator", "Platform App Builder"],
      id: 1,
    },
    {
      title: "Architects",
      content: ["Aminstator", "Advanced Adminstrator", "Platform App Builder"],
      id: 2,
    },
    {
      title: "Associates",
      content: ["Aminstator", "Advanced Adminstrator", "Platform App Builder"],
      id: 3,
    },
    {
      title: "Commerce",
      content: ["Aminstator", "Advanced Adminstrator", "Platform App Builder"],
      id: 4,
    },
    {
      title: "Consultant",
      content: ["Aminstator", "Advanced Adminstrator", "Platform App Builder"],
      id: 5,
    },
    {
      title: "Associates",
      content: ["Aminstator", "Advanced Adminstrator", "Platform App Builder"],
      id: 6,
    },
    {
      title: "Designers",
      content: ["Aminstator", "Advanced Adminstrator", "Platform App Builder"],
      id: 7,
    },
    {
      title: "Developers",
      content: ["Aminstator", "Advanced Adminstrator", "Platform App Builder"],
      id: 8,
    },
    {
      title: "Marketers",
      content: ["Aminstator", "Advanced Adminstrator", "Platform App Builder"],
      id: 9,
    },
  ];

  const handleItemClick = (index) => {
    if (index === openItemIndex) {
      setOpenItemIndex(null);
    } else {
      setOpenItemIndex(index);
    }
  };

  return (
    <div>
      <div>
        {list.map((item, i) => (
          <div className="bySkill" onClick={() => setShow(!show)} key={item.id}>
            <div className="by-skill-list" onClick={() => handleItemClick(i)}>
              <p>{item.title}</p>
              <button className="cursor">
                {" "}
                {i === openItemIndex ? (
                  <IoChevronUp className="arrowUp" />
                ) : (
                  <IoChevronDownOutline className="arrowDown" />
                )}
              </button>
            </div>

            {i === openItemIndex && (
              <div className="selects">
                <button
                  className="cursor"
                  onClick={() => Navigate("/talentpage")}
                >
                  {item.content[0]}
                </button>
                <button
                  className="cursor"
                  onClick={() => Navigate("/talentpage")}
                >
                  {item.content[1]}
                </button>
                <button
                  className="cursor"
                  onClick={() => Navigate("/talentpage")}
                >
                  {item.content[2]}
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
