import "../../styles/dashboard/navBar.scss";
import { AiFillCaretDown, AiOutlineBell } from "react-icons/ai";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../../features/userSlice";
import { selectActiveUser } from "../../features/activeUserSlice";
import Add from "../../assets/dashboard/icons/addJobs.svg";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/CapitalizeFirstName";

export default function NavBar({ navTitle, showMenu, setShowMenu }) {
  const userDetails = useSelector(selectUserDetails);
  const activeUser = useSelector(selectActiveUser);

  return (
    <>
      <div className="navBar-container">
        <div className="nav-title">
          <h2>{navTitle}</h2>
        </div>
        <div className="nav-links-section">
          {userDetails.type.toLowerCase() === "client" && (
            <Link to="/post-job" className="dashboardPostJobNav">
              <img src={Add} alt="add" />
              Post a Job
            </Link>
          )}
          <div className="alert">
            <AiOutlineBell />
          </div>
          <div className="profile-section">
            <div className="avatar">
              <img src={activeUser?.image} alt="Profile" />
            </div>
            <div className="names">
              <h4>
                {capitalizeFirstLetter(activeUser?.firstname)}{" "}
                {capitalizeFirstLetter(activeUser?.lastname)}
              </h4>
              <p className="subtitle">{userDetails.type}</p>
            </div>
            <div className="menu pointer">
              <AiFillCaretDown
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu(!showMenu);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
