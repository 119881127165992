import { Link } from "react-router-dom";
import BspNewsAndUpdateImage from "../../assets/website/images/bsp-news-and-image.svg";
import "../../styles/website/BlogPage.css";
export default function BspNewsAndUpdate() {
  return (
    <div className="bsp-news-and-update-container">
      <div className="bsp-news-and-update-heading">
        <h4>PSP News and Updates </h4>
        <p>
          From new products launches to exciting company updates, -
          stay-up-to-date with the lates PSP news
        </p>
      </div>

      <Link to="/singleblog" className="bsp-news-and-update-image-and-writeup">
        <figure className="bsp-news-and-update-image">
          <img src={BspNewsAndUpdateImage} alt="" />
        </figure>
        <div className="bsp-news-and-update-writeup">
          <h4>Can AI Assistant Make Us Faster and More Creative?</h4>
          <p>
            Generate AI can increase productivity in some cases - here's how to
            make the best of it{" "}
          </p>
        </div>
      </Link>

      <div className="bsp-news-and-update-buttons">
        <button className="all cursor">All</button>
        <button className="cursor"> Customeer service</button>
        <button className="cursor"> Customeer service</button>
        <button className="cursor"> Customeer service</button>
        <button className="cursor"> Customeer service</button>
      </div>
    </div>
  );
}
