import React from "react";
import Location from "../../../assets/dashboard/icons/location.svg";
import Symbol from "../../../assets/dashboard/icons/Symbol.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfilePictureBorder from "../../../assets/dashboard/images/Ellipse.png";
import { selectActiveUser } from "../../../features/activeUserSlice";
import { selectUserDetails } from "../../../features/userSlice";

export const DashboardProfile = () => {
  const activeUser = useSelector(selectActiveUser);
  const userDetails = useSelector(selectUserDetails);

  return (
    <div className="dashboard-page-profile-detail">
      <div className="dashboard-profile-user-avatar-dashboard">
        <div>{/* <img src={ProfilePictureBorder} alt="border" /> */}</div>
        <img
          src={activeUser?.image}
          alt="avatar"
          style={{ objectFit: "cover", border: "4px solid #F47C20" }}
        />
      </div>
      <div className="dashboard-profile-user-detail">
        <div
          className={
            userDetails?.avaliable
              ? "dashboard-profile-user-availability"
              : "dashboard-profile-user-availability-off"
          }
        >
          {userDetails?.avaliable ? <p>AVAILABLE</p> : <p>INVISIBLE</p>}
        </div>
        <p className="dashboard-profile-user-name">{userDetails?.display}</p>
        <p className="dashboard-profile-user-designation">
          {userDetails?.category}
        </p>
        <div className="dashboard-profile-user-location-and-rating">
          <div>
            <img src={Location} alt="location" />
            <p>{userDetails?.location}</p>
          </div>
          <div>
            <img src={Symbol} alt="star" />
            <p>{userDetails?.rating}</p>
          </div>
        </div>
        <div className="dashboard-page-profile-user-skill">
          {activeUser?.skills &&
            activeUser?.skills.map((skill, i) => (
              <div className="dashboard-profile-user-skill-main" key={i}>
                <p>{skill}</p>
              </div>
            ))}
        </div>
        <Link to="/dashboard/profile">Edit profile</Link>
      </div>
    </div>
  );
};
