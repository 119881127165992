import Navigation from "../../common/website/Navbar";
import TheFutureOfHiring from "../../components/website/TheFutureOfHiring";
import OurBlog from "../../components/website/OurBlog";
import Footer from "../../common/website/Footer";
import PspforTalentHero5 from "../../components/website/PspforTalentHero5";
import PspForClientContent from "../../components/website/PSPForTalentContent";
export default function PspForTalent() {
  return (
    <div>
      <Navigation />
      <PspforTalentHero5 />
      <PspForClientContent />
      {/* <PspForTalentCentent/> */}

      <TheFutureOfHiring
        title=" Start your journey with us"
        buttonTitle="Get started "
      />
      <OurBlog leftHeader="Our Blog" rightHeader="See more" />
      <Footer />
    </div>
  );
}
