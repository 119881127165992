import React, { Fragment, useState } from "react";
import "../../styles/postJob/postJob.scss";
import PostJobHeader from "../../components/postJob/postJobHeader";
import { useNavigate, useParams } from "react-router-dom";
import StepOne from "../../components/editJob/stepOne";
import StepTwo from "../../components/editJob/stepTwo";
import StepThree from "../../components/editJob/stepThree";
import { AiOutlineLeft } from "react-icons/ai";

const EditJob = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { jobId } = useParams();

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <StepOne jobId={jobId} onNextStep={handleNextStep} />;
      case 1:
        return (
          <StepTwo
            jobId={jobId}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      case 2:
        return <StepThree jobId={jobId} onPrevStep={handlePrevStep} />;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <PostJobHeader />
      <div className="postJob-container">
        <div
          className="breadcrumb pointer"
          style={{ marginTop: "30px" }}
          onClick={() => navigate(-1)}
        >
          <div className="icon">
            <AiOutlineLeft />
          </div>
          <div className="text">Back</div>
        </div>
        <div className="postJob-step-container">
          <div>
            <p>Edit Job</p>
            <p>Edit an existing job post</p>
          </div>
          <p>
            Step <span>{activeStep + 1}/3</span>
          </p>
        </div>
        <div>{renderStep()}</div>
      </div>
    </Fragment>
  );
};

export default EditJob;
