import Footer from "../../common/website/Footer";
import Navigation from "../../common/website/Navbar";
import OurBlog from "../../components/website/OurBlog";
import SingleBblogHero from "../../components/website/SingleBlogHero";
import SingleBlogPageWriteup from "../../components/website/SingleBlogPageWriteup";
import SingleBlogPageSidebar from "../../components/website/SinglePageSideBar";
import StartYourTalent from "../../components/website/StartYourTaent";

export default function SingleBlogPage() {
  return (
    <div>
      <Navigation />
      <SingleBlogPageSidebar />

      <SingleBblogHero />
      <SingleBlogPageWriteup />
      <OurBlog leftHeader="Related Articles" />
      <StartYourTalent />
      <Footer />
    </div>
  );
}
