import "../../../styles/dashboard/jobDetails.scss";
import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import { SlLocationPin } from "react-icons/sl";
import { GoDotFill } from "react-icons/go";
import Button from "../../common/button";
import { BsBookmarkPlus, BsBookmarkFill, BsShare } from "react-icons/bs";
import { useState } from "react";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { useEffect } from "react";
import { BounceLoader, ScaleLoader } from "react-spinners";

export default function JobDetails({ jobId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [savedJob, setSavedJob] = useState(false);
  const [singleJob, setSingleJob] = useState(null);
  const navigate = useNavigate();

  // Get single job
  useEffect(() => {
    const handleGetSingleJob = async () => {
      try {
        setIsLoading(true);
        if (jobId) {
          const response = await axiosInstance.get(`/job/p/${jobId}`);
          setSavedJob(response.data.save);
          const res = response.data.data;
          setSingleJob(res);
          setIsLoading(false);
          // const successMessage =
          //   response.data.message || "Job fetched successful";
          // ShowToast({ type: "success", text: successMessage });
        }
      } catch (error) {
        setIsLoading(false);
        const errorMessage =
          error?.response?.data?.error || "An error occurred";
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    handleGetSingleJob();
  }, [jobId]);

  // Save a job
  const handleSaveJob = async () => {
    try {
      const response = await axiosInstance.post("/job/save", {
        id: jobId,
      });
      const successMessage = response.data.message || "Job Saved";
      ShowToast({ type: "success", text: successMessage });
      setSavedJob(true);
    } catch (error) {
      const errorMessage = error?.response?.data?.error || "An error occurred";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // Remove saved job
  const handleRemoveSavedJob = async () => {
    try {
      const response = await axiosInstance.put(`/job/save/${jobId}`);
      const successMessage = response.data.message || "Job removed";
      ShowToast({ type: "success", text: successMessage });
      setSavedJob(false);
      navigate("/dashboard/jobs/find");
    } catch (error) {
      const errorMessage = error?.response?.data?.error || "An error occurred";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // Apply for job
  const handleJobApplication = async () => {
    try {
      setButtonLoader(true);
      const response = await axiosInstance.post(`/job/apply`, {
        id: jobId,
      });
      setButtonLoader(false);
      const successMessage = response.data.message || "Application sent";
      ShowToast({ type: "success", text: successMessage });
    } catch (error) {
      setButtonLoader(false);
      const errorMessage = error?.response?.data?.error || "An error occurred";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="postJob-preview-loader">
          <BounceLoader size="100px" color="#134271" />
        </div>
      ) : (
        <div className="job-details-container">
          <div className="breadcrumb pointer" onClick={() => navigate(-1)}>
            <div className="icon">
              <AiOutlineLeft />
            </div>
            <div className="text">Back</div>
          </div>

          <div className="details-row">
            <center className="company-details">
              <div className="logo">
                <img
                  src={singleJob?._company.image}
                  alt={singleJob?._company.name}
                />
              </div>
              <h3 className="name">{singleJob?._company.name}</h3>
              <div className="location">
                {" "}
                <SlLocationPin /> {singleJob?.location}
              </div>
              <Button
                text={
                  buttonLoader ? (
                    <ScaleLoader height={15} color="#ffffff" />
                  ) : (
                    "Apply Now"
                  )
                }
                fn={() => {
                  handleJobApplication();
                }}
              />

              <div className="line"></div>

              <p className="subtitle">Know someone who would be a great fit?</p>

              <div className="buttons">
                {" "}
                <button className="share-btn">Share this job</button>
              </div>
            </center>
            <div className="job-details">
              <div className="details-header">
                <h2>{singleJob?.role}</h2>
                <div className="share-save-icons">
                  <div className="icon">
                    {!savedJob ? (
                      <BsBookmarkPlus
                        onClick={handleSaveJob}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <BsBookmarkFill
                        onClick={handleRemoveSavedJob}
                        color="#134271"
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                  <div className="icon">
                    <BsShare />
                  </div>
                </div>
              </div>

              <div className="summary">
                <div className="time-budget subtitle">
                  {" "}
                  <div className="time">
                    {singleJob?.duration} <GoDotFill /> {singleJob?.notice}
                  </div>
                  <div className="budget">
                    {singleJob?.currency}
                    {singleJob?.min}/hr - {singleJob?.currency}
                    {singleJob?.max}/hr
                  </div>
                </div>
                <div className="button-section">
                  <Button
                    text={
                      buttonLoader ? (
                        <ScaleLoader height={15} color="#ffffff" />
                      ) : (
                        "Apply Now"
                      )
                    }
                    fn={() => {
                      handleJobApplication();
                    }}
                  />
                </div>
              </div>

              <div className="skills-section">
                <h4>Skills</h4>
                <div className="skills">
                  {singleJob?.tags.map((tag, i) => (
                    <div key={i} className="skill">
                      {tag}
                    </div>
                  ))}
                </div>
              </div>

              <div className="text-section ">
                <h4 className="heading">Description</h4>
                <div className="description">{singleJob?.description}</div>
              </div>

              <div className="text-section ">
                <h4 className="heading">Responsibilities</h4>
                <p className="description">{singleJob?.responsibilities}</p>
              </div>

              <div className="text-section ">
                <h4 className="heading">Requirements</h4>
                <p className="description">{singleJob?.requirements}</p>
              </div>

              <div className="text-section ">
                <h4 className="heading">The Brief</h4>
                <div className="description ">{singleJob?.brief}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
