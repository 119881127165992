import { combineReducers } from "redux";
import authReducer from "../features/authSlice";
import userReducer from "../features/userSlice";
import activeUserSlice from "../features/activeUserSlice";
import jobReducer from "../features/jobSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  activeUser: activeUserSlice,
  job: jobReducer,
});

export default rootReducer;
