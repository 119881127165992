import React from "react";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Successful from "../../assets/dashboard/icons/successful.svg";

const Modal = (props) => {
  const navigate = useNavigate();

  return (
    <div className="modal-container" onClick={props.handleClick}>
      {/* <Navigation/> */}
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="close-signup-modal">
          <button onClick={props.handleClick} className="pointer">
            <IoClose />
          </button>
        </div>
        <div className="modal-image-and-writeup">
          <figure>
            <img src={Successful} alt="success" />
          </figure>
          <h2>Job edited Successfully</h2>
        </div>
        <div className="signup-and-login-buttons">
          <button
            className="cursor"
            onClick={() => navigate("/client-dashboard")}
          >
            Go back home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
