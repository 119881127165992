import averter from "../../../assets/website/images/Avatar Image.svg";
import verified from "../../../assets/website/icons/verified.svg";
import "../../../styles/website/SearchTalentpage.css";
import { AiFillStar } from "react-icons/ai";
import { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosConfig";
import { Link, useLocation } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import ShowToast from "../../../common/website/Toast";
import { capitalizeFirstLetter } from "../../../utils/CapitalizeFirstName";
import { FormatRateDuration } from "../../../utils";

export default function SearchTalentCard({ searchTalent }) {
  const [talentList, setTalentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const confirmLocation = location.pathname;

  useEffect(() => {
    const getTalentList = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(
          `/search/talent?keyword=${
            searchTalent.searchByName || searchTalent.searchByLocation
          }`
          // `/search/talent?keyword=${searchTalent.searchByLocation}&skills=${searchTalent.searchByName}`
        );

        const res = response.data.data;

        console.log(res)

        if (confirmLocation === "/client-dashboard") {
          const dashboardTalents = res.slice(0, 6);
          setTalentList(dashboardTalents);
        } else {
          setTalentList(res);
        }
        setIsLoading(false);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Cannot fetch talents";
        ShowToast({ type: "error", text: errorMessage });
        setIsLoading(false);
      }
    };

    getTalentList();
  }, [searchTalent, confirmLocation]);

  return (
    <>
      {isLoading && (
        <div className="postJob-preview-loader">
          <BounceLoader size="100px" color="#134271" />
        </div>
      )}
      <div className="search-talent-card" style={{ marginBottom: "100px" }}>
        <div className="fourth-card">
          {talentList.length
            ? talentList.map((talent, i) => {
                return (
                  <div
                    className="fourth-page search-talent-card-section search-talent-card "
                    key={talent._id}
                  >
                    <button className="favorite-btn cursor">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 22 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.62 18.8101C11.28 18.9301 10.72 18.9301 10.38 18.8101C7.48 17.8201 1 13.6901 1 6.6901C1 3.6001 3.49 1.1001 6.56 1.1001C8.38 1.1001 9.99 1.9801 11 3.3401C12.01 1.9801 13.63 1.1001 15.44 1.1001C18.51 1.1001 21 3.6001 21 6.6901C21 13.6901 14.52 17.8201 11.62 18.8101Z"
                          stroke="#7F879E"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                    <div className="fourth-card-image-section">
                      <figure style={{ border: "1px solid #eee"}} className="user-avatar">
                        <img
                          src={talent.image || averter}
                          alt=""
                          className="user-img"
                        />
                      </figure>
                      <div className="verified-symbol">
                        <img src={verified} alt="" className="" />
                      </div>
                    </div>

                    <div className="fourth-page-buttom card-details-section">
                      <div className="designer-name-and-location text-center">
                        <h4>
                          {capitalizeFirstLetter(talent?.firstname)}{" "}
                          {capitalizeFirstLetter(talent?.lastname)}
                        </h4>
                        <p className="icon-and-location">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.5 11.6917C11.9359 11.6917 13.1 10.5276 13.1 9.0917C13.1 7.65576 11.9359 6.4917 10.5 6.4917C9.06405 6.4917 7.89999 7.65576 7.89999 9.0917C7.89999 10.5276 9.06405 11.6917 10.5 11.6917Z"
                              stroke="#7F879E"
                              stroke-width="1.5"
                            />
                            <path
                              d="M3.51666 7.57508C5.15832 0.358417 15.85 0.36675 17.4833 7.58342C18.4417 11.8167 15.8083 15.4001 13.5 17.6168C11.825 19.2334 9.17499 19.2334 7.49166 17.6168C5.19166 15.4001 2.55832 11.8084 3.51666 7.57508Z"
                              stroke="#7F879E"
                              stroke-width="1.5"
                            />
                          </svg>
                          {talent.location || "Califonia, USA"}
                        </p>
                      </div>
                      <div className="designer-skill-rate-section">
                        <div className="rate-rating-section">
                          <div className="rate">
                            <p>CAD {talent.rates.min}/{FormatRateDuration(talent.rates.time)} - CAD {talent.rates.min}/{FormatRateDuration(talent.rates.time)}</p>  {/************ fix this ************/}
                          </div>
                          {/* <div className=" skills-section  shadow">
                            <p className="centered">
                              <AiFillStar className="star" /> <span>3.0</span> 
                            </p>
                          </div> */}
                        </div>
                        <div className="profile-content skills-section">
                          {talent.skills
                            ? talent.skills.map((skill, i) => {
                                if (i < 3) {
                                  return (
                                    <div key={i}>
                                      <p>{skill} </p>
                                    </div>
                                  );
                                }
                              })
                            : null}
                          {talent.skills && talent.skills.length > 3 && (
                            <div>
                              <p className="tool centered">
                                +{talent?.skills?.length - 3}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="profile-content skill-section">
                          {talent.tools?.map((tool, i) => {
                            if (i < 3) {
                              return (
                                <div key={i}>
                                  <p className="tool">
                                    {" "}
                                    <img src={tool.icon} alt="" /> {tool.name}{" "}
                                  </p>
                                </div>
                              );
                            }
                          })}

                          {talent.tools && talent.tools.length > 3 && (
                            <div>
                              <p className="tool centered">
                                +{talent?.tools?.length - 3}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <Link to={`/client-dashboard/applicant/${talent._id}`}>
                        <button className="search-talent-card-button cursor">
                          View profile
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
}
