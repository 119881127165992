export default function StillHaveQuestions({
  title,
  subtitle,
  handleClick,
  buttonTitle,
}) {
  return (
    <div className="still-have-questions">
      <header>
        <h3>
          Still have <span>questions?</span>
        </h3>
      </header>
      <p>We've got the answers </p>
      <button className="cursor"> Hello, PSP</button>
    </div>
  );
}
