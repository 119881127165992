import Edit from "../../../assets/dashboard/icons/edit.svg";

export const ProfileEditableHeading = ({ title, onClick }) => {
  return (
    <div className="dashboard-profile-user-editable-header">
      <h5>{title}</h5>
      <img onClick={onClick} src={Edit} alt="edit" />
    </div>
  );
};
