import React from "react";
import GoalCard from "../../../../components/goalCard";
import Button from "../../../../components/common/button";
import "./styles.scss";
import {
  COLOR_SEARCH_ICON,
  FIRST_PLACE_ICON,
  MONEY_BAG_ICON,
  MONEY_ICON,
} from "../../../../assets/website/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserDetails,
  setUserDetails,
} from "../../../../features/userSlice";
import ShowToast from "../../../../common/website/Toast";

export default function RegisterGoals() {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const goalList = [
    {
      icon: MONEY_BAG_ICON,
      text: "To earn my main income",
      desc: "Main",
    },
    {
      icon: MONEY_ICON,
      text: "To make money on the side",
      desc: "Side",
    },
    {
      icon: FIRST_PLACE_ICON,
      text: "To get work experience",
      desc: "Experience",
    },
    {
      icon: COLOR_SEARCH_ICON,
      text: "I don't have a goal in mind yet",
      desc: "Unknown",
    },
  ];
  const handleSubmit = () => {
    if (userDetails.goal.trim() === "") {
      ShowToast({ type: "error", text: "Please fill in all field" });
      return;
    }
    navigate("/register/{details}");
  };
  return (
    <div className="goal_container">
      <h1>What is your main goal</h1>
      <p>Highlight the opportunity that fit your goals best</p>

      <div className="goal_cards">
        {goalList.map((goal, i) => (
          // <div className="goal_card">
            <GoalCard
              key={i}
              icon={goal.icon}
              text={goal.text}
              value={goal.text}
              checked={userDetails.goal === goal.desc}
              onChange={() =>
                 dispatch(setUserDetails({ ...userDetails, goal: goal.desc }))
              }
            />
          // </div>
        ))}
      </div>
      <div style={{ width: "10%", paddingTop: "1em" }}>
        <Button fn={handleSubmit} text="Next" />
      </div>
    </div>
  );
}
