import React, { useEffect, useState } from "react";

import CardDesign from "../../../assets/dashboard/icons/cardDesign.svg";
import eyeSlash from "../../../assets/dashboard/icons/eyeSlashWhite.svg";
import { useSelector } from "react-redux";
import { selectActiveUser } from "../../../features/activeUserSlice";
import { ScaleLoader } from "react-spinners";

const SingleCard = ({
  bgColor,
  cardDesign,
  border,
  title,
  titleColor,
  amount,
  walletBtn,
  icon,
  isLoading,
  showBal,
  fundWallet,
  createWallet,
}) => {
  const [showBalance, setShowBalance] = useState(true);
  const activeUser = useSelector(selectActiveUser);
  const [balance, setBalance] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setBalance(activeUser?._wallet);
    }, 4000);
  }, [activeUser]);

  return (
    <div
      className="single-card-container"
      style={{ backgroundColor: bgColor, border: border }}
    >
      <div>
        <img src={icon} alt="logo icon" className="wallet-card-logo" />
        {cardDesign && (
          <img
            src={CardDesign}
            alt="card design"
            className="wallet-card-design"
          />
        )}
        <div>
          <p style={{ color: titleColor }}>{title}</p>
          <div>
            {balance ? (
              <p style={{ color: titleColor }}>
                {showBalance ? amount : "*****"}
              </p>
            ) : (
              <p style={{ color: titleColor }}>
                {showBalance ? (
                  <ScaleLoader height={15} color={titleColor} />
                ) : (
                  "*****"
                )}
              </p>
            )}
            {showBal && (
              <img
                src={eyeSlash}
                alt="eye slash"
                onClick={() => setShowBalance((prev) => !prev)}
              />
            )}
          </div>
        </div>
        {!activeUser?._wallet && walletBtn && (
          <button onClick={createWallet}>
            {isLoading ? (
              <ScaleLoader height={15} color="#ffffff" />
            ) : (
              "Create Wallet"
            )}
          </button>
        )}
        {activeUser?._wallet && walletBtn && (
          <button onClick={fundWallet}>
            {activeUser?.type === "Client" ? "Fund Wallet" : "Withdraw"}
          </button>
        )}
      </div>
    </div>
  );
};

export default SingleCard;
