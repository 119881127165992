import React, { useState } from "react";
import "../auth/auth.scss";
import { REG_BANNER } from "../../assets/website/constants";
import EmailSignUpForm from "./emailSignUpForm";
import IdentityForm from "./identityForm";
import VerificationForm from "./verificationForm";
import DetailsForm from "./detailsForm";

export default function RegisterForm() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <IdentityForm onNextStep={handleNextStep} />;
      case 1:
        return <EmailSignUpForm onNextStep={handleNextStep} />;
      case 2:
        return <VerificationForm onNextStep={handleNextStep} />;
      case 3:
        return <DetailsForm />;
      default:
        return null;
    }
  };
  return (
    <div className="auth_container">
      <div className="authBanner">
        <header className="bannerHeader">
          <h1>Start your journey with us</h1>
          <p>Discover the world’s best freelancer and businesses</p>
        </header>
        <img src={REG_BANNER} alt="" />
      </div>
      <>{renderStep()}</>
    </div>
  );
}
