import twitter from "../../assets/website/icons/twiter.svg";
import linkedin from "../../assets/website/icons/Linkedin.svg";
import facebook from "../../assets/website/icons/facebook.svg";
import instagram from "../../assets/website/icons/instagram.svg";
import logo from "../../assets/website/icons/logo.svg";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useState } from "react";


export default function Footer() {
  const [showMore, setShowMore] = useState(true);
  const handleMore = () => {
    setShowMore((prev) => !prev);
  };
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-section">
        <div className="footer-item logo-socials-section">
          <Link to="/" className="logo cursor">
            <img src={logo} alt="PSP" />
          </Link>
          <div className="footer-socialmedia">
            <figure className="cursor">
              <img src={twitter} alt="" />
            </figure>
            <figure className="cursor">
              <img src={linkedin} alt="" />
            </figure>
            <figure className="cursor">
              <img src={facebook} alt="" />
            </figure>
            <figure className="cursor">
              <img src={instagram} alt="" />
            </figure>
          </div>
        </div>

        <ul className="footer-item">
          <li>
            <b>Resource</b>
          </li>
          <li>
            <Link to="/faq" className="footer-link">
              FAQ
            </Link>
          </li>
          <li>
            <Link to="/blogpage" className="footer-link">
              Blog
            </Link>{" "}
          </li>

          <li>Help & Support</li>
        </ul>

        <ul className="footer-item">
          <li>
            <b>Discover Talents</b>
          </li>
          <li>Administrators</li>
          <li>Architects</li>
          {showMore && (
            <>
              <li>Associates</li>
              <li>Commerce</li>
              <li>Consultants</li>
              <li>Designer</li>
              <li>Developer</li>
              <li>Marketers</li>
            </>
          )}
          <li className="footerShowMore" onClick={handleMore}>
            {showMore ? "Show less" : "Show more"}
            {showMore ? (
              <IoChevronUp className="arrowUp" />
            ) : (
              <IoChevronDownOutline className="arrowDown" />
            )}
          </li>
        </ul>

        <ul className="footer-item">
          <li>
            <b>Company</b>
          </li>
          <li>Mission</li>
          <li>Careers</li>
        </ul>

        <ul className="footer-item">
          <li>
            <b>How it works</b>{" "}
          </li>
          <li>
            <Link to="/pspforclient" className="footer-link">
              PSP for client
            </Link>
          </li>
          <li>
            <Link to="/pspfortalent" className="footer-link">
              PSP for talent
            </Link>
          </li>
        </ul>
      </div>
      <div className="footer-copyright">
        <p>@Copyright {currentYear}. All Right Reserved </p>
        <div>
          <ul className="footer-policy">
            <li>
              {" "}
              <Link to="/privacypolicy" className="footer-link">
                Privacy Policy
              </Link>
            </li>
            <li className="cursor">Terms of service </li>
            <li className="cursor"> Cookie Policy</li>
            <li className="cursor">Cookie Setting</li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
