import React, { useState } from "react";
import Input from "../common/input";
import PasswordInput from "../common/input/passwordInput";
import Button from "../common/button";
import { useNavigate } from "react-router-dom";
import CountryCodeInput from "../common/input/phoneInput";
import axiosInstance from "../../services/AxiosConfig";
import ShowToast from "../../common/website/Toast";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";
import { ScaleLoader } from "react-spinners";

export default function DetailsForm({ onNextStep }) {
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [indicator, setIndicator] = useState(new Array(5).fill(false));
  const userDetails = useSelector(selectUserDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClientNext = async () => {
    if (
      userDetails.firstName.trim() === "" ||
      userDetails.lastName.trim() === "" ||
      userDetails.password.length < 8 ||
      !checkboxChecked
    ) {
      ShowToast({ type: "error", text: "Please fill in all required fields" });
      return;
    }
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        "/authentication/register/client",
        {
          firstname: userDetails.firstName,
          lastname: userDetails.lastName,
          password: userDetails.password,
        }
      );
      const successMessage = response.data.message || "Registration Successful";
      ShowToast({ type: "success", text: successMessage });

      navigate("/register/{details}");
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        "An error occured during registration";
      console.error("An error occured during registration", errorMessage); //Remove console
      ShowToast({ type: "error", text: errorMessage });
    }
    setIsLoading(false);
  };

  // TALENT REGISTRATION
  const handleTalentNext = async () => {
    if (
      userDetails.firstName.trim() === "" ||
      userDetails.lastName.trim() === "" ||
      (userDetails.type === "Talent" &&
        userDetails.phoneNumber.trim() === "") ||
      userDetails.password.length < 8 ||
      !checkboxChecked
    ) {
      ShowToast({ type: "error", text: "Please fill in all required fields" });
      return;
    }
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        "/authentication/register/talent",
        {
          firstname: userDetails.firstName,
          lastname: userDetails.lastName,
          password: userDetails.password,
          phone: userDetails.phoneNumber,
          country_code: userDetails.countryCode,
        }
      );

      const successMessage = response.data.message || "Registration Successful";

      ShowToast({ type: "success", text: successMessage });

      console.log(
        "Registration successful",
        response.data,
        userDetails.firstName,
        userDetails.password
      ); //Remove Console

      navigate("/register/{goal}");
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        "An error occured during registration";

      console.error("An error occured during registration", errorMessage); //Remove console
      ShowToast({ type: "error", text: errorMessage });
    }
    setIsLoading(false);
  };

  const calculatePasswordStrength = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const length = password.length;

    return { hasUppercase, hasLowercase, hasNumber, length };
  };

  const getStrengthLabel = () => {
    const { hasUppercase, hasLowercase, hasNumber, length } =
      calculatePasswordStrength(userDetails.password);
    const strength = (hasUppercase + hasLowercase + hasNumber) / 3;

    if (length < 8) {
      return "Very Weak";
    } else if (strength < 0.6) {
      return "Weak";
    } else if (strength < 0.8) {
      return "Strong";
    } else {
      return "Very Strong";
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;

    const { hasUppercase, hasLowercase, hasNumber, length } =
      calculatePasswordStrength(password);
    // Calculate the password strength
    const strength = (hasUppercase + hasLowercase + hasNumber) / 3;

    // Update the indicator state based on the calculated strength
    const filledIndicators = Array.from(
      { length: 5 },
      (_, i) => i < Math.ceil(strength * 5) && length >= (i + 1) * 2
    );
    setIndicator(filledIndicators);
    if (length <= 12) {
      dispatch(setUserDetails({ ...userDetails, password }));
    }
  };

  return (
    <form className="authForm">
      <header>
        <div className="authForm_header">Let's get to know you</div>
        <div className="authForm_sub_header">
          Enter your details to start your journey with us
        </div>
      </header>
      <div style={{ width: "100%", display: "flex", flexWrap: "wrap", columnGap: "10px", justifyContent: "space-between" }}>
        <Input
          style={{ width: "47%" }}
          type="text"
          placeholder="First name"
          value={userDetails.firstName}
          onChange={(e) =>
            dispatch(
              setUserDetails({ ...userDetails, firstName: e.target.value })
            )
          }
        />
        <Input
          style={{ width: "47%" }}
          type="text"
          placeholder="Last name"
          value={userDetails.lastName}
          onChange={(e) =>
            dispatch(
              setUserDetails({ ...userDetails, lastName: e.target.value })
            )
          }
        />
      </div>
      {userDetails.type === "Talent" && (
        <CountryCodeInput
          value={userDetails.phoneNumber}
          onChange={(e) =>
            dispatch(
              setUserDetails({
                ...userDetails,
                phoneNumber: e,
              })
            )
          }
        />
      )}
      <PasswordInput
        value={userDetails.password}
        onChange={handlePasswordChange}
        placeholder="password"
      />
      <div>
        <div className="passwordIndicator">
          <div className="passwordIndicator_Container">
            <div className="passwordIndicator_inputContainer">
              {indicator.map((isFilled, i) => (
                <div
                  key={i}
                  className={`indicator-item ${isFilled ? "filled" : ""}`}
                />
              ))}
            </div>
            <span
              className={
                getStrengthLabel()?.toLowerCase()?.includes("weak")
                  ? "text-danger"
                  : ""
              }
            >
              {getStrengthLabel()}
            </span>
          </div>
          <span>
            Password must be at least 8 characters with a combination of letters
            and numbers.
          </span>
        </div>
        <div className="privacyPolicy">
          <input
            type="checkbox"
            checked={checkboxChecked}
            onChange={() => setCheckboxChecked((prev) => !prev)}
          />
          <div>
            I agree with <span>PSP Terms of Service</span>, including the
            <span>User Agreement</span> and <span>Privacy Policy</span>.
          </div>
        </div>
      </div>
      <Button
        fn={userDetails.type === "Talent" ? handleTalentNext : handleClientNext}
        text={
          isLoading ? <ScaleLoader height={15} color="#ffffff" /> : "Continue"
        }
      />
      <section>
        <div className="options">
          Already have an account?
          <span onClick={() => navigate("/auth/login")}>Log in</span>
        </div>
      </section>
    </form>
  );
}
