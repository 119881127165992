import React from "react";
import MainLayout from "../../../layouts/mainLayout";
import CardSection from "../../../components/dashboard/wallet/CardSection";
import "../../../styles/dashboard/wallet.scss";
import WalletTable from "../../../components/dashboard/wallet/WalletTable";

export const Wallets = () => {
  return (
    <MainLayout navTitle={"Wallets"}>
      <CardSection />
      <WalletTable />
    </MainLayout>
  );
};
