import React, { Fragment } from "react";
import PostJobHeader from "../../../components/postJob/postJobHeader";
import PreviewJob from "../../../components/postJob/PreviewJob";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";

const PreviewPostedJobs = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  return (
    <Fragment>
      <PostJobHeader />
      <div className="postJob-container">
        <div
          className="breadcrumb pointer"
          style={{ margin: "30px 0" }}
          onClick={() => navigate(-1)}
        >
          <div className="icon">
            <AiOutlineLeft />
          </div>
          <div className="text">Back</div>
        </div>
        {/* <div className="postJob-step-container"> */}
        {/* <p onClick={() => navigate(-1)} className="cursor">
            Back
          </p> */}
        {/* <div>
            <p>Post Job</p>
            <p>Hire the best person for the job</p>
          </div>
          <p>
            Step <span>{activeStep + 1}/3</span>
          </p> */}
        {/* </div> */}
        <div>
          <PreviewJob jobId={jobId} />
        </div>
      </div>
    </Fragment>
  );
};

export default PreviewPostedJobs;
