import React, { useEffect, useState } from "react";
import Modal from "./modal";
import axiosInstance from "../../services/AxiosConfig";
import ShowToast from "../../common/website/Toast";
import { BounceLoader } from "react-spinners";
import { GoDotFill } from "react-icons/go";

const PreviewJob = ({ jobId }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewSingleJob, setViewSingleJob] = useState(null);

  const closeModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const handleViewSingleJob = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(`/job/${jobId}`);
        const res = response.data.data;
        setViewSingleJob(res);
        setIsLoading(false);
        const successMessage =
          response.data.message || "Job fetched successful";
        ShowToast({ type: "success", text: successMessage });
      } catch (error) {
        setIsLoading(false);
        const errorMessage =
          error?.response?.data?.error || "An error occurred";
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    if (jobId) {
      handleViewSingleJob();
    }
  }, [jobId]);

  return (
    <>
      {isModalVisible && <Modal handleClick={closeModal} />}
      {!isLoading ? (
        <div className="postJob-preview-container">
          <div className="postJob-preview-header">
            <p>{viewSingleJob?.role}</p>
            <div>
              <div>
                <p>{viewSingleJob?.duration}</p>
                <GoDotFill />
                <p>{viewSingleJob?.notice}</p>
              </div>
              <span>
                <p>
                  {viewSingleJob?.currency.toUpperCase()}{" "}
                  {Number(viewSingleJob?.min)?.toLocaleString()} -{" "}
                  {viewSingleJob?.currency.toUpperCase()}{" "}
                  {Number(viewSingleJob?.max)?.toLocaleString()}
                </p>
              </span>
            </div>
            <div>
              <span>
                <p>{viewSingleJob?.tags}</p>
              </span>
              <span>
                <p>Associates</p>
              </span>
              <span>
                <p>Commerce</p>
              </span>
            </div>
          </div>
          <hr />
          <div className="postJob-preview-description-container">
            <div className="postJob-preview-description">
              <p>Description</p>
              <p>{viewSingleJob?.description}</p>
            </div>
            <div className="postJob-preview-description">
              <p>Responsibilities</p>
              <p>{viewSingleJob?.responsibilities}</p>
            </div>
            <div className="postJob-preview-description">
              <p>Requirements</p>
              <p>{viewSingleJob?.requirements}</p>
            </div>
            <div className="postJob-preview-description">
              <p>The Brief</p>
              <p>{viewSingleJob?.brief}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="postJob-preview-loader">
          <BounceLoader size="100px" color="#134271" />
        </div>
      )}
      {/* <div className="postJob-submit-button-container-2">
        <button onClick={onPrevStep} className="cursor">
          Back
        </button>
        <button onClick={handlePostJob} className="cursor">
          Submit
        </button>
      </div> */}
    </>
  );
};

export default PreviewJob;
