// import logo from './logo.svg';
import "./App.css";
import "./styles/website/landingPage.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/website/Home";
import Login from "./pages/website/Login";
import Register from "./pages/website/Register";
import RegisterGoals from "./pages/website/Register/goals";
import RegisterUserDetails from "./pages/website/Register/registerDetails";
import FindTalent from "./pages/website/SecondPageFindTalent";
import SecondPageFindTalent2 from "./pages/website/SecondPageFindTalent2";
import PspForTalent from "./pages/website/PspForTalent";
import BlogPage from "./pages/website/BlogPage";
import SearchTalent from "./pages/website/SearchTalentPage";
import SingleBogPageSidebar from "./components/website/SinglePageSideBar";
import SingleBlogPage from "./pages/website/SingleBlogPage";
import Faq from "./pages/website/FAQ";
import PspForClient from "./pages/website/PspForClient";
import PrivacyPolicy from "./pages/website/PrivacyPolicy";
import SignUpModal from "./components/website/SignUpModal";
import Dashboard from "./pages/dashboard";
import Profile from "./pages/dashboard/profile";
import FindJobPage from "./pages/dashboard/find_job";
import SingleJob from "./pages/dashboard/find_job/SingleJob";
import SavedJobsPage from "./pages/dashboard/saved";
import { ToastContainer } from "react-toastify";
import ResetPassword from "./pages/website/ResetPassword";
import ResetYourPassword from "./pages/website/ResetYourPassword";
import { useSelector } from "react-redux";
import { selectAuth } from "./features/authSlice";
import MessagesPage from "./pages/dashboard/messages";
import ScrollToTop from "./utils/ScrollToTop";
import { Projects } from "./pages/dashboard/projects";
import { Wallets } from "./pages/dashboard/wallets";
import { Offers } from "./pages/dashboard/offers";
import { Settings } from "./pages/dashboard/settings";
import useGetSelf from "./hooks/GetSelf";
import { useEffect } from "react";
import { selectUserDetails } from "./features/userSlice";
import FindTalentPage from "./pages/dashboard/find_talent";
import PostedJobsPage from "./pages/dashboard/posted";
import PostJob from "./pages/postJob";
import ApplicationPage from "./pages/dashboard/application";
import PreviewPostedJobs from "./pages/dashboard/posted/PreviewPostedJobs";
import ProfileDetail from "./pages/dashboard/profile_details";
import PaymentCheckout from "./pages/dashboard/wallets/PaymentCheckout";
import EditJob from "./pages/postJob/EditJob";
function App() {
  const isAuthenticated = useSelector(selectAuth);
  const userDetails = useSelector(selectUserDetails);

  const handleGetSelf = useGetSelf();

  useEffect(() => {
    handleGetSelf();
  }, [handleGetSelf]);

  return (
    <div>
      <BrowserRouter>
        {/* <NavBar /> */}
        <ScrollToTop />
        <Routes>
          {/* <Route path="" element={<Footer/>}/> */}
          <Route path="/" element={<Home />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/register/{goal}" element={<RegisterGoals />} />
          <Route path="/register/{details}" element={<RegisterUserDetails />} />
          <Route path="/" element={<Home />} />
          <Route path="/findtalent" element={<FindTalent />} />
          <Route path="/talentpage" element={<SecondPageFindTalent2 />} />
          <Route path="/pspfortalent" element={<PspForTalent />} />
          <Route path="/pspforclient" element={<PspForClient />} />
          <Route path="/searchtalent" element={<SearchTalent />} />
          <Route path="/blogpage" element={<BlogPage />} />
          <Route path="/singleblog" element={<SingleBlogPage />} />
          <Route path="/sidebar" element={<SingleBogPageSidebar />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/sign" element={<SignUpModal />} />
          <Route path="/auth/password/reset" element={<ResetPassword />} />
          <Route
            path="auth/password/reset/new-password"
            element={<ResetYourPassword />}
          />

          {/* Dashboard Routing */}

          {isAuthenticated && (
            <>
              <Route
                path={
                  userDetails?.type.toLowerCase() === "talent"
                    ? "/dashboard"
                    : "/client-dashboard"
                }
              >
                <Route path="" element={<Dashboard />} />
                <Route path="jobs/find" element={<FindJobPage />} />
                <Route path="jobs/find/:jobId" element={<SingleJob />} />
                <Route path="talents/find" element={<FindTalentPage />} />
                <Route path="jobs/saved" element={<SavedJobsPage />} />
                <Route path="jobs/saved/:jobId" element={<SingleJob />} />
                <Route path="jobs/posted" element={<PostedJobsPage />} />
                <Route
                  path="jobs/posted/:jobId"
                  element={<PreviewPostedJobs />}
                />
                <Route path="messages" element={<MessagesPage />} />
                <Route path="profile" element={<Profile />} />
                <Route
                  path="applicant/:applicantId"
                  element={<ProfileDetail />}
                />
                <Route path="projects" element={<Projects />} />
                <Route path="wallet" element={<Wallets />} />
                <Route path="offers" element={<Offers />} />
                <Route path="offers" element={<Offers />} />
                <Route path="settings" element={<Settings />} />
                <Route path="application" element={<ApplicationPage />} />
                <Route path="checkout" element={<PaymentCheckout />} />
              </Route>
              <Route path="/post-job" element={<PostJob />} />
              <Route path="/post-job/:jobId" element={<PostJob />} />
              <Route path="/edit-job/:jobId" element={<EditJob />} />
            </>
          )}
        </Routes>
      </BrowserRouter>

      <ToastContainer />
    </div>
  );
}

export default App;
