import Minicard from "./Minicard";

export default function TalentCategory(){
    return(
        <div className="talent-category-container">
            <div className="browse-talents">
                <header>
                <h3>Browse Talents By Categories </h3>
                </header>
                <p>Get some inspiratrions from 1800 + skills</p>
            </div>
            <div className="TalentCategory-miniCard">
                <Minicard title='Admin' number='1233' rate='4.8'/>
                <Minicard title='Architects' number='1233' rate='4.5'/>
                <Minicard title='Associates' number='1233' rate='4.5'/>
                <Minicard title='Commerce' number='1233' rate='4.5'/>
                <Minicard title='Consultant' number='1233' rate='4.5'/>
                <Minicard title='Designer' number='1233' rate='4.5'/>
                <Minicard title='Developers' number='1233' rate='4.5'/>
                <Minicard title='Marketers' number='1233' rate='4.5'/>


            </div>
        </div>
    )
}