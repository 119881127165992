import React, { useEffect, useState } from "react";
import { BounceLoader } from "react-spinners";
import "../../../styles/postJob/postJob.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// Components
import EmptyState from "../../../components/dashboard/saved/EmptyState";
import Filter from "../../../components/dashboard/projects/Filter";
import { formatDate } from "../../../utils/FormatDate";

// Images
import emptyState from "../../../assets/dashboard/icons/emptyPostedJobs.svg";
import chat from "../../../assets/dashboard/icons/chat.svg";
import clock from "../../../assets/dashboard/icons/clock.svg";
import filter from "../../../assets/dashboard/icons/Filter.svg";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { Link } from "react-router-dom";

const ApplicationTable = () => {
  const [filterBy, setFilterBy] = useState("newApp");
  const [isLoading, setIsLoading] = useState(false);
  const [applicantList, setApplicantList] = useState([]);
  const [hiredList, setHiredList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);

  const tableHead = [
    { head: "Role", filter: filter },
    { head: "Applicant", filter: filter },
    { head: "Location", filter: filter },
    { head: "Date", filter: filter },
    { head: "Action" },
  ];

  const tableApplicationHead = [
    { head: "Role", filter: filter },
    { head: "Applicant", filter: filter },
    { head: "Location", filter: filter },
    { head: "Date", filter: filter },
    { head: "Status" },
  ];

  // Get applicant list
  useEffect(() => {
    const getApplicantList = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/applicants?state=pending");
        const res = response.data.data;
        setApplicantList(res);
        console.log("res", res);
        setIsLoading(false);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Error fetching applicants";
        setIsLoading(false);
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    getApplicantList();
  }, []);

  // Hire an Applicant
  const hireApplicant = async (applicantID) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        `/applicants/accept/${applicantID}`
      );
      const successMessage =
        response.data.message || "You have successfully hired an applicant";
      ShowToast({ type: "success", text: successMessage });
      setIsLoading(false);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      setIsLoading(false);
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // Reject an Applicant
  const rejectApplicant = async (applicantID) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        `/applicants/reject/${applicantID}`
      );
      const successMessage =
        response.data.message || "You have successfully rejected an applicant";
      ShowToast({ type: "success", text: successMessage });
      setIsLoading(false);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      setIsLoading(false);
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // Get hired applicant list
  useEffect(() => {
    const getHiredApplicantList = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(`/applicants?state=accepted`);
        const res = response.data.data;
        // console.log("res", res);
        setHiredList(res);
        const successMessage = response.data.message || "Applicants fetched";
        // ShowToast({ type: "success", text: successMessage });
        setIsLoading(false);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Error fetching applicants";
        setIsLoading(false);
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    getHiredApplicantList();
  }, []);

  // Get rejected applicant list
  useEffect(() => {
    const getRejectedApplicantList = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(`/applicants?state=rejected`);
        const res = response.data.data;
        console.log("res", res);
        setRejectedList(res);
        const successMessage = response.data.message || "Applicants fetched";
        // ShowToast({ type: "success", text: successMessage });
        setIsLoading(false);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Error fetching applicants";
        setIsLoading(false);
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    getRejectedApplicantList();
  }, []);

  return (
    <div className="application">
      {applicantList.length <= 0 && (
        <div className="application__top">
          {/* <h2 className="application_title">Application</h2> */}
          <p className="application_description">
            Check and filter all job applications here
          </p>
        </div>
      )}

      <div className="filter-container">
        <Filter
          icon={chat}
          filterName="New applications"
          filterCount={applicantList.length}
          onClick={() => setFilterBy("newApp")}
          color={filterBy === "newApp" ? "#101928" : "#344054"}
          bgColor={filterBy === "newApp" ? "#CBD6E2" : "#F0F2F5"}
          border={
            filterBy === "newApp" ? "1px solid #134271" : "1px solid #D0D5DD"
          }
          countColor={filterBy === "newApp" ? "#ffffff" : "#344054"}
          countBg={filterBy === "newApp" ? "#134271" : "#E4E7EC"}
        />
        <Filter
          icon={clock}
          filterName="Hired"
          filterCount={hiredList.length}
          onClick={() => {
            setFilterBy("hire");
          }}
          color={filterBy === "hire" ? "#101928" : "#344054"}
          bgColor={filterBy === "hire" ? "#CBD6E2" : "#F0F2F5"}
          border={
            filterBy === "hire" ? "1px solid #134271" : "1px solid #D0D5DD"
          }
          countColor={filterBy === "hire" ? "#ffffff" : "#344054"}
          countBg={filterBy === "hire" ? "#134271" : "#E4E7EC"}
        />
        <Filter
          icon={clock}
          filterName="Reject"
          filterCount={rejectedList.length}
          onClick={() => setFilterBy("reject")}
          color={filterBy === "reject" ? "#101928" : "#344054"}
          bgColor={filterBy === "reject" ? "#CBD6E2" : "#F0F2F5"}
          border={
            filterBy === "reject" ? "1px solid #134271" : "1px solid #D0D5DD"
          }
          countColor={filterBy === "reect" ? "#ffffff" : "#344054"}
          countBg={filterBy === "reect" ? "#134271" : "#E4E7EC"}
        />
      </div>

      {/* Applicants */}
      {filterBy === "newApp" && (
        <div>
          {applicantList.length && !isLoading ? (
            <TableContainer width="100%" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHead.map((th, i) => (
                      <TableCell key={i}>
                        <div>
                          <p>{th.head}</p>
                          {th.filter && (
                            <img
                              src={th.filter}
                              alt="filter"
                              className="pointer"
                            />
                          )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicantList?.map((applicant, i) => (
                    <TableRow key={i}>
                      <TableCell className="postedJob-role">
                        {applicant._job.role}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/client-dashboard/applicant/${applicant._user?._id}`}
                          // onClick={() => navigate(`${applicant._user._id}`)}
                          style={{ textDecoration: "none", color: "#7F879E" }}
                        >
                          {applicant._user?.firstname}{" "}
                          {applicant._user?.lastname}
                        </Link>
                      </TableCell>
                      <TableCell>{applicant._user?.location}</TableCell>
                      <TableCell>{formatDate(applicant.updatedAt)}</TableCell>
                      <TableCell>
                        <div className="application__btns">
                          <button
                            onClick={() => hireApplicant(applicant._id)}
                            className="btn hired"
                          >
                            Hired
                          </button>
                          <button
                            onClick={() => rejectApplicant(applicant._id)}
                            className="btn reject"
                          >
                            Reject
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : !applicantList.length && !isLoading ? (
            <EmptyState
              title={"No Projects  (yet)"}
              subtitle={"All projects will appear here"}
              img={emptyState}
            />
          ) : (
            <div className="postJob-preview-loader">
              <BounceLoader size="100px" color="#134271" />
            </div>
          )}
        </div>
      )}

      {/* Hired Applicants */}
      {filterBy === "hire" && (
        <div>
          {hiredList.length && !isLoading ? (
            <TableContainer width="100%" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableApplicationHead.map((th, i) => (
                      <TableCell key={i}>
                        <div>
                          <p>{th.head}</p>
                          {th.filter && (
                            <img
                              src={th.filter}
                              alt="filter"
                              className="pointer"
                            />
                          )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hiredList?.map((project, i) => (
                    <TableRow key={i}>
                      <TableCell className="postedJob-role">
                        {project._job.role}
                      </TableCell>
                      <TableCell>
                        {project._user.firstname} {project._user.lastname}
                      </TableCell>
                      <TableCell>{project._user.location}</TableCell>
                      <TableCell>{formatDate(project.updatedAt)}</TableCell>
                      <TableCell>
                        <div className="application-hired-status-container">
                          <p>Hired</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : !hiredList.length && !isLoading ? (
            <EmptyState
              title={"No Projects  (yet)"}
              subtitle={"All projects will appear here"}
              img={emptyState}
            />
          ) : (
            <div className="postJob-preview-loader">
              <BounceLoader size="100px" color="#134271" />
            </div>
          )}
        </div>
      )}

      {/* Rejected Applicants */}
      {filterBy === "reject" && (
        <div>
          {rejectedList.length && !isLoading ? (
            <TableContainer width="100%" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableApplicationHead.map((th, i) => (
                      <TableCell key={i}>
                        <div>
                          <p>{th.head}</p>
                          {th.filter && (
                            <img
                              src={th.filter}
                              alt="filter"
                              className="pointer"
                            />
                          )}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rejectedList?.map((project, i) => (
                    <TableRow key={i}>
                      <TableCell className="postedJob-role">
                        {project._job.role}
                      </TableCell>
                      <TableCell>
                        {project._user.firstname} {project._user.lastname}
                      </TableCell>
                      <TableCell>{project._user.location}</TableCell>
                      <TableCell>{formatDate(project.updatedAt)}</TableCell>
                      <TableCell>
                        <div className="application-rejected-status-container">
                          <p>Rejected</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : !rejectedList.length && !isLoading ? (
            <EmptyState
              title={"No Projects  (yet)"}
              subtitle={"All projects will appear here"}
              img={emptyState}
            />
          ) : (
            <div className="postJob-preview-loader">
              <BounceLoader size="100px" color="#134271" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ApplicationTable;
