import { Link } from "react-router-dom";
import logo from "../../assets/website/icons/logo.svg";

export default function ResetPasswordNavbar(){
    return(
        <div className="reset-navigation">
            <div className="reset-logo-container">
                <Link to="/" className="reset-logo-link">
                <img src={logo} alt="logo" />
                </Link>
            </div>

     

   </div>
    )
}