import React from "react";
import "./styles.scss";

export default function Button({ text, fn }) {
  return (
    <div className="button-container">
      <button
        onClick={() => fn()}
        type="button"
        style={{ padding: "12px 24px" }}
        className="button"
      >
        {text}
      </button>
    </div>
  );
}
