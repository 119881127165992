import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/website/icons/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/website/Navbar.css";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import SignUpModal from "../../components/website/SignUpModal";

function Navigation() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const dropdownRef = useRef(null);

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  function handleToggleMobileMenu() {
    setShowMobileMenu(!showMobileMenu);
  }

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    setShowMobileMenu(false);
    return () => {
      document.removeEventListener("click", closeDropdown);
      setShowMobileMenu(false);
    };
  }, []);

  const navigate = useNavigate();

  const [isModalVisible, setModalVisible] = useState(false);
  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="Navigation">
      {isModalVisible && <SignUpModal handleClick={closeModal} />}
      <div className="Navbar">
        <div className="left-nav">
          <Link to="/" className="logo-section">
            <img src={logo} alt="PSP" />
          </Link>
        </div>

        <div
          className={
            !showMobileMenu ? "right-nav " : "right-nav  mobile-options"
          }
        >
          <div className="right-nav-link-contianer">
            <li
              onClick={() => setDropdownOpen(!dropdownOpen)}
              ref={dropdownRef}
              className="nav-link"
            >
              <span className="cursor">How It Works</span>
              {dropdownOpen && (
                <ul className="how-it-work-dropdown">
                  <Link className="nav-link" to="/pspforclient">
                    Psp For Client
                  </Link>
                  <Link className="nav-link" to="/pspfortalent">
                    Psp For Talent
                  </Link>
                  <Link className="nav-link" to="/faq">
                    FAQ
                  </Link>
                  <Link className="nav-link">Mission</Link>
                </ul>
              )}
            </li>
            <Link to="/findtalent" className="nav-link">
              Find Talent
            </Link>
          </div>

          <div className="right-nav-button-container">
            {/* <button className="cursor">Post a job</button> */}
            <button
              onClick={() => {
                navigate("/auth/login");
              }}
              className="cursor"
            >
              Log in
            </button>
            <button className="cursor" onClick={openModal}>
              Get Started
            </button>
          </div>
        </div>

        <div
          className="mobile-menu-switch bg-danger"
          onClick={handleToggleMobileMenu}
        >
          {!showMobileMenu ? <AiOutlineMenu /> : <AiOutlineClose />}
        </div>
      </div>
    </div>
  );
}

export default Navigation;
