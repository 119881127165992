import React from "react";
import "./styles.scss";

export default function VerticalLabelInput({
  type,
  placeholder,
  label,
  icon,
  value,
  onChange,
  onKeyDown,
}) {
  return (
    <div className="input">
      <div style={{ width: "100%" }}>
        <label
          style={{
            fontSize: "14px",
            letterSpacing: "-2%",
            fontWeight: "500",
            color: "#848FAC",
          }}
          htmlFor=""
        >
          {label}
        </label>
        <div style={{ display: "flex", gap: "10px" }}>
          {icon && <img src={icon} alt="icon" />}
          <input
            className="verticalInput"
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            style={{ width: "100%" }}
            onKeyDown={onKeyDown}
          />
        </div>
      </div>
    </div>
  );
}
