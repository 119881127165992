import Navigation from "../../common/website/Navbar";
import BspNewsAndUpdate from "../../components/website/BspNewsAndUpdates";
import StartYourTalent from "../../components/website/StartYourTaent";
import Footer from "../../common/website/Footer";
import SecondCard from "../../components/website/SecondCard";
export default function BlogPage() {
  return (
    <div>
      <Navigation />
      <BspNewsAndUpdate />
      <SecondCard />
      <SecondCard />
      <SecondCard />
      <StartYourTalent />
      <Footer />
    </div>
  );
}
