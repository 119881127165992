import SavedJobs from "../../../components/dashboard/saved";
import MainLayout from "../../../layouts/mainLayout";

export default function SavedJobsPage() {
  return (
    <MainLayout navTitle={"Saved"}>
      <SavedJobs />
    </MainLayout>
  );
}
