/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Location from "../../../assets/dashboard/icons/location.svg";
import { ProfileEditableHeading } from "./ProfileEditableHeading";
import ProfilePictureBorder from "../../../assets/dashboard/images/Ellipse.png";
import { nameCase } from "../../../utils/FormatName";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { useNavigate } from "react-router-dom";

export default function ProfileDetails({ applicantId }) {
  const navigate = useNavigate();
  const [applicantDetails, setApplicantDetails] = useState({});

  // Get applicant details
  useEffect(() => {
    const getSingleApplicant = async () => {
      try {
        const response = await axiosInstance.get(
          `/applicants/user/${applicantId}`
        );
        const appDetails = response.data.data;
        console.log("app", appDetails);
        setApplicantDetails(appDetails);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Error fetching Certificates";
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    getSingleApplicant();
  }, [applicantId]);


  const handleMsgBtn = (data) => {
    if(Object.entries(data).length === 0){ return }
    const stringifiedData = JSON.stringify(data)
    localStorage.setItem('current-chat', stringifiedData)
    navigate('/client-dashboard/messages');
  }

  return (
    <div className="dashboard-profile-details-container">
      <div className="dashboard-profile-detail">
        <div className="dashboard-profile-user-avatar">
          <div>
            <img src={ProfilePictureBorder} alt="border" />
          </div>
          <img
            src={applicantDetails?.image}
            alt="avatar"
            style={{ objectFit: "cover" }}
          />
        </div>
        <div className="dashboard-profile-user-detail">
          {/* {activeUser?.avaliable ? (
            <div className="dashboard-profile-user-availability">
              <p>AVAILABLE</p>
            </div>
          ) : (
            <div className="dashboard-profile-user-availability-off">
              <p>INVISIBLE</p>
            </div>
          )} */}
          <p className="dashboard-profile-user-name">
            {nameCase(applicantDetails?.firstname)}{" "}
            {nameCase(applicantDetails?.lastname)}
          </p>
          <p className="dashboard-profile-user-designation">Associate</p>
          {/* <p className="dashboard-profile-user-displayname">
            Display name: {nameCase(applicantDetails?.display)}
          </p> */}
        </div>
      </div>
      <button onClick={() => handleMsgBtn(applicantDetails)} className="message-button cursor">
        Message
      </button>

      <hr className="dashboard-profile-details-divider" />

      {/* Skill */}
      <div className="dashboard-profile-user-skill">
        <ProfileEditableHeading title="Skills" />
        {applicantDetails?.skills &&
          applicantDetails?.skills.map((skill, i) => (
            <div className="dashboard-profile-user-skill-main" key={i}>
              <p>{skill}</p>
            </div>
          ))}
      </div>

      {/* Bio */}
      <div className="dashboard-profile-user-Bio">
        <ProfileEditableHeading title="Bio" />
        <p>{applicantDetails?.bio}</p>
      </div>

      {/* Location */}
      <div className="dashboard-profile-user-location">
        <ProfileEditableHeading title="Location" />
        <div className="dashboard-profile-user-location-main">
          <img src={Location} alt="location" />
          <p>{applicantDetails?.location}</p>
        </div>
      </div>

      {/* Hourly Rate */}
      <div className="dashboard-profile-user-rate">
        <ProfileEditableHeading title="Hourly Rate" />
        <div className="dashboard-profile-user-rate-main">
          <p>
            {`$${applicantDetails?.rates?.min}`}-
            {`$${applicantDetails?.rates?.max}`}/hr
          </p>
        </div>
      </div>

      {/* Links */}
      <div className="dashboard-profile-user-link">
        <ProfileEditableHeading title="Links" />
        <div className="dashboard-profile-user-link-main">
          <p>{applicantDetails?.link}</p>
        </div>
      </div>
    </div>
  );
}
