import React, { useState } from "react";
import "./styles.scss";
import DisplayName from "../../../../../components/userDetailsForms/displayName";
import UserSkills from "../../../../../components/userDetailsForms/skills";
import UserCategory from "../../../../../components/userDetailsForms/category";
import UserBio from "../../../../../components/userDetailsForms/bio";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../../../../../features/userSlice";
import ClientLocation from "../../../../../components/userDetailsForms/clientLocation";

export default function EnterDetails() {
  //   {
  //   currentStep,
  //   action,
  //   description,
  //   content,
  // }
  const [activeStep, setActiveStep] = useState(0);
  const userDetails = useSelector(selectUserDetails);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  const handlePrevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // const stateProps = {
  //   currentStep: "2",
  //   action: "you",
  //   description: "none",
  //   content: <VerticalLabelInput placeholder="Enter display name" />,
  // };

  const renderClientSteps = () => {
    switch (activeStep) {
      case 0:
        return <DisplayName onNextStep={handleNextStep} />;
      case 1:
        return (
          <ClientLocation
            onNextStep={handleNextStep}
            prevStep={handlePrevStep}
          />
        );
      case 2:
        return <UserBio prevStep={handlePrevStep} />;
      default:
        return null;
    }
  };

  const renderTalentSteps = () => {
    switch (activeStep) {
      case 0:
        return <DisplayName onNextStep={handleNextStep} />;
      case 1:
        return (
          <UserCategory onNextStep={handleNextStep} prevStep={handlePrevStep} />
        );
      case 2:
        return (
          <UserSkills onNextStep={handleNextStep} prevStep={handlePrevStep} />
        );
      case 3:
        return <UserBio prevStep={handlePrevStep} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {userDetails.type === "Client" ? (
        // Client steps
        <>{renderClientSteps()}</>
      ) : (
        // Talent steps
        <>{renderTalentSteps()}</>
      )}
    </div>
  );
}
