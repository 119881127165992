import { BsGrid, BsGridFill } from "react-icons/bs";
import { TbLayoutList } from "react-icons/tb";
import { PiSquareSplitVerticalFill } from "react-icons/pi";
import "../../../styles/dashboard/findJob.scss";
import { useEffect, useState } from "react";
import JobCard from "./card";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { BounceLoader } from "react-spinners";
import EmptyState from "../saved/EmptyState";
import emptyState from "../../../assets/dashboard/icons/emptyPostedJobs.svg";

export default function FindJob() {
  const [view, setView] = useState({
    grid: true,
    list: false,
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [availableJobs, setAvailableJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState({
    role: "",
    location: "",
    category: "",
  });

  useEffect(() => {
    const getJobs = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(
          `/job/search?role=${searchTerm.role}&category=${searchTerm.category}&location=${searchTerm.location}`
        );
        const res = response.data.data;
        setAvailableJobs(res);
        setIsLoading(false);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Error fetching Jobs";
        setIsLoading(false);
        ShowToast({ type: "error", text: errorMessage });
      }
    };
    getJobs();
  }, [searchTerm.role, searchTerm.category, searchTerm.location]);

  return (
    <>
      <div className="find-job-container">
        <div className="search-background">
          <div className="search-button-section">
            <div className="search-section">
              <input
                className="jobs"
                type="search"
                placeholder="Search Jobs..."
                value={searchTerm.role}
                onChange={(e) =>
                  setSearchTerm({ ...searchTerm, role: e.target.value })
                }
              />
              <input
                className="location"
                type="search"
                placeholder="Location"
                value={searchTerm.location}
                onChange={(e) =>
                  setSearchTerm({ ...searchTerm, location: e.target.value })
                }
              />
              <input
                className="category"
                type="search"
                placeholder="Category"
                value={searchTerm.category}
                onChange={(e) =>
                  setSearchTerm({ ...searchTerm, category: e.target.value })
                }
              />
            </div>

            <button>Search</button>
          </div>
        </div>

        <section className="sort-view-section">
          <div className="sort-section">
            <div className="item">
              <label htmlFor="sort">Sort by:</label>
              <select name="" id="sort">
                <option value="">Newest</option>
              </select>
            </div>
            <div className="item">
              <label htmlFor="sort">Rate</label>
              <select name="" id="sort">
                <option value=""></option>
              </select>
            </div>
          </div>

          <div className="switch-view">
            <div
              className={view.grid ? "active-view" : "item"}
              onClick={() => setView({ ...view, grid: true, list: false })}
            >
              <div className="icon">
                {view.grid ? <BsGridFill /> : <BsGrid />}
              </div>

              <div className="text">Grid</div>
            </div>{" "}
            <div
              className={view.list ? "active-view" : "item"}
              onClick={() => setView({ ...view, grid: false, list: true })}
            >
              <div className="icon">
                {view.list ? <PiSquareSplitVerticalFill /> : <TbLayoutList />}
              </div>

              <div className="text">List</div>
            </div>
          </div>
        </section>
        <section
          className={
            view.grid
              ? "grid-job-cards cards-section"
              : "list-job-cards cards-section"
          }
        >
          {!isLoading &&
            availableJobs.length > 0 &&
            availableJobs.map((job, i) => {
              return (
                <div className="card" key={i}>
                  <JobCard fn={() => navigate(`${job?._id}`)} job={job} />
                </div>
              );
            })}
        </section>
      </div>
      {!availableJobs.length && !isLoading && (
        <EmptyState
          title={"No Available  Jobs (yet)"}
          subtitle={"All available jobs will appear soon"}
          img={emptyState}
        />
      )}
      {isLoading && (
        <div className="postJob-preview-loader">
          <BounceLoader size="100px" color="#134271" />
        </div>
      )}
    </>
  );
}
