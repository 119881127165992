import averter from "../../assets/website/images/Avatar Image.svg";
import verified from "../../assets/website/icons/verified.svg";
import avatar from "../../assets/website/images/avatar.jpg";
import figma from "../../assets/website/icons/skills/figma.png";
import xd from "../../assets/website/icons/skills/xd.png";

export default function FourthCard() {
  const searchResult = [
    {
      name: "Design King",
      location: "California, USA",
      rate: "$60-$70",
      skills: ["Web designer", "Figma", "Adobe XD", "Photoshop"],
      tools: [
        {
          name: "Figma",
          icon: figma,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
      ],
      image: avatar,
    },
    {
      name: "Joy Designs",
      location: "New York, USA",
      rate: "$50-$65",
      skills: ["Graphic design", "Illustrator", "InDesign"],
      tools: [
        {
          name: "Figma",
          icon: figma,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
      ],
    },
    {
      name: "Pixel Picasso",
      location: "London, UK",
      rate: "$70-$80",
      skills: ["UI/UX design", "Sketch", "InVision"],
      tools: [
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
      ],
    },
    {
      name: "Artistic Visionary",
      location: "Paris, France",
      rate: "$55-$75",
      skills: [
        "Illustrator",
        "Photoshop",
        "Concept Art",
        "Illustrator",
        "Photoshop",
        "Concept Art",
      ],
    },
    {
      name: "Digital Maestro",
      location: "Sydney, Australia",
      rate: "$65-$75",
      skills: ["Motion graphics", "After Effects", "Premiere Pro"],
    },
    {
      name: "Visual Alchemist",
      location: "Toronto, Canada",
      rate: "$50-$60",
      skills: ["Graphic design", "Logo design", "Canva"],
    },
    {
      name: "Color Wizard",
      location: "Berlin, Germany",
      rate: "$45-$55",
      skills: ["Color theory", "Palette design", "Color grading"],
    },
    {
      name: "Pixel Ninja",
      location: "Tokyo, Japan",
      rate: "$70-$85",
      skills: ["Pixel art", "Pixel animation", "Pixel sprite design"],
    },
    {
      name: "Illustration Maestro",
      location: "Rio de Janeiro, Brazil",
      rate: "$50-$60",
      skills: ["Digital illustration", "Procreate", "Clip Studio Paint"],
    },
  ];

  return (
    <div className="fourth-card-infomartion">
      <div className="fourth-card">
        {searchResult.length
          ? searchResult.map((talent, i) => {
              return (
                <div className="fourth-page search-card-section" key={i}>
                  <div className="fourth-card-image-section">
                    <figure className=" user-avatar">
                      <img
                        src={talent.image || averter}
                        alt=""
                        className="user-img"
                      />
                    </figure>
                    <div className="verified-symbol">
                      <img src={verified} alt="" className="" />
                    </div>
                  </div>

                  <div className="card-details-section">
                    <div className="designer-name-and-location ">
                      <h4>{talent.name} </h4>
                      <p className="icon-and-location">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.5 11.6917C11.9359 11.6917 13.1 10.5276 13.1 9.0917C13.1 7.65576 11.9359 6.4917 10.5 6.4917C9.06405 6.4917 7.89999 7.65576 7.89999 9.0917C7.89999 10.5276 9.06405 11.6917 10.5 11.6917Z"
                            stroke="#7F879E"
                            stroke-width="1.5"
                          />
                          <path
                            d="M3.51666 7.57508C5.15832 0.358417 15.85 0.36675 17.4833 7.58342C18.4417 11.8167 15.8083 15.4001 13.5 17.6168C11.825 19.2334 9.17499 19.2334 7.49166 17.6168C5.19166 15.4001 2.55832 11.8084 3.51666 7.57508Z"
                            stroke="#7F879E"
                            stroke-width="1.5"
                          />
                        </svg>
                        Califonia, USA
                      </p>
                    </div>

                    <div className="designer-skill-rate-section">
                      <div className="rate-rating-section">
                        <div className="rate">
                          <p>{talent.rate || "$60 - $65"}/hr</p>
                        </div>
                        <div className="star-section">
                          <p>⭐ 3.0</p>
                        </div>
                      </div>
                      <div className="profile-content skill-section">
                        {talent.skills
                          ? talent.skills.map((skill, i) => {
                              return (
                                <div key={i}>
                                  <p>{skill} </p>
                                </div>
                              );
                            })
                          : null}
                      </div>{" "}
                      <div className="profile-content skill-section">
                        {talent.tools?.map((tool, i) => {
                          if (i < 3) {
                            return (
                              <div key={i}>
                                <p className="tool">
                                  {" "}
                                  <img src={tool.icon} alt="" /> {tool.name}{" "}
                                </p>
                              </div>
                            );
                          }
                        })}

                        {talent.tools && talent.tools.length > 3 && (
                          <div>
                            <p className="tool">+{talent?.tools?.length - 3}</p>
                          </div>
                        )}
                      </div>
                    </div>

                    <button className="fourth-card-button cursor">
                      View Profile{" "}
                    </button>
                  </div>
                </div>
              );
            })
          : null}

        {/*
        <div className="fourth-page">
          <figure className="fourth-page-top">
            <img src={averter} alt="" className="fouthCard-avatar-image" />
            <img src={verified} alt="" className="fouthCard-verified-image" />
          </figure>

          <div className="fourth-page-buttom">
            <div className="designer-name-and-location ">
              <h4>Design King </h4>
              <p>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 11.6917C11.9359 11.6917 13.1 10.5276 13.1 9.0917C13.1 7.65576 11.9359 6.4917 10.5 6.4917C9.06405 6.4917 7.89999 7.65576 7.89999 9.0917C7.89999 10.5276 9.06405 11.6917 10.5 11.6917Z"
                    stroke="#7F879E"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.51666 7.57508C5.15832 0.358417 15.85 0.36675 17.4833 7.58342C18.4417 11.8167 15.8083 15.4001 13.5 17.6168C11.825 19.2334 9.17499 19.2334 7.49166 17.6168C5.19166 15.4001 2.55832 11.8084 3.51666 7.57508Z"
                    stroke="#7F879E"
                    stroke-width="1.5"
                  />
                </svg>{" "}
                Califonia, USA
              </p>
            </div>
            <div className="profile-content">
              <div>
                <p>$60 - $65/hr</p>
              </div>
              <div>
                <p>⭐ 3.0</p>
              </div>
            </div>
            <div className="profile-content">
              <div>
                <p>Web designer </p>
              </div>
              <div>
                <p>Designer</p>
              </div>
              <div>
                <p>Designer</p>
              </div>
            </div>
            <div className="profile-content">
              <div>
                <p>Figma </p>
              </div>
              <div>
                <p>Adobe XD</p>
              </div>
              <div>
                <p>Photoshop</p>
              </div>
              <div>
                <p>+1</p>
              </div>
            </div>

            <button className="fourth-card-button">View Profile </button>
          </div>
        </div>

        <div className="fourth-page">
          <figure className="fourth-page-top">
            <img src={averter} alt="" className="fouthCard-avatar-image" />
            <img src={verified} alt="" className="fouthCard-verified-image" />
          </figure>

          <div className="fourth-page-buttom">
            <div className="designer-name-and-location ">
              <h4>Design King </h4>
              <p>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 11.6917C11.9359 11.6917 13.1 10.5276 13.1 9.0917C13.1 7.65576 11.9359 6.4917 10.5 6.4917C9.06405 6.4917 7.89999 7.65576 7.89999 9.0917C7.89999 10.5276 9.06405 11.6917 10.5 11.6917Z"
                    stroke="#7F879E"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.51666 7.57508C5.15832 0.358417 15.85 0.36675 17.4833 7.58342C18.4417 11.8167 15.8083 15.4001 13.5 17.6168C11.825 19.2334 9.17499 19.2334 7.49166 17.6168C5.19166 15.4001 2.55832 11.8084 3.51666 7.57508Z"
                    stroke="#7F879E"
                    stroke-width="1.5"
                  />
                </svg>{" "}
                Califonia, USA
              </p>
            </div>
            <div className="profile-content">
              <div>
                <p>$60 - $65/hr</p>
              </div>
              <div>
                <p>⭐ 3.0</p>
              </div>
            </div>
            <div className="profile-content">
              <div>
                <p>Web designer </p>
              </div>
              <div>
                <p>Designer</p>
              </div>
              <div>
                <p>Designer</p>
              </div>
            </div>
            <div className="profile-content">
              <div>
                <p>Figma </p>
              </div>
              <div>
                <p>Adobe XD</p>
              </div>
              <div>
                <p>Photoshop</p>
              </div>
              <div>
                <p>+1</p>
              </div>
            </div>

            <button className="fourth-card-button">View Profile </button>
          </div>
        </div> */}
      </div>
      <div className="view-more-adminstrators">
        <button className="cursor"> View More Adminstrators</button>
      </div>
    </div>
  );
}
