import Messages from "../../../components/dashboard/messages";
import MainLayout from "../../../layouts/mainLayout";

export default function MessagesPage() {
  return (
    <MainLayout navTitle={"Messages"}>
      <Messages />
    </MainLayout>
  );
}
