import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { useSelector } from "react-redux";
import { selectActiveUser } from "../../../features/activeUserSlice";

const FundWalletModal = (props) => {
  const [amountValue, setAmountValue] = useState("");
  const activeUser = useSelector(selectActiveUser);

  // Redirect URL
  const handleRedirectUrl = async () => {
    try {
      let successUrl, cancelUrl;
      if (activeUser?.type === "Client") {
        successUrl = "http://localhost:3001/client-dashboard/checkout";
        cancelUrl = "http://localhost:3001/client-dashboard/checkout";
      } else if (activeUser?.type === "Talent") {
        successUrl = "http://localhost:3001/dashboard/checkout";
        cancelUrl = "http://localhost:3001/dashboard/checkout";
      }

      if (successUrl && cancelUrl) {
        const response = axiosInstance.put("/stripe/urls/", {
          success: successUrl,
          canceled: cancelUrl,
        });

        const res = response.data.data;
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Fund wallet
  const handleFundWallet = async () => {
    try {
      const fundWallet = await axiosInstance.post("/stripe/checkout", {
        amount: amountValue,
      });

      window.open(fundWallet.data.action, "_blank");
      //   window.location.href = fundWallet.data.action;

      const successMessage = fundWallet.data.message || "Wallet funded";
      ShowToast({ type: "success", text: successMessage });
      props.onClose();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ||
        "An error occurred while funding wallet";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  return (
    <div className="modal-container" onClick={props.handleClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="close-signup-modal">
          <button onClick={props.handleClick} className="pointer">
            <IoClose />
          </button>
        </div>
        <div className="change-password-form-container">
          <p>Fund Wallet</p>
          <div>
            <input
              type="text"
              defaultValue={0}
              className="fundWallet-input"
              value={amountValue}
              placeholder="Ex. 200.55"
              onChange={(e) => setAmountValue(e.target.value)}
            />
          </div>
          <div className="change-password-submit-button-container">
            <button
              onClick={() => {
                handleFundWallet();
                handleRedirectUrl();
              }}
              type="submit"
              className="cursor"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundWalletModal;
