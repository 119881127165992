import emptyState from "../../../assets/dashboard/icons/emptyPostedJobs.svg";
import filter from "../../../assets/dashboard/icons/Filter.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import EmptyState from "../../../components/dashboard/saved/EmptyState";
import "../../../styles/postJob/postJob.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MenuBtn from "./MenuBtn";
import { BounceLoader } from "react-spinners";

export default function PostedJobs() {
  const [postedJob, setPostedJob] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const tableHead = [
    { head: "Role", filter: filter },
    { head: "Brief", filter: filter },
    { head: "Date", filter: filter },
    { head: "Action" },
    { head: "" },
  ];

  useEffect(() => {
    const getPostedJobs = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/job");
        const res = response.data.data.map((job) => ({
          ...job,
          date: new Date(job.createdAt).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
        }));
        setPostedJob(res);
        setIsLoading(false);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Error Loading Saved Jobs";
        setIsLoading(false);
        ShowToast({ type: "error", text: errorMessage });
      }
    };
    getPostedJobs();
  }, []);

  return (
    <div className="postedJobs-container">
      <p>Check and filter your posted job here</p>
      {postedJob.length && !isLoading ? (
        <TableContainer width="100%" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((th, i) => (
                  <TableCell key={i}>
                    <div>
                      <p>{th.head}</p>
                      {th.filter && (
                        <img src={th.filter} alt="filter" className="pointer" />
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {postedJob?.map((job, i) => (
                <TableRow key={i}>
                  <TableCell className="postedJob-role">{job.role}</TableCell>
                  <TableCell>{job.brief}</TableCell>
                  <TableCell>{job.date}</TableCell>
                  <TableCell>
                    <button
                      onClick={() => navigate(`${job._id}`)}
                      className="pointer"
                    >
                      View
                    </button>
                  </TableCell>
                  <TableCell>
                    <MenuBtn jobId={job._id} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !postedJob.length && !isLoading ? (
        <EmptyState
          title={"No  Posted  jobs  (yet)"}
          subtitle={"All posted jobs will appear here"}
          img={emptyState}
          btnText={"Post job"}
          fn={() => {
            navigate("/post-job");
          }}
        />
      ) : (
        <div className="postJob-preview-loader">
          <BounceLoader size="100px" color="#134271" />
        </div>
      )}
    </div>
  );
}
