import { Link } from "react-router-dom"
import "../../styles/website/PspForTalent.css"

export default function PspforTalentHero5(){
    return(
        <div className="hero4">
            <div>

            <header>
                <h4>The <span className="psp-secondary">all-in-one</span> platform for freelance work</h4>
            </header>

            <p>Are you hiring? &nbsp;  <Link className="explore-bsp-for-talents" to="/pspforclient"> Explore PSP for Client</Link></p>
            </div>
        </div>
    )
}

