// import Edit from "../../../assets/dashboard/icons/edit.svg";

export const ProfileEditableHeading = ({ title }) => {
  return (
    <div className="dashboard-profile-user-editable-header">
      <h5>{title}</h5>
      {/* <img src={Edit} alt="edit" /> */}
    </div>
  );
};
