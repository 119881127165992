import { AiOutlineClose } from "react-icons/ai";
import "../../../styles/dashboard/prompts.scss";

export default function Prompt({ action, fn, close }) {
  return (
    <div className="prompt-container" onClick={(e) => e.stopPropagation()}>
      <div className="prompt">
        <div className="text">
          Are you sure you want to {action}?{" "}
          <AiOutlineClose className="pointer" onClick={() => close()} />
        </div>

        <div className="buttons">
          <button onClick={() => close()}>Cancel</button>
          <button onClick={() => fn()}>Log Out</button>
        </div>
      </div>
    </div>
  );
}
