import SecondCard from "./SecondCard";

export default function OurBlog({ leftHeader, rightHeader }) {
  return (
    <div>
      <div className="ourBlog">
        <header>
          <h2>{leftHeader}</h2>
        </header>
        <header>
          <h4 className="pointer">{rightHeader}</h4>
        </header>
      </div>

      <div>
        <SecondCard
          title="Get started with PSP"
          time="August 31, 2023 • 12 min read"
          getStated="How to get started Psp for clients"
        />
      </div>
    </div>
  );
}
