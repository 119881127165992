import React, { useState } from "react";
import VerticalLabelInput from "../common/input/verticalLabelInput";
import BackButton from "../common/button/backButton";
import Button from "../common/button";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";
import ShowToast from "../../common/website/Toast";

export default function UserSkills({ onNextStep, prevStep }) {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const [currentSkill, setCurrentSkill] = useState("");

  const handleNext = () => {
    if (userDetails.skill.length === 0) {
      ShowToast({ type: "error", text: "Please add a skill" });
      return;
    }
    onNextStep();
  };
  const handlePrev = () => {
    prevStep();
  };

  const handleSkillChange = (e) => {
    setCurrentSkill(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && currentSkill.trim() !== "") {
      dispatch(
        setUserDetails({
          ...userDetails,
          skill: [...userDetails?.skill, currentSkill.trim()],
        })
      );
      setCurrentSkill("");
    }
  };

  return (
    <div>
      <div className="enterDetails_container">
        <div className="steps">Step 4/5</div>
        <h1>Tell us your sales force skill ?</h1>
        <span>Let know your strengths to help get the right gigs</span>
        <VerticalLabelInput
          label="Add up to three skills"
          value={currentSkill}
          onChange={handleSkillChange}
          onKeyDown={handleKeyDown}
        />
        <p style={{ margin: "10px 0 20px 0", fontSize: "14px", fontWeight: "600" }}>
          Please ensure to press the "Enter" key after entering each skill to
          maintain clear formatting (all skills input will be displayed on the right hand side beneath the profile photo)
        </p>
        <div className="enterDetails_buttons">
          <BackButton fn={handlePrev} text="Back" />
          <Button fn={handleNext} text="Next" />
        </div>
      </div>
    </div>
  );
}
