import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchTalentCard from "../find_talent/SearchTalentCard";

const DashboardRecommendedTalents = () => {
  const [searchTalent] = useState({
    searchByName: "",
    searchByLocation: "",
  });

  return (
    <div className="dashboard-recommended-talent-container">
      <div className="dashboard-applications-header-container">
        <h5>Recommended talents</h5>
        <Link to="/client-dashboard/talents/find">See All</Link>
      </div>
      <SearchTalentCard searchTalent={searchTalent} />
    </div>
  );
};

export default DashboardRecommendedTalents;
