import ThirdCard from "./ThirdCard";

export default function BenefitsOfBSP(){
    return(
        <div className="top-skill-container">
        <div className="top-skill">
      <header>
        <h4>
         Benefits of using PSP
        </h4>
      </header>
      <p>Most viewed and all-time top-selling services </p>
    </div>
    <ThirdCard/>
    </div>
    )
}