import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import * as Yup from "yup";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { selectUserDetails, setUserDetails } from "../../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";

const EditProfileModal = (props) => {
  const [currentSkill, setCurrentSkill] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    skills: Yup.array()
      .of(Yup.string())
      .min(1, "At least one skill is required"),
    bio: Yup.string().required("Bio is required"),
    location: Yup.string().required("Location is required"),
    links: Yup.string().required("links is required"),
    rates: Yup.object().shape({
      min: Yup.string().required("Minimum amount per hour is required"),
      max: Yup.string().required("Maximum amount per hour is required"),
      currency: Yup.string().required("Currency is required"),
    }),
  });

  const initialValues = {
    skills: userDetails.skill,
    bio: userDetails.bio,
    location: userDetails.location,
    links: userDetails.link,
    rates: {
      min: userDetails?.rates?.min,
      max: userDetails?.rates?.max,
      currency: userDetails?.rates?.currency,
    },
  };

  // Edit Profile
  useEffect(() => {
    const handleGetProfile = async () => {
      try {
        const response = await axiosInstance.get(`/profile`);
        const { data } = response.data;
        dispatch(setUserDetails(data));
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Cannot fetch profile details";
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    handleGetProfile();
  }, [dispatch]);

  const handleInputChange = (name, value) => {
    if (name.startsWith("rates.")) {
      const ratesField = name.split(".")[1]; // Get the rates field name
      dispatch(
        setUserDetails({
          ...userDetails,
          rates: {
            ...userDetails.rates,
            [ratesField]: value,
          },
        })
      );
    } else {
      // For other fields, update userDetails directly
      dispatch(setUserDetails({ ...userDetails, [name]: value }));
    }
  };

  // Edit Profile
  const handleProfileEdit = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.put(`/profile`, {
        skills: userDetails.skill,
        bio: userDetails.bio,
        location: userDetails.location,
        links: userDetails.link,
        rates: {
          min: userDetails?.rates?.min,
          max: userDetails?.rates?.max,
          currency: userDetails?.rates?.currency,
        },
      });
      setIsLoading(false);
      props.onClose();
      const successMessage =
        response.data.message || "Profile updated Successfully";
      ShowToast({ type: "success", text: successMessage });
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        "An error occured";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  return (
    <div className="certificate-modal-container" onClick={props.onClose}>
      <div
        className="certificate-modal-content"
        // style={{ maxWidth: "664px !important", width: "100% !important" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="close-certificate-modal">
          <button onClick={props.onClose} className="pointer">
            <IoClose />
          </button>
        </div>
        <div className="certification-modal-form-container">
          <p>Edit your profile</p>
          <p>Let Client Know about you </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleProfileEdit}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <>
                {console.log("values", values.rates.min)}
                <Form onSubmit={handleSubmit}>
                  <div className="certificate-form-container">
                    {/* Skills */}
                    <div className="certificate-input-container">
                      <label htmlFor="skills">Skills</label>
                      <p>
                        Associate at least 1 skill to this license or
                        certification. It’ll also appear in your skills section.
                      </p>
                      {/* Display the list of skills */}
                      {values.skills.length > 0 && (
                        <div>{values.skills.join(", ")}</div>
                      )}
                      <Field
                        type="text"
                        name="skills"
                        id="skills"
                        placeholder="Ex. Project management"
                        className="certificate-form-input"
                        value={currentSkill}
                        onChange={(e) => setCurrentSkill(e.target.value)}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && currentSkill.trim() !== "") {
                            e.preventDefault(); // Prevent form submission on "Enter"
                            const newSkills = [
                              ...values.skills,
                              currentSkill.trim(),
                            ];
                            handleChange({
                              target: { name: "skills", value: newSkills },
                            });
                            setCurrentSkill("");
                            handleInputChange("skills", newSkills);
                          }
                        }}
                      />
                      <ErrorMessage
                        name="skills"
                        component="div"
                        className="error"
                      />
                    </div>

                    {/* Bio */}
                    <div className="certificate-input-container">
                      <label htmlFor="bio">Bio</label>
                      <Field
                        type="text"
                        name="bio"
                        id="bio"
                        className="certificate-form-input"
                        placeholder="Ex. Hello World!"
                        value={values.bio}
                        // onChange={handleChange}
                        onChange={(e) => {
                          const { value } = e.target;
                          handleChange(e);
                          handleInputChange("bio", value);
                        }}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="bio"
                        component="div"
                        className="error"
                      />
                    </div>

                    {/* Location */}
                    <div className="certificate-input-container">
                      <label htmlFor="location">Location</label>
                      <Field
                        type="text"
                        name="location"
                        id="location"
                        placeholder="Ex. Abuja, Nigeria"
                        className="certificate-form-input"
                        value={values.location}
                        // onChange={handleChange}
                        onChange={(e) => {
                          const { value } = e.target;
                          handleChange(e);
                          handleInputChange("location", value);
                        }}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="location"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="postJob-input-container">
                      <label htmlFor="rates.min">Minimum Amount Per Hour</label>
                      <div className="postJob-double-input-container">
                        <Field
                          type="text"
                          name="rates.min"
                          id="input"
                          className="postJob-form-input"
                          value={values.rates.min}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleChange(e);
                            handleInputChange("rates.min", value);
                          }}
                          onBlur={handleBlur}
                        />
                        <hr />
                        <Field
                          as="select"
                          name="rates.currency"
                          id="rates.currency"
                          className="postJob-form-select"
                          value={values.rates.currency}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleChange(e);
                            handleInputChange("rates.currency", value);
                          }}
                          onBlur={handleBlur}
                        >
                          <option defaultValue="CAD">CAD</option>
                          <option value="NGN">NGN</option>
                          <option value="EUR">EUR</option>
                          <option value="USD">USD</option>
                        </Field>
                      </div>
                      <div className="postJob-currency">
                        <ErrorMessage
                          name="rates.min"
                          component="div"
                          className="error"
                        />
                        <ErrorMessage
                          name="rates.currency"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="postJob-input-container">
                      <label htmlFor="rates.max">Maximum Amount Per Hour</label>
                      <div className="postJob-double-input-container">
                        <Field
                          type="text"
                          name="rates.max"
                          id="rates.max"
                          className="postJob-form-input"
                          value={values.rates.max}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleChange(e);
                            handleInputChange("rates.max", value);
                          }}
                          onBlur={handleBlur}
                        />
                        <hr />
                        <Field
                          as="select"
                          name="rates.currency"
                          id="rates.currency"
                          className="postJob-form-select"
                          value={values.rates.currency}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleChange(e);
                            handleInputChange("rates.currency", value);
                          }}
                          onBlur={handleBlur}
                        >
                          <option defaultValue="CAD">CAD</option>
                          <option value="NGN">NGN</option>
                          <option value="EUR">EUR</option>
                          <option value="USD">USD</option>
                        </Field>
                      </div>
                      <div className="postJob-currency">
                        <ErrorMessage
                          name="rates.max"
                          component="div"
                          className="error"
                        />
                        <ErrorMessage
                          name="rates.currency"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    {/* link */}
                    <div className="certificate-input-container">
                      <label htmlFor="links">Links</label>
                      <Field
                        type="text"
                        name="links"
                        id="links"
                        placeholder="www.cloud.mylinks.com"
                        className="certificate-form-input"
                        value={values.links}
                        // onChange={handleChange}
                        onChange={(e) => {
                          const { value } = e.target;
                          handleChange(e);
                          handleInputChange("links", value);
                        }}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="links"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="change-password-submit-button-container">
                    <button type="submit" className="cursor">
                      {isLoading ? (
                        <ScaleLoader height={15} color="#ffffff" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;
