import FindJob from "../../../components/dashboard/find_job";
import MainLayout from "../../../layouts/mainLayout";

export default function FindJobPage() {
  return (
    <MainLayout navTitle={"Find Job"}>
      <FindJob />
    </MainLayout>
  );
}
