import "../../styles/dashboard/sidebar.scss";
import { BsGrid, BsGridFill } from "react-icons/bs";
import {
  AiFillMessage,
  AiFillSetting,
  AiOutlineMessage,
  AiOutlineSetting,
} from "react-icons/ai";
import { BiSolidUser, BiUser } from "react-icons/bi";
import logo from "../../assets/website/icons/logo.svg";
import { Link, useLocation } from "react-router-dom";
import wallet from "../../assets/dashboard/icons/wallet.svg";
import saved from "../../assets/dashboard/icons/saved.svg";
import saved2 from "../../assets/dashboard/icons/saved2.svg";
import wallet2 from "../../assets/dashboard/icons/wallet2.svg";
import findJob from "../../assets/dashboard/icons/findJob.svg";
import findJob2 from "../../assets/dashboard/icons/findJob2.svg";
import projects from "../../assets/dashboard/icons/projects.svg";
import projects2 from "../../assets/dashboard/icons/projects2.svg";
// import offers from "../../assets/dashboard/icons/offers.svg";
import PostedJobs from "../../assets/dashboard/icons/PostedJobs.svg";
import PostedJobs2 from "../../assets/dashboard/icons/PostedJobs2.svg";
import application from "../../assets/dashboard/icons/application.svg";
import application2 from "../../assets/dashboard/icons/application2.svg";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../../features/userSlice";

export default function Sidebar() {
  const location = useLocation();
  const userDetails = useSelector(selectUserDetails);
  const pathname = location.pathname.split("/:")[0];

  function switchIcons(link, icon1, icon2) {
    return pathname !== link ? icon1 : icon2;
  }

  const sidebarItems = [
    {
      sectionHead: "Menu",
      sectionItems: [
        {
          item: "Dashboard",
          icon: switchIcons("/dashboard", <BsGrid />, <BsGridFill />),
          link: "/dashboard",
          function: () => {},
        },
        {
          item: "Find job",
          icon: switchIcons(
            "/dashboard/jobs/find",
            <img src={findJob} alt="find job" />,
            <img src={findJob2} alt="find job" />
          ),
          link: "/dashboard/jobs/find",
          function: () => {},
        },
        {
          item: "Projects",
          icon: switchIcons(
            "/dashboard/projects",
            <img src={projects} alt="projects" />,
            <img src={projects2} alt="projects" />
          ),
          link: "/dashboard/projects",
          function: () => {},
        },
        // {
        //   item: "Offers",
        //   icon: switchIcons(
        //     "/dashboard/offers",
        //     <img src={offers} alt="offers" />,
        //     <img src={offers} alt="offers" />
        //   ),
        //   link: "/dashboard/offers",
        //   function: () => {},
        // },
        {
          item: "Saved",
          icon: switchIcons(
            "/dashboard/jobs/saved",
            <img src={saved} alt="saved jobs" />,
            <img src={saved2} alt="saved jobs" />
          ),
          link: "/dashboard/jobs/saved",
          function: () => {},
        },
        {
          item: "Wallet",
          icon: switchIcons(
            "/dashboard/wallet",
            <img src={wallet} alt="wallet" />,
            <img src={wallet2} alt="wallet" />
          ),
          link: "/dashboard/wallet",
          function: () => {},
        },
        {
          item: "Messages",
          icon: switchIcons(
            "/dashboard/messages",
            <AiOutlineMessage />,
            <AiFillMessage />
          ),
          link: "/dashboard/messages",
          function: () => {},
        },
        {
          item: "Profile",
          icon: switchIcons("/dashboard/profile", <BiUser />, <BiSolidUser />),
          link: "/dashboard/profile",
          function: () => {},
        },
      ],
    },
    // {
    //   sectionHead: "Preferences",
    //   sectionItems: [
    //     {
    //       item: "Settings",
    //       icon: switchIcons(
    //         "/dashboard/settings",
    //         <AiOutlineSetting />,
    //         <AiFillSetting />
    //       ),
    //       link: "/dashboard/settings",
    //       function: () => {},
    //     },
    //   ],
    // },
  ];

  const clientSidebarItems = [
    {
      sectionHead: "Menu",
      sectionItems: [
        {
          item: "Dashboard",
          icon: switchIcons("/client-dashboard", <BsGrid />, <BsGridFill />),
          link: "/client-dashboard",
          function: () => {},
        },
        {
          item: "Find talent",
          icon: switchIcons(
            "/client-dashboard/talents/find",
            <img src={findJob} alt="find job" />,
            <img src={findJob2} alt="find job" />
          ),
          link: "/client-dashboard/talents/find",
          function: () => {},
        },
        {
          item: "Application",
          icon: switchIcons(
            "/client-dashboard/application",
            <img src={application} alt="application" />,
            <img src={application2} alt="application" />
          ),
          link: "/client-dashboard/application",
          function: () => {},
        },
        {
          item: "Projects",
          icon: switchIcons(
            "/client-dashboard/projects",
            <img src={projects} alt="projects" />,
            <img src={projects2} alt="projects" />
          ),
          link: "/client-dashboard/projects",
          function: () => {},
        },
        {
          item: "Posted jobs",
          icon: switchIcons(
            "/client-dashboard/jobs/posted",
            <img src={PostedJobs} alt="Posted jobs" />,
            <img src={PostedJobs2} alt="Posted jobs" />
          ),
          link: "/client-dashboard/jobs/posted",
          function: () => {},
        },
        {
          item: "Wallet",
          icon: switchIcons(
            "/client-dashboard/wallet",
            <img src={wallet} alt="wallet" />,
            <img src={wallet2} alt="wallet" />
          ),
          link: "/client-dashboard/wallet",
          function: () => {},
        },
        {
          item: "Messages",
          icon: switchIcons(
            "/client-dashboard/messages",
            <AiOutlineMessage />,
            <AiFillMessage />
          ),
          link: "/client-dashboard/messages",
          function: () => {},
        },
        // {
        //   item: "Profile",
        //   icon: switchIcons(
        //     "/client-dashboard/profile",
        //     <BiUser />,
        //     <BiSolidUser />
        //   ),
        //   link: "/client-dashboard/profile",
        //   function: () => {},
        // },
      ],
    },
    {
      sectionHead: "Preferences",
      sectionItems: [
        {
          item: "Settings",
          icon: switchIcons(
            "/client-dashboard/settings",
            <AiOutlineSetting />,
            <AiFillSetting />
          ),
          link: "/client-dashboard/settings",
          function: () => {},
        },
      ],
    },
  ];

  return (
    <div className="sidebar-container">
      <div className="sidebar-content">
        <div className="logo-section">
          <div className="logo">
            <img src={logo} alt="Pinacle Solutions Platform" />
          </div>
        </div>

        <div className="sidebar-links-section">
          {userDetails.type.toLowerCase() === "talent"
            ? sidebarItems.map((item, i) => {
                return (
                  <div key={i}>
                    <div className="section-head">{item.sectionHead}</div>

                    {item.sectionItems?.map((sidebar, i) => {
                      return (
                        <Link to={sidebar.link}>
                          <div
                            className={
                              pathname === sidebar.link
                                ? "active-sidebar-item "
                                : "sidebar-item "
                            }
                          >
                            <div className="icon">{sidebar.icon}</div>
                            <div className="text">{sidebar.item}</div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                );
              })
            : clientSidebarItems.map((item, i) => {
                return (
                  <div key={i}>
                    <div className="section-head">{item.sectionHead}</div>

                    {item.sectionItems?.map((sidebar, i) => {
                      return (
                        <Link to={sidebar.link}>
                          <div
                            className={
                              pathname === sidebar.link
                                ? "active-sidebar-item "
                                : "sidebar-item "
                            }
                          >
                            <div className="icon">{sidebar.icon}</div>
                            <div className="text">{sidebar.item}</div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
