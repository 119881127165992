import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const CloseAccount = (props) => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  // Handle form submission
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission logic here
    console.log("Submitted values:", values);
    resetForm();
  };

  return (
    <div className="modal-container" onClick={props.handleClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="close-signup-modal">
          <button onClick={props.handleClick} className="pointer">
            <IoClose />
          </button>
        </div>
        <div className="change-password-form-container">
          <p>Close Account</p>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="postJob-form-container">
                  <div className="postJob-input-container">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="password"
                      name="email"
                      id="email"
                      className="postJob-form-input"
                      placeholder="Enter your email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div className="change-password-submit-button-container">
                  <button type="submit" className="cursor">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CloseAccount;
