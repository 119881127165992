import { ProfileCertification } from "../../../components/dashboard/profile/ProfileCertification";
import ProfileDetails from "../../../components/dashboard/profile/ProfileDetails";
import MainLayout from "../../../layouts/mainLayout";
import "../../../styles/dashboard/dashboardProfile.scss";

export default function Profile() {
  return (
    <MainLayout navTitle={"Profile"}>
      <div className="dashboard-profile-container">
        <ProfileDetails />
        <ProfileCertification />
      </div>
    </MainLayout>
  );
}
