import logo from "../images/images/Layer_1.svg";
import authBannerLabel from "../images/images/Group 427320684.svg";
import authBanner from "../images/images/beam-browser-window 1.svg";
import regBanner from "../images/images/beam-woman-is-working-at-a-computer 1.svg";
import eye from "../icons/linear.svg";
import eyeSlash from "../icons/eye-slash.svg";
import google from "../icons/Google.svg";
import personIcon from "../icons/personIcon.svg";
import userIcon from "../icons/userIconBig.svg";
import locationIcon from "../icons/location.svg";
import arrowLeft from "../icons/arrow-left.svg";
import colorSearch from "../icons/icons8-search 1.svg";
import moneyBag from "../icons/icons8-money-bag 1.svg";
import money from "../icons/icons8-money 1.svg";
import firstPlace from "../icons/icons8-first-place-ribbon 1.svg";
import pro from "../icons/pro427322051.svg"


// IMAGES
export const LOGO = logo
export const AUTH_BANNER_LABEL = authBannerLabel;
export const AUTH_BANNER = authBanner
export const REG_BANNER = regBanner

// ICONS
export const EYE_ICON = eye
export const EYE_SLASH_ICON = eyeSlash
export const GOOGLE_ICON = google
export const USER_ICON = personIcon

export const COLOR_SEARCH_ICON = colorSearch
export const MONEY_BAG_ICON = moneyBag
export const MONEY_ICON = money
export const FIRST_PLACE_ICON = firstPlace

export const USER_ICON_BIG = userIcon;
export const ARROW_LEFT = arrowLeft;
export const LOCATION = locationIcon;
export const PRO = pro