import { useEffect, useState } from "react";
import AddCertificate from "../../../assets/dashboard/icons/add.svg";
import OutlinedButton from "../../common/button/outlinedButton";
import Trash from "../../../assets/dashboard/icons/trash.svg";
import emptyState from "../../../assets/dashboard/icons/EmptyState.svg";
import EmptyState from "../saved/EmptyState";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import CertificateModal from "./CertificateModal";
import QRCode from "react-qr-code";

export const ProfileCertification = () => {
  const [certifications, setCertifications] = useState([]);
  const [certificateModal, setCertificateModal] = useState(false);

  const closeModal = () => {
    setCertificateModal(false);
  };

  // Get certificate
  const getCertifications = async () => {
    try {
      // setIsLoading(true);
      const response = await axiosInstance.get("/profile/certificates");
      const res = response.data.data;
      setCertifications(res);
      const successMessage = response.data.message || "Certificates fetched";
      // ShowToast({ type: "success", text: successMessage });
      // setIsLoading(false);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "Error fetching Certificates";
      // setIsLoading(false);
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  useEffect(() => {
    getCertifications();
  }, []);

  // Delete Certificate
  const deleteCertification = async (id) => {
    try {
      // setIsLoading(true);
      const response = await axiosInstance.delete(`/profile/certificate/${id}`);
      const successMessage = response.data.message || "Certificate deleted";
      ShowToast({ type: "success", text: successMessage });
      getCertifications();
      // setIsLoading(false);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "Error deleting Certificate";
      // setIsLoading(false);
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  return (
    <>
      {certificateModal && (
        <CertificateModal
          onClose={() => setCertificateModal(false)}
          getCert={() => getCertifications()}
          handleClick={closeModal}
        />
      )}

      <div className="dashboard-profile-certifications-container">
        <div className="dashboard-profile-add-certifications-container">
          <div className="dashboard-profile-add-certifications">
            <h3>Certifications</h3>
            <img
              onClick={() => {
                setCertificateModal(true);
              }}
              src={AddCertificate}
              className="pointer"
              alt="add certificate"
            />
          </div>
        </div>

        {/* CERTIFICATION LIST */}

        {certifications?.length ? (
          certifications?.map((cert, i) => (
            <div
              key={i}
              className="dashboard-profile-list-certifications-container"
            >
              <div className="dashboard-profile-list-certifications">
                <div style={{ display: "flex", justifyContent: "center"}}>
                  <QRCode value={cert?.credential_url} size={120} /> 
                </div>
                <div className="dashboard-profile-list-certifications-details">
                  <h4>{cert.name}</h4>
                  <p>{cert.type}</p>
                  <p>Credential ID {cert.credential_id}</p>
                  <OutlinedButton text="Show credential" padding="10px 23px" />
                </div>
                <OutlinedButton
                  text="Remove"
                  icon={Trash}
                  padding="10px 17px"
                  onClick={() => deleteCertification(cert._id)}
                />
              </div>
            </div>
          ))
        ) : (
          <EmptyState
            title={"No certifications"}
            subtitle={"All Certifications will appear here"}
            img={emptyState}
            btnText={"Add Certifications"}
            fn={() => {
              setCertificateModal(true);
            }}
          />
        )}
      </div>
    </>
  );
};
