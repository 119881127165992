import { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

export default function FrequentlyAskedQuestions() {
  const [show, setShow] = useState();
  const [openItemIndex, setOpenItemIndex] = useState(null);

  let list = [
    {
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 1,
    },
    {
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 2,
    },
    {
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 3,
    },
    {
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 4,
    },
  ];

  const handleItemClick = (index) => {
    if (index === openItemIndex) {
      setOpenItemIndex(null);
    } else {
      setOpenItemIndex(index);
    }
  };
  return (
    <div className="talent-page-frequently-asked-questions">
      <div className="talent-page-frequently-asked-questions-left">
        <header>
          <h4>Frequently Asked Question</h4>
        </header>
        <p>Did you find the question as you expected ?</p>
      </div>
      <div className="talent-page-frequently-asked-questions-right">
        {list.map((item, i) => (
          <div className="" onClick={() => setShow(!show)} key={item.id}>
            <div
              className="talent-page-question-and-button-container"
              onClick={() => handleItemClick(i)}
            >
              <h4>{item.title}</h4>
              <button className="cursor">
                {" "}
                {i === openItemIndex ? (
                  <AiOutlineMinus className="arrowUp" />
                ) : (
                  <AiOutlinePlus className="arrowDown" />
                )}
              </button>
            </div>

            {i === openItemIndex && (
              <div className="talent-page-answers">
                <p>{item.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
