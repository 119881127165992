import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { selectJobDetails, setJobDetails } from "../../features/jobSlice";
import * as Yup from "yup";

// const validationSchema = Yup.object().shape({
//   role: Yup.string().required("Role is required"),
//   location: Yup.string().required("Location is required"),
//   // tags: Yup.array()
//   //   .min(3, "At least 3 skills must be selected")
//   //   .required("At least 3 skills must be selected"),
//   tags: Yup.string().required("Skills must be selected"),
//   notice: Yup.string().required("Notice is required"),
//   duration: Yup.string().required("Duration is required"),
//   min: Yup.string().required("Minimum amount is required"),
//   max: Yup.string().required("Minimum amount is required"),
//   currency: Yup.string().required("Currency is required"),
// });

const StepOne = ({ onNextStep, jobId }) => {
  // const [selectedSkills, setSelectedSkills] = useState([]);
  const jobDetails = useSelector(selectJobDetails);
  const dispatch = useDispatch();

  const initialValues = {
    role: jobDetails.role,
    location: jobDetails.location,
    tags: jobDetails.tags,
    notice: jobDetails.notice,
    duration: jobDetails.duration,
    min: jobDetails.min,
    max: jobDetails.max,
    currency: jobDetails.currency,
  };

  const handleInputChange = (e, handleChange) => {
    // handleChange(e);
    const { name, value } = e.target;
    // dispatch(setJobDetails({ ...jobDetails, [name]: value }));

    // if (name === "tags") {
    //   // Split the selected skills into an array
    //   const selectedSkillsArray = Array.from(
    //     e.target.selectedOptions,
    //     (option) => option.value
    //   );
    //   handleChange({
    //     target: {
    //       name,
    //       value: selectedSkillsArray, // Update the value of 'tags' directly in Formik
    //     },
    //   });
    // } else {
    // For other fields, update as usual
    handleChange(e);
    dispatch(setJobDetails({ ...jobDetails, [name]: value }));
    // }
  };

  const handleOnSubmit = () => {
    onNextStep();
  };

  // const handleSkillSelection = (e) => {
  //   const skill = e.target.value;
  //   if (!selectedSkills.includes(skill)) {
  //     setSelectedSkills([...selectedSkills, skill]);
  //   }
  // };

  // const handleSkillRemoval = (index) => {
  //   const updatedSkills = selectedSkills.filter((_, i) => i !== index);
  //   setSelectedSkills(updatedSkills);
  // };

  return (
    <Formik
      // validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="postJob-form-container">
            <div className="postJob-input-container">
              <label htmlFor="role">Role</label>
              <Field
                as="select"
                name="role"
                id="role"
                className="postJob-form-select"
                placeholder="Ex. Administrator"
                value={values.role}
                onChange={(e) => handleInputChange(e, handleChange)}
                onBlur={handleBlur}
              >
                <option value="">Ex. Administrator</option>
                <option value="frontend">Frontend</option>
                <option value="backend">Backend</option>
                <option value="devops">DevOps</option>
              </Field>
              <ErrorMessage name="role" component="div" className="error" />
            </div>

            {/* <div className="postJob-input-container">
              <label htmlFor="location">Location</label>
              <Field
                type="text"
                name="location"
                id="location"
                placeholder="Ex. Abuja, Nigeria"
                className="postJob-form-input"
                value={values.location}
                onChange={(e) => handleInputChange(e, handleChange)}
                onBlur={handleBlur}
              />
              <ErrorMessage name="location" component="div" className="error" />
            </div> */}

            <div className="postJob-input-container">
              <label htmlFor="tags">Skills</label>
              <Field
                as="select"
                name="tags"
                id="tags"
                className="postJob-form-select"
                value={values.tags}
                onChange={(e) => {
                  handleInputChange(e, handleChange);
                  // handleSkillSelection(e);
                }}
                onBlur={handleBlur}
              >
                <option value="">Ex. Project management</option>
                <option value="javascript">Javascript</option>
                <option value="node javascript">Node Javascript</option>
                <option value="cloud computing">Cloud Computing</option>
                <option value="javascript">Javascript</option>
                <option value="node javascript">Node Javascript</option>
                <option value="cloud computing">Cloud Computing</option>
              </Field>
              <ErrorMessage name="tags" component="div" className="error" />
              {/* Display selected skills */}
              {/* <div>
                {selectedSkills.map((skill, index) => (
                  <div
                    key={index}
                    onClick={() => handleSkillRemoval(index)}
                    className="selected-skill cursor"
                  >
                    {skill}
                  </div>
                ))}
              </div> */}
            </div>

            <div className="postJob-input-container">
              <label htmlFor="notice">Notice  &nbsp;(project commencement)</label>
              <Field
                as="select"
                name="notice"
                id="notice"
                className="postJob-form-select"
                value={values.notice}
                onChange={(e) => handleInputChange(e, handleChange)}
                onBlur={handleBlur}
              >
                <option value="">Ex. Immediately</option>
                <option value="1 week">1 Week</option>
                <option value="2 weeks">2 Weeks</option>
                <option value="1 month">1 Month</option>
              </Field>
              <ErrorMessage name="notice" component="div" className="error" />
            </div>

            <div className="postJob-input-container">
              <label htmlFor="duration">Duration &nbsp;(project completion)</label>
              <Field
                type="text"
                name="duration"
                id="duration"
                placeholder="Ex. 1- 4weeks"
                className="postJob-form-input"
                value={values.duration}
                onChange={(e) => handleInputChange(e, handleChange)}
                onBlur={handleBlur}
              />
              <ErrorMessage name="duration" component="div" className="error" />
            </div>

            <div className="postJob-input-container">
              <label htmlFor="min">Minimum Amount Per Hour</label>
              <div className="postJob-double-input-container">
                <Field
                  type="text"
                  name="min"
                  id="input"
                  className="postJob-form-input"
                  value={values.min}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                />
                <hr />
                <Field
                  as="select"
                  name="currency"
                  id="currency"
                  className="postJob-form-select"
                  value={values.currency}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                >
                  <option defaultValue="CAD">CAD</option>
                  <option value="NGN">NGN</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                </Field>
              </div>
              <div className="postJob-currency">
                <ErrorMessage name="min" component="div" className="error" />
                <ErrorMessage
                  name="currency"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="postJob-input-container">
              <label htmlFor="max">Maximum Amount Per Hour</label>
              <div className="postJob-double-input-container">
                <Field
                  type="text"
                  name="max"
                  id="max"
                  className="postJob-form-input"
                  value={values.max}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                />
                <hr />
                <Field
                  as="select"
                  name="currency"
                  id="currency"
                  className="postJob-form-select"
                  value={values.currency}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                >
                  <option defaultValue="CAD">CAD</option>
                  <option value="NGN">NGN</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                </Field>
              </div>
              <div className="postJob-currency">
                <ErrorMessage name="max" component="div" className="error" />
                <ErrorMessage
                  name="currency"
                  component="div"
                  className="error"
                />
              </div>
            </div>
          </div>
          <div className="postJob-submit-button-container">
            <button type="submit" className="cursor">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepOne;
