export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDate = date
    .toLocaleDateString("en-US", options)
    .replace(",", "");
  return formattedDate;
};
