import TalentSearch from "../../../components/dashboard/find_talent/TalentSearch";
import SearchTalentCard from "../../../components/dashboard/find_talent/SearchTalentCard";
import MainLayout from "../../../layouts/mainLayout";
import { useState } from "react";

export default function FindTalentPage() {
  const [searchTalent, setSearchTalent] = useState({
    searchByName: "",
    searchByLocation: "",
  });
  return (
    <MainLayout navTitle={"Find Talent"}>
      <TalentSearch
        searchTalent={searchTalent}
        setSearchTalent={setSearchTalent}
      />
      <SearchTalentCard searchTalent={searchTalent} />
    </MainLayout>
  );
}
