import React, { useState } from "react";
import VerticalLabelInput from "../common/input/verticalLabelInput";
import BackButton from "../common/button/backButton";
import Button from "../common/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";
import Link from "../../assets/website/icons/link.svg";
import ImgInput from "../common/input/imgInput";
import Location from "../../assets/website/icons/location.svg";
import ShowToast from "../../common/website/Toast";
import axiosInstance from "../../services/AxiosConfig";
import { ScaleLoader } from "react-spinners";
import { persistor } from "../../app/configureStore";

export default function UserBio({ prevStep }) {
  const userDetails = useSelector(selectUserDetails);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientNavigateTo = async () => {
    if (
      userDetails.link.trim() === "" ||
      userDetails.profilePic.trim() === ""
    ) {
      ShowToast({ type: "error", text: "Please fill in all field" });
      return;
    }
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        "/authentication/onboard/client",
        {
          name: userDetails.displayName,
          location: userDetails.location,
          image: userDetails.profilePic,
          link: userDetails.link,
          individual: userDetails.individualHiring,
        }
      );
      const successMessage = response.data.message || "Onboarding Successful";
      ShowToast({ type: "success", text: successMessage });
      navigate("/auth/login");
      persistor.purge();
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        "An error occured during onboarding";
      ShowToast({ type: "error", text: errorMessage });
    }
    setIsLoading(false);
  };

  const talentNavigateTo = async () => {
    if (userDetails.location.trim() === "") {
      ShowToast({ type: "error", text: "Please fill in a location" });
      return;
    }

    if (userDetails.bio.trim() === "") {
      ShowToast({ type: "error", text: "Please fill in your bio" });
      return;
    }

    if (userDetails.profilePic.trim() === "") {
      ShowToast({ type: "error", text: "Please upload an image" });
      return;
    }

    try {
      const response = await axiosInstance.post(
        "/authentication/onboard/talent",
        {
          goal: userDetails.goal,
          display_name: userDetails.displayName,
          category: userDetails.category,
          skills: userDetails.skill,
          location: userDetails.location,
          bio: userDetails.bio,
          image: userDetails.profilePic,
        }
      );
      const successMessage = response.data.message || "Onboarding Successful";
      ShowToast({ type: "success", text: successMessage });
      navigate("/auth/login");
      persistor.purge();
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        "An error occured during onboarding";
      ShowToast({ type: "error", text: errorMessage });
    }
    setIsLoading(false);
  };
  const handlePrev = () => {
    prevStep();
  };

  return (
    <div>
      {userDetails.type === "Talent" ? (
        <div className="enterDetails_container">
          <div className="steps">Step 5/5</div>
          <h1>Tell us about you?</h1>
          <span>We’ll use this information to fill your bio</span>
          <VerticalLabelInput
            icon={Location}
            placeholder="Enter location"
            value={userDetails.location}
            onChange={(e) =>
              dispatch(
                setUserDetails({ ...userDetails, location: e.target.value })
              )
            }
          />
          <VerticalLabelInput
            placeholder="Enter Bio"
            value={userDetails.bio}
            onChange={(e) =>
              dispatch(setUserDetails({ ...userDetails, bio: e.target.value }))
            }
          />
          {/* <VerticalLabelInput
            placeholder="Enter Pic Link"
            value={userDetails.profilePic}
            onChange={(e) =>
              dispatch(
                setUserDetails({ ...userDetails, profilePic: e.target.value })
              )
            }
          /> */}
          <ImgInput
            label="Add a photo to help build connection"
            title="Upload profile photo"
          />
          <div className="enterDetails_buttons">
            <BackButton fn={handlePrev} text="Back" />
            <Button fn={talentNavigateTo} text="Next" />
          </div>
        </div>
      ) : (
        // CLIENT
        <div className="enterDetails_container">
          <div className="steps">Step 3/3</div>
          <h1>Almost there!</h1>
          <span>Upload company logo for trust</span>
          <ImgInput
            label="Add a photo to help build connection"
            title="Upload profile photo"
          />
          {/* <VerticalLabelInput
            placeholder="Enter Pic Link"
            value={userDetails.profilePic}
            onChange={(e) =>
              dispatch(
                setUserDetails({ ...userDetails, profilePic: e.target.value })
              )
            }
          /> */}
          <VerticalLabelInput
            icon={Link}
            label="Add links"
            placeholder="Website, linkedin"
            value={userDetails.link}
            onChange={(e) =>
              dispatch(setUserDetails({ ...userDetails, link: e.target.value }))
            }
          />
          <div className="enterDetails_buttons">
            <BackButton fn={handlePrev} text="Back" />
            <Button
              fn={clientNavigateTo}
              text={
                isLoading ? (
                  <ScaleLoader height={15} color="#ffffff" />
                ) : (
                  "Submit"
                )
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
