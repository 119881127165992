import { useState } from "react";
import Byskill from "../website/BySkill";
import Location from "../website/Location";

export default function TopSkills() {
  const [view, setView] = useState({
    Byskill: true,
    location: false,
  });

  return (
    <div className="top-skill-container">
      <div className="top-skill">
        <header>
          <h4 className="top-skill-for-each-category">
            Top skills for each category
          </h4>
        </header>
        <p>Most viewed and all-time top-selling services </p>
      </div>

      <div className="find-talent-buttons">
        <button
          onClick={() => setView({ location: false, Byskill: true })}
          className={`find-talent-button-one cursor ${
            view.Byskill ? "active" : ""
          }`}
        >
          By Skill
        </button>

        <button
          onClick={() => setView({ location: true, Byskill: false })}
          className={`find-talent-button-two cursor ${
            view.location ? "active" : ""
          }`}
        >
          Location
        </button>
      </div>
      {view.Byskill ? <Byskill /> : view.location ? <Location /> : null}
    </div>
  );
}
