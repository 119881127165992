import React from "react";
import "./styles.scss";

export default function OutlinedButton({ text, onClick, icon, padding }) {
  return (
    <div className="outlined-button-container">
      <button
        onClick={onClick}
        type="button"
        className="button"
        style={{ padding }}
      >
        {text}
        <img src={icon || null} alt={icon || null} />
      </button>
    </div>
  );
}
