import OurBlog from "../../components/website/OurBlog";
import Footer from "../../common/website/Footer";
import Navigation from "../../common/website/Navbar";
import TopSkills from "../../components/website/TopSkills";
import StartHiring from "../../components/website/StartHiring";
import BenefitsOf from "../../components/website/BenefitOfBSP";
import PostToday from "../../components/website/PostToday";
import Hero2 from "../../components/website/Hero2";

export default function SecondPageFindTalent() {
  return (
    <div>
      <Navigation />
      <Hero2 />
      <TopSkills />
      <PostToday />
      <StartHiring />
      <BenefitsOf />
      <OurBlog leftHeader="Our Blog" rightHeader="See more" />
      <Footer />
    </div>
  );
}
