import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CompanyLogo from "../../../assets/dashboard/icons/CompanyLogo.svg";
import CompanyLogo1 from "../../../assets/dashboard/icons/CompanyLogo-1.svg";
import NoApplication from "../../../assets/dashboard/icons/NoApplication.svg";
import ColoredButton from "../../common/button/coloredButtons";
import EmptyState from "../saved/EmptyState";


const myApplications = [
  // {
  //   companyLogo: CompanyLogo,
  //   title: "Social Media Assistant",
  //   companyName: "Nomad",
  //   location: "Paris, France",
  //   dateTitle: "Date Applied",
  //   date: "24 July 2023",
  //   btnText: "Waiting",
  // },
  // {
  //   companyLogo: CompanyLogo1,
  //   title: "Social Media Assistant",
  //   companyName: "Nomad",
  //   location: "Paris, France",
  //   dateTitle: "Date Applied",
  //   date: "24 July 2023",
  //   btnText: "Hired",
  // },
  // {
  //   companyLogo: CompanyLogo1,
  //   title: "Social Media Assistant",
  //   companyName: "Nomad",
  //   location: "Paris, France",
  //   dateTitle: "Date Applied",
  //   date: "24 July 2023",
  //   btnText: "Waiting",
  // },
  // {
  //   companyLogo: CompanyLogo1,
  //   title: "Social Media Assistant",
  //   companyName: "Nomad",
  //   location: "Paris, France",
  //   dateTitle: "Date Applied",
  //   date: "24 July 2023",
  //   btnText: "Hired",
  // },
];

export const DashboardApplications = () => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-applications-container">
      <div className="dashboard-applications-header-container">
        <h5>My Applications</h5>
        <Link to="#">View All</Link>
      </div>
      {myApplications.length > 0 ? (
        <div className="dashboard-applications-list-container">
          {myApplications.map((applications, i) => (
            <div key={i} className="dashboard-applications-list-item-container">
              <div className="dashboard-applications-company-details">
                <img
                  src={applications.companyLogo}
                  alt={applications.companyName}
                />
                <div className="dashboard-applications-company-name">
                  <h5>{applications.title}</h5>
                  <p>
                    {applications.companyName} &#8226; {applications.location}
                  </p>
                </div>
              </div>
              <div className="dashboard-applications-date-details">
                <p>{applications.dateTitle}</p>
                <p>{applications.date}</p>
              </div>
              <ColoredButton
                text={applications.btnText}
                padding="2px 20px"
                backgroundColor={
                  applications.btnText.toLowerCase() === "waiting"
                    ? "rgba(255, 250, 224, 0.7)"
                    : "#EAFCE4"
                }
                width="117px"
                color={
                  applications.btnText.toLowerCase() === "waiting"
                    ? "#DB9B10"
                    : "#229337"
                }
                fontWeight="700"
                lineHeight="24px"
              />
            </div>
          ))}
        </div>
      ) : (
        <EmptyState
          subtitle={"Apply for your next project"}
          img={NoApplication}
          btnText={"Browse jobs"}
          fn={() => navigate('/dashboard/jobs/find')}
        />
      )}
    </div>
  );
};
