import { useParams } from "react-router-dom";
import JobDetails from "../../../components/dashboard/find_job/JobDetails";
import MainLayout from "../../../layouts/mainLayout";

export default function SingleJob() {
  const { jobId } = useParams();

  return (
    <MainLayout navTitle={"Find Job"}>
      <JobDetails jobId={jobId} />
    </MainLayout>
  );
}
