import React, { useEffect, useState } from "react";
import Payouts from "../../../assets/dashboard/icons/payouts.svg";
import Logo from "../../../assets/dashboard/icons/logo.svg";
import Ellipse from "../../../assets/dashboard/icons/Ellipse.svg";
import Ellipse1 from "../../../assets/dashboard/icons/Ellipse1.svg";
import EmptyState from "../saved/EmptyState";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { selectActiveUser } from "../../../features/activeUserSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const DashboardPayouts = () => {
  const [wallet, setWallet] = useState(false);
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const activeUser = useSelector(selectActiveUser);

  // Get wallet
  const handleGetWallet = async () => {
    try {
      const getWallet = await axiosInstance.get("/wallet");
      const walletAddress = getWallet.data.data;
      setWallet(walletAddress);
      const successMessage = getWallet.data.message || "Wallet fetched";
      // ShowToast({ type: "success", text: successMessage });
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ||
        "An error occurred while fetching wallet";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // Create wallet
  const handleWalletCreation = async () => {
    setIsLoadingWallet(true);
    try {
      if (!activeUser?._wallet) {
        const createWallet = await axiosInstance.post("/wallet");
        setIsLoadingWallet(false);
        const successMessage = createWallet.data.message || "Wallet created";
        ShowToast({ type: "success", text: successMessage });
      }
    } catch (error) {
      setIsLoadingWallet(false);
      const errorMessage =
        error?.response?.data?.error ||
        "An error occurred during wallet creation";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  useEffect(() => {
    if (activeUser?._wallet) {
      handleGetWallet();
    }
  }, [activeUser?._wallet]);

  return (
    <div className="dashboard-payout-container">
      {activeUser?._wallet ? (
        <>
          <h5>Total payouts</h5>
          <div className="dashboard-payout-card">
            <div className="dashboard-payout-card-logo">
              <img src={Logo} alt="Pinnacle Solution Platform" />
              <img src={Ellipse1} alt="Ellipse" />
            </div>
            <div className="dashboard-payout-amount">
              <div>
                <p>Total payouts</p>
                <h3>
                  {wallet?.currency}{" "}
                  {parseFloat(wallet?.payout?.$numberDecimal).toLocaleString()}
                </h3>
              </div>
              <img src={Ellipse} alt="Ellipse" />
            </div>
            <Link to="/dashboard/wallet">
              <button className="dashboard-payout-wallet pointer">
                View wallet
              </button>
            </Link>
          </div>
        </>
      ) : (
        <EmptyState
          subtitle={"Get paid on time"}
          img={Payouts}
          btnText={isLoadingWallet ? "Processing..." : "Set up your wallet"}
          fn={() => handleWalletCreation()}
        />
      )}
    </div>
  );
};
