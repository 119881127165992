import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "../../../styles/postJob/postJob.scss";
import React, { useEffect, useState } from "react";
import EmptyState from "../saved/EmptyState";
import { BounceLoader } from "react-spinners";
import { Link } from "react-router-dom";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import emptyState from "../../../assets/dashboard/icons/emptyTransactions.svg";
import filter from "../../../assets/dashboard/icons/Filter.svg";

const WalletTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [walletTransactions, setWalletTransactions] = useState([]);

  const tableHead = [
    { head: "Name", filter: filter },
    { head: "Amount", filter: filter },
    { head: "Type", filter: filter },
    { head: "Date", filter: filter },
    { head: "Staus", filter: filter },
  ];

  // Get transactions
  const getWalletTransactions = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get("/transactions");
      const res = response.data.data;
      setWalletTransactions(res);
      // const successMessage = response.data.message || "Transactions fetched";
      // ShowToast({ type: "success", text: successMessage });
      setIsLoading(false);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "Error fetching transactions";
      setIsLoading(false);
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  useEffect(() => {
    if (walletTransactions.length > 0) {
      getWalletTransactions();
    }
  }, [walletTransactions.length]);

  return (
    <div className="postedJobs-container">
      <div
        className="dashboard-applications-header-container"
        style={{ margin: "20px 0" }}
      >
        <h5>Recent Transactions</h5>
        <Link to="#">See All</Link>
      </div>
      {walletTransactions.length && !isLoading ? (
        <TableContainer width="100%" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((th, i) => (
                  <TableCell key={i}>
                    <div>
                      <p>{th.head}</p>
                      {th.filter && (
                        <img src={th.filter} alt="filter" className="pointer" />
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {walletTransactions.map((tr, i) => (
                <TableRow key={i}>
                  <TableCell
                    className="postedJob-role"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "13.22px",
                    }}
                  >
                    <img src={tr.icon} alt="" />
                    {tr.name}
                  </TableCell>
                  <TableCell>{tr.amount}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "inline-block",
                        padding: "4.41px 13.22px",
                        borderRadius: "13.22px",
                        fontWeight: 600,
                        color:
                          tr.type.toLowerCase() === "payment"
                            ? "#501E61"
                            : "#1671D9",
                        backgroundColor:
                          tr.type.toLowerCase() === "payment"
                            ? "#fbefff"
                            : "#e2effc",
                      }}
                    >
                      <p>{tr.type}</p>
                    </div>
                  </TableCell>
                  <TableCell>{tr.date}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "inline-block",
                        padding: "4.41px 13.22px",
                        borderRadius: "13.22px",
                        fontWeight: 600,
                        color:
                          tr.status.toLowerCase() === "pending"
                            ? "#F3A218"
                            : "#0F973D",
                        backgroundColor:
                          tr.status.toLowerCase() === "pending"
                            ? "#FEF6E7"
                            : "#E7F6EC",
                      }}
                    >
                      <p>{tr.status}</p>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !walletTransactions.length && !isLoading ? (
        <EmptyState
          title={"No Recent  transaction (yet)"}
          subtitle={"All recent transactions will appear here"}
          img={emptyState}
        />
      ) : (
        <div className="postJob-preview-loader">
          <BounceLoader size="100px" color="#134271" />
        </div>
      )}
    </div>
  );
};

export default WalletTable;
