import { useDispatch } from "react-redux";
import axiosInstance from "../services/AxiosConfig";
import { setActiveUser } from "../features/activeUserSlice";

export default function useGetSelf() {
  const dispatch = useDispatch();

  async function handleGetSelf() {
    try {
      const {data} = await axiosInstance.get("/profile");
      const userData = data?.data;
      dispatch(setActiveUser(userData));
    } catch (error) {
      // console.log(error);
    }
  }

  return handleGetSelf;
}
