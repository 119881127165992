import { useState } from "react";
import fingerTipImage from "../../assets/website/images/fingertip img.svg";
import FAQforClient from "./FAQforClient";

function AnyQuestionsWeCanHelpWith({ title, SpanTitle, secondTitle }) {
  const [view, setView] = useState({
    forTalent: false,
    forClient: true,
  });

  return (
    <div className="ask-questions-container">
      <div className="ask-questions">
        <h4>Any Question? We can help you.</h4>
      </div>

      <div className="find-talent-buttons">
        <button
          onClick={() => setView({ forTalent: false, forClient: true })}
          className={`find-talent-button-one cursor ${
            view.forClient ? "active" : ""
          }`}
        >
          For Client
        </button>
        <button
          onClick={() => setView({ forTalent: true, forClient: false })}
          className={`find-talent-button-two cursor ${
            view.forTalent ? "active" : ""
          }`}
        >
          For Talent
        </button>
      </div>
      {view.forTalent ? (
        ""
      ) : view.forClient ? (
        <FAQforClient
          buttonTitle="Find Client"
          imageSrc={fingerTipImage}
          headTitle="Talent At Your Fingertip"
          body="An easy way to find freelancers with extraordinary talents and skils
            to help you get your job done"
        />
      ) : null}
    </div>
  );
}
export default AnyQuestionsWeCanHelpWith;
