import React, { useEffect, useState } from "react";
import "../../../styles/postJob/postJob.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyState from "../saved/EmptyState";
import { BounceLoader, ScaleLoader } from "react-spinners";
// import axiosInstance from "../../../services/AxiosConfig";
// import ShowToast from "../../../common/website/Toast";
import emptyState from "../../../assets/dashboard/icons/emptyPostedJobs.svg";
import filter from "../../../assets/dashboard/icons/Filter.svg";
import chat from "../../../assets/dashboard/icons/chat.svg";
import clock from "../../../assets/dashboard/icons/clock.svg";
import Filter from "./Filter";
import { selectActiveUser } from "../../../features/activeUserSlice";
import { useSelector } from "react-redux";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { capitalizeFirstLetter } from "../../../utils/CapitalizeFirstName";
import { formatDate } from "../../../utils/FormatDate";
import PayTalentModal from "./PayTalentModal";

const ProjectsTable = () => {
  const activeUser = useSelector(selectActiveUser);
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("on-going");
  // const [filterQuery, setFilterQuery] = useState("");
  const [completed, setCompleted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [completedProjectList, setCompletedProjectList] = useState([]);
  const [projectTalentList, setProjectTalentList] = useState([]);
  const [completedProjectTalentList, setCompletedProjectTalentList] = useState(
    []
  );

  // fetch client ongoing
  const fetchClientOnGoingList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/project/client?status=On-going`
      );
      const res = response.data.data;
      setProjectList(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error?.response?.data?.error || "Error fetching projects";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // fetch client completed
  const fetchClientCompletedList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/project/client?status=Completed`
      );
      const res = response.data.data;
      setCompletedProjectList(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error?.response?.data?.error || "Error fetching projects";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // fetch talent ongoing
  const fetchTalentOnGoingList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/project/talent?status=On-going`
      );
      const res = response.data.data;
      setProjectTalentList(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error?.response?.data?.error || "Error fetching projects";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // fetch talent completed
  const fetchTalentCompletedList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/project/talent?status=Completed`
      );
      const res = response.data.data;
      setCompletedProjectTalentList(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error?.response?.data?.error || "Error fetching projects";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // Get client projects
  // const getClientProjects = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await axiosInstance.get(
  //       `/project/client?status=${filterQuery}`
  //     );
  //     const res = response.data.data;
  //     setProjectList(res);
  //     setCompletedProjectList(res);
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     const errorMessage =
  //       error?.response?.data?.error || "Error fetching projects";
  //     ShowToast({ type: "error", text: errorMessage });
  //   }
  // };

  // Get talent projects
  // const getTalentProjects = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await axiosInstance.get(
  //       `/project/talent?status=${filterQuery}`
  //     );
  //     const res = response.data.data;
  //     setProjectTalentList(res);
  //     setCompletedProjectTalentList(res);
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     const errorMessage =
  //       error?.response?.data?.error || "Error fetching projects";
  //     ShowToast({ type: "error", text: errorMessage });
  //   }
  // };

  useEffect(() => {
    if (activeUser && activeUser.type) {
      if (activeUser?.type === "Client") {
        fetchClientOnGoingList();
        fetchClientCompletedList();
      } else {
        fetchTalentOnGoingList();
        fetchTalentCompletedList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser]);

  // Pay a talent
  const handleMarkAsompleted = async (projectIdTalent) => {
    try {
      setBtnLoader(true);
      const response = await axiosInstance.put(
        `/project/complete/${projectIdTalent}`
      );
      const res = response.data.data;
      console.log(res);
      setBtnLoader(false);
    } catch (error) {
      setBtnLoader(false);
      const errorMessage =
        error?.response?.data?.error || "Error fetching projects";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  const tableHead = [
    { head: "Role", filterBy: filter },
    {
      head: `${activeUser?.type === "Talent" ? "Client" : "Talent"}`,
      filter: filter,
    },
    { head: "Start  Date", filter: filter },
    { head: "Status", filter: filter },
    { head: "Payment", filter: filter },
    { head: "Action", filter: filter },
  ];

  const completedTableHead = [
    { head: "Role", filter: filter },
    {
      head: `${activeUser?.type === "Talent" ? "Client" : "Talent"}`,
      filter: filter,
    },
    { head: "Start  Date", filter: filter },
    { head: "End  Date", filter: filter },
    { head: "Status", filter: filter },
    { head: "Payment", filter: filter },
  ];

  return (
    <>
      {/* {showModal && (
        <PayTalentModal
          onClose={() => setShowModal(false)}
          handleClick={() => setShowModal(false)}
          projectId={projectId}
        />
      )} */}
      <div className="postedJobs-container">
        <div className="filter-container">
          <Filter
            icon={chat}
            filterName="Ongoing Projects"
            filterCount={
              !completed && activeUser?.type === "Client"
                ? projectList?.length
                : projectTalentList?.length
            }
            onClick={() => {
              setFilterBy("on-going");
              // setFilterQuery("On-going");
              setCompleted(false);
            }}
            color={filterBy === "on-going" ? "#101928" : "#344054"}
            bgColor={filterBy === "on-going" ? "#CBD6E2" : "#F0F2F5"}
            border={
              filterBy === "on-going"
                ? "1px solid #134271"
                : "1px solid #D0D5DD"
            }
            countColor={filterBy === "on-going" ? "#ffffff" : "#344054"}
            countBg={filterBy === "on-going" ? "#134271" : "#E4E7EC"}
          />
          <Filter
            icon={clock}
            filterName="Closed Projects"
            filterCount={
              completed && activeUser?.type === "Client"
                ? completedProjectList?.length
                : completedProjectTalentList?.length
            }
            onClick={() => {
              setFilterBy("closed");
              setCompleted(true);
              // setFilterQuery("Completed");
            }}
            color={filterBy === "closed" ? "#101928" : "#344054"}
            bgColor={filterBy === "closed" ? "#CBD6E2" : "#F0F2F5"}
            border={
              filterBy === "closed" ? "1px solid #134271" : "1px solid #D0D5DD"
            }
            countColor={filterBy === "closed" ? "#ffffff" : "#344054"}
            countBg={filterBy === "closed" ? "#134271" : "#E4E7EC"}
          />
        </div>

        {/* Client */}
        {activeUser?.type === "Client" &&
          (projectList?.length > 0 && !isLoading ? (
            !completed ? (
              <TableContainer width="100%" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((th, i) => (
                        <TableCell key={i}>
                          <div>
                            <p>{th.head}</p>
                            {th.filter && (
                              <img
                                src={th.filter}
                                alt="filter"
                                className="pointer"
                              />
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {!isLoading ? (
                        projectList?.map((project, i) => (
                          <TableRow key={i}>
                            <TableCell className="postedJob-role">
                              {project?._job?.role}
                            </TableCell>
                            <TableCell>
                              {capitalizeFirstLetter(project?._user?.firstname)}{" "}
                              {capitalizeFirstLetter(project?._user?.lastname)}
                            </TableCell>
                            <TableCell>{formatDate(project?.start)}</TableCell>
                            <TableCell>
                              <div
                                style={{
                                  width: "112.39px",
                                  display: "inline-block",
                                  padding: "4.41px 13.22px",
                                  borderRadius: "13.22px",
                                  fontWeight: 600,
                                  color:
                                    project?.status.toLowerCase() === "on-going"
                                      ? "#F47C20"
                                      : "#1671D9",
                                  backgroundColor:
                                    project?.status.toLowerCase() === "on-going"
                                      ? "#FCEEE3"
                                      : "#e2effc",
                                }}
                              >
                                <p style={{ textAlign: "center" }}>
                                  {project?.status}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  width: "112.39px",
                                  display: "inline-block",
                                  padding: "4.41px 13.22px",
                                  borderRadius: "13.22px",
                                  fontWeight: 600,
                                  color: !project?.paid ? "#A7744D" : "#1671D9",
                                  backgroundColor: !project?.paid
                                    ? "#F1E2D7"
                                    : "#e2effc",
                                }}
                              >
                                <p style={{ textAlign: "center" }}>
                                  {!project?.paid && "Pending"}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  width: "112.39px",
                                  display: "inline-block",
                                  padding: "4.41px 13.22px",
                                  borderRadius: "8px",
                                  fontWeight: 600,
                                  color: !project?.paid
                                    ? "#FFFFFF"
                                    : "rgba(77, 156, 167, 1)",
                                  backgroundColor: !project?.paid
                                    ? "#134271"
                                    : "rgba(214, 227, 229, 1)",
                                  cursor: "pointer",
                                  transition: "background-color 0.3s", // Add transition for smooth effect
                                }}
                                onClick={() => setShowModal(true)}
                              >
                                <p style={{ textAlign: "center" }}>
                                  {btnLoader ? (
                                    <ScaleLoader height={15} color="#ffffff" />
                                  ) : !project.paid ? (
                                    "Pay"
                                  ) : (
                                    "Paid"
                                  )}
                                </p>
                                {showModal && (
                                  <PayTalentModal
                                    onClose={() => setShowModal(false)}
                                    handleClick={() => setShowModal(false)}
                                    projectId={project._id}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <div className="postJob-preview-loader">
                          <BounceLoader size="100px" color="#134271" />
                        </div>
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer width="100%" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {completedTableHead.map((th, i) => (
                        <TableCell key={i}>
                          <div>
                            <p>{th.head}</p>
                            {th.filter && (
                              <img
                                src={th.filter}
                                alt="filter"
                                className="pointer"
                              />
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completedProjectList.map((project, i) => (
                      <TableRow key={i}>
                        <TableCell className="postedJob-role">
                          {project?._job?.role}
                        </TableCell>
                        <TableCell>
                          {capitalizeFirstLetter(project?._user?.firstname)}{" "}
                          {capitalizeFirstLetter(project?._user?.lastname)}
                        </TableCell>
                        <TableCell>{formatDate(project?.start)}</TableCell>
                        <TableCell>{formatDate(project?.end) || ""}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "112.39px",
                              display: "inline-block",
                              padding: "4.41px 13.22px",
                              borderRadius: "13.22px",
                              fontWeight: 600,
                              color: "#229337",
                              backgroundColor: "#DAEADD",
                            }}
                          >
                            <p style={{ textAlign: "center" }}>
                              {project?.status}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "112.39px",
                              display: "inline-block",
                              padding: "4.41px 13.22px",
                              borderRadius: "8px",
                              fontWeight: 600,
                              color: !project?.paid
                                ? "#FFFFFF"
                                : "rgba(77, 156, 167, 1)",
                              backgroundColor: !project?.paid
                                ? "#134271"
                                : "rgba(214, 227, 229, 1)",
                              cursor: "pointer",
                            }}
                            onClick={() => setShowModal(true)}
                          >
                            <p style={{ textAlign: "center" }}>
                              {btnLoader ? (
                                <ScaleLoader height={15} color="#ffffff" />
                              ) : !project.paid ? (
                                "Pay"
                              ) : (
                                "Paid"
                              )}
                            </p>
                            {showModal && (
                              <PayTalentModal
                                onClose={() => setShowModal(false)}
                                handleClick={() => setShowModal(false)}
                                projectId={project._id}
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : !projectList.length && !isLoading ? (
            <EmptyState
              title={"No Projects  (yet)"}
              subtitle={"All projects will appear here"}
              img={emptyState}
            />
          ) : (
            <div className="postJob-preview-loader">
              <BounceLoader size="100px" color="#134271" />
            </div>
          ))}

        {/* Talent */}
        {activeUser?.type === "Talent" &&
          (projectTalentList?.length > 0 && !isLoading ? (
            !completed ? (
              <TableContainer width="100%" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((th, i) => (
                        <TableCell key={i}>
                          <div>
                            <p>{th.head}</p>
                            {th.filter && (
                              <img
                                src={th.filter}
                                alt="filter"
                                className="pointer"
                              />
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectTalentList?.map((project, i) => (
                      <TableRow key={i}>
                        <TableCell className="postedJob-role">
                          {project?._job?.role}
                        </TableCell>
                        <TableCell>
                          {capitalizeFirstLetter(project?._company?.name)}
                        </TableCell>
                        <TableCell>{formatDate(project?.start)}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "112.39px",
                              display: "inline-block",
                              padding: "4.41px 13.22px",
                              borderRadius: "13.22px",
                              fontWeight: 600,
                              color:
                                project?.status.toLowerCase() === "on-going"
                                  ? "#F47C20"
                                  : "#1671D9",
                              backgroundColor:
                                project?.status.toLowerCase() === "on-going"
                                  ? "#FCEEE3"
                                  : "#e2effc",
                            }}
                          >
                            <p style={{ textAlign: "center" }}>
                              {project?.status}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "112.39px",
                              display: "inline-block",
                              padding: "4.41px 13.22px",
                              borderRadius: "13.22px",
                              fontWeight: 600,
                              color: !project?.paid ? "#A7744D" : "#1671D9",
                              backgroundColor: !project?.paid
                                ? "#F1E2D7"
                                : "#e2effc",
                            }}
                          >
                            <p style={{ textAlign: "center" }}>
                              {!project?.paid && "Pending"}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "144px",
                              display: "inline-block",
                              padding: "4.41px 13.22px",
                              borderRadius: "8px",
                              fontWeight: 600,
                              fontSize: "12px",
                              color: "white",
                              backgroundColor: "#134271",
                              cursor: "pointer",
                            }}
                            onClick={() => handleMarkAsompleted(project?._id)}
                          >
                            <p style={{ textAlign: "center" }}>
                              {btnLoader ? (
                                <ScaleLoader height={15} color="#ffffff" />
                              ) : (
                                !project?.paid && "Mark completed"
                              )}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer width="100%" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {completedTableHead.map((th, i) => (
                        <TableCell key={i}>
                          <div>
                            <p>{th.head}</p>
                            {th.filter && (
                              <img
                                src={th.filter}
                                alt="filter"
                                className="pointer"
                              />
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completedProjectTalentList?.map((project, i) => (
                      <TableRow key={i}>
                        <TableCell className="postedJob-role">
                          {project?._job?.role}
                        </TableCell>
                        <TableCell>
                          {capitalizeFirstLetter(project?._company?.name)}
                        </TableCell>
                        <TableCell>{formatDate(project?.start)}</TableCell>
                        <TableCell>{formatDate(project?.end) || ""}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "112.39px",
                              display: "inline-block",
                              padding: "4.41px 13.22px",
                              borderRadius: "13.22px",
                              fontWeight: 600,
                              color: "#229337",
                              backgroundColor: "#DAEADD",
                            }}
                          >
                            <p style={{ textAlign: "center" }}>
                              {project?.status}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "112.39px",
                              display: "inline-block",
                              padding: "4.41px 13.22px",
                              borderRadius: "8px",
                              fontWeight: 600,
                              color: "#4D9CA7",
                              backgroundColor: "#D6E3E5",
                            }}
                          >
                            <p style={{ textAlign: "center" }}>
                              {!project?.paid ? "Pending" : "paid"}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : !projectTalentList.length && !isLoading ? (
            <EmptyState
              title={"No Projects (yet)"}
              subtitle={"All projects will appear here"}
              img={emptyState}
            />
          ) : (
            <div className="postJob-preview-loader">
              <BounceLoader size="100px" color="#134271" />
            </div>
          ))}
      </div>
    </>
  );
};

export default ProjectsTable;
