import Navigation from "../../common/website/Navbar";
import SearchTalentCard from "../../components/website/SearchTalentCard";
import SearchTalentHero from "../../components/website/SearchTalentHero";

export default function SearchTalent(){
    return(
        <div>
            <Navigation/>
            <SearchTalentHero/>
            <SearchTalentCard/>
        </div>
    )
}