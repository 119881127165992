import MainLayout from "../../../layouts/mainLayout";
import PostedJobs from "./PostedJobs";

export default function PostedJobsPage() {
  return (
    <MainLayout navTitle={"Posted Jobs"}>
      <PostedJobs />
    </MainLayout>
  );
}
