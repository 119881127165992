import React, { useState } from "react";
import ChangePassWordModal from "./ChangePassWordModal";
import CloseAccount from "./CloseAccountModal";

const Security = () => {
  const [isPasswordModalVisible, setPasswordModalVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const closeModal = () => {
    setPasswordModalVisible(false);
    setModalVisible(false);
  };

  return (
    <div className="security-container">
      {isPasswordModalVisible && (
        <ChangePassWordModal handleClick={closeModal} />
      )}
      {isModalVisible && <CloseAccount handleClick={closeModal} />}
      <div>
        <div>
          <p>Update Password</p>
          <p>Change your old password to a new one</p>
        </div>
        <span onClick={() => setPasswordModalVisible(true)}>
          Change password
        </span>
      </div>

      <div>
        <p>Termination</p>
        <p onClick={() => setModalVisible(true)}>Close my account</p>
      </div>
    </div>
  );
};

export default Security;
