import { useEffect, useState } from "react";
import Location from "../../../assets/dashboard/icons/location.svg";
import { ProfileEditableHeading } from "./ProfileEditableHeading";
import { useSelector } from "react-redux";
import ProfilePictureBorder from "../../../assets/dashboard/images/Ellipse.png";
import { selectActiveUser } from "../../../features/activeUserSlice";
import { nameCase } from "../../../utils/FormatName";
import axiosInstance from "../../../services/AxiosConfig";
import useGetSelf from "../../../hooks/GetSelf";
import EditProfileModal from "./EditProfileModal";

export default function ProfileDetails() {
  const [editProfileModal, setEditProfileModal] = useState(false);
  const activeUser = useSelector(selectActiveUser);
  const [availability, setAvailability] = useState(activeUser?.available);
  const handleGetSelf = useGetSelf();

  useEffect(() => {
    setAvailability(activeUser?.avaliable);
  }, [activeUser?.avaliable]);

  const getProfileData = async () => {
    try {
      const response = await axiosInstance.get("/profile");
      const token = localStorage.getItem("pspUserToken");
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      console.log("profile data", response.data);
    } catch (error) {
      console.log("axios", error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  async function toggleAvailability() {
    setAvailability(!availability);
    try {
      const resp = await axiosInstance.put("/profile/state");
      handleGetSelf();
      console.log(resp);
    } catch (error) {
      setAvailability(!availability);
    }
  }

  return (
    <>
      {editProfileModal && (
        <EditProfileModal onClose={() => setEditProfileModal(false)} />
      )}
      <div className="dashboard-profile-details-container">
        <div className="dashboard-profile-detail">
          <div className="dashboard-profile-user-avatar">
            <div>
              <img src={ProfilePictureBorder} alt="border" />
            </div>
            <img
              src={activeUser?.image}
              alt="avatar"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="dashboard-profile-user-detail">
            {activeUser?.avaliable ? (
              <div className="dashboard-profile-user-availability">
                <p>AVAILABLE</p>
              </div>
            ) : (
              <div className="dashboard-profile-user-availability-off">
                <p>INVISIBLE</p>
              </div>
            )}
            <p className="dashboard-profile-user-name">
              {nameCase(activeUser?.firstname)} {nameCase(activeUser?.lastname)}
            </p>
            {/*<p className="dashboard-profile-user-designation">Associate</p>*/}  {/** check and fix this */}

            {/* <p className="dashboard-profile-user-displayname">
              Display name: {nameCase(activeUser?.display)}
            </p> */}

            <div className="dashboard-profile-user-availability-switch">
              <div
                className="dashboard-profile-user-availability-switch-available"
                style={{
                  backgroundColor: availability ? "#134271" : "transparent",
                  color: availability ? "#ffffff" : "#7F879E",
                }}
                onClick={() => {
                  toggleAvailability();
                  // setAvailability(true);
                }}
              >
                <p>Available</p>
              </div>
              <div
                className="dashboard-profile-user-availability-switch-invisible"
                style={{
                  backgroundColor: !availability ? "#134271" : "transparent",
                  color: !availability ? "#ffffff" : "#7F879E",
                }}
                onClick={() => {
                  toggleAvailability();
                  // setAvailability(false);
                }}
              >
                <p>Invisible</p>
              </div>
            </div>
          </div>
        </div>

        <hr className="dashboard-profile-details-divider" />

        {/* Skill */}
        <div className="dashboard-profile-user-skill">
          <ProfileEditableHeading
            onClick={() => setEditProfileModal(true)}
            title="Skills"
          />
          {activeUser?.skills &&
            activeUser?.skills.map((skill, i) => (
              <div className="dashboard-profile-user-skill-main" key={i}>
                <p>{skill}</p>
              </div>
            ))}
        </div>

        {/* Bio */}
        <div className="dashboard-profile-user-Bio">
          <ProfileEditableHeading
            onClick={() => setEditProfileModal(true)}
            title="Bio"
          />
          <p>{activeUser?.bio}</p>
        </div>

        {/* Location */}
        <div className="dashboard-profile-user-location">
          <ProfileEditableHeading
            onClick={() => setEditProfileModal(true)}
            title="Location"
          />
          <div className="dashboard-profile-user-location-main">
            <img src={Location} alt="location" />
            <p>{activeUser?.location}</p>
          </div>
        </div>

        {/* Hourly Rate */}
        <div className="dashboard-profile-user-rate">
          <ProfileEditableHeading
            onClick={() => setEditProfileModal(true)}
            title="Hourly Rate"
          />
          <div className="dashboard-profile-user-rate-main">
            <p>
              {activeUser?.rates.currency}
              {activeUser?.rates.min}-{activeUser?.rates.currency}
              {activeUser?.rates.max}/hr
            </p>
          </div>
        </div>

        {/* Links */}
        <div className="dashboard-profile-user-link">
          <ProfileEditableHeading
            onClick={() => setEditProfileModal(true)}
            title="Links"
          />
          <div className="dashboard-profile-user-link-main">
            <p>{activeUser?.link}</p>
          </div>
        </div>
      </div>
    </>
  );
}
