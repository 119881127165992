import nike from "../../assets/website/icons/nike.svg"
import dropbox from "../../assets/website/icons/dropbox.svg"
import shopify from "../../assets/website/icons/shopify.svg"
import forever from "../../assets/website/icons/forever.svg"
import reebok from "../../assets/website/icons/reebok.svg"
import intercom from "../../assets/website/icons/intercom.svg"
import notion from "../../assets/website/icons/notion.svg"
import revolut from "../../assets/website/icons/revolut.svg"
import subway from "../../assets/website/icons/subway.svg"


function Trustedby(){
    return(
        <div className="trusted-by">
            <div className="trusted-brands">
            <p>Trusted By</p>
            </div>

            <div className="brand-container">
                <figure className="brand"> <img src={nike} alt="nike" /></figure>
                <figure className="brand"> <img src={dropbox} alt="dropbox " /></figure>
                <figure className="brand"> <img src={shopify} alt=" shopify " /></figure>
                <figure className="brand"> <img src={forever} alt="forever " /></figure>
                <figure className="brand"> <img src={reebok} alt="reebok  " /></figure>
                <figure className="brand"> <img src={intercom} alt="intercom " /></figure>
                <figure className="brand"> <img src={notion} alt="notion  " /></figure>
                <figure className="brand"> <img src={revolut} alt=" revolut " /></figure>
                <figure className="brand"> <img src={subway} alt="subway " /></figure>

            </div>
        </div>
    )
}
export default Trustedby;
