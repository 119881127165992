import HeroImage from "../../assets/website/images/post a job image.svg";

export default function Hero2() {
  return (
    <div className="hire-vetted-talents-container-two">
      <div className="content">
        <div className="talent-at-your-finngertip-left">
          <div className="hire-vetted-talents">
            <p>News! </p>
            <p>Hire vetted talents </p>
          </div>
          <div className="talent-at-your-finngertip-details">
            <h3 className="talent-at-fingertip-header">
              Post a job and start, your <span> hiring journey </span>
            </h3>

            <p className="talent-at-fingertip-ptags">
              {" "}
              {/* Connect with talent that is in line you, and hire them to take
              your business to the next level */}
              Engage with compatible talent and elevate your business to new
              heights through strategic hires.
            </p>
          </div>
          <div className="get-started-btn">
            <button className="get-started cursor">Get Started </button>
          </div>
        </div>

        <figure className="talent-at-your-finngertip-right">
          <img src={HeroImage} alt="fingerTip" />
        </figure>
      </div>
    </div>
  );
}
