import React, { useEffect, useState } from "react";
import SingleCard from "./SingleCard";
import LogoIcon from "../../../assets/dashboard/icons/logoIcon.svg";
import DarkLogoIcon from "../../../assets/dashboard/icons/darkLogoIcon.svg";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import FundWalletModal from "./FundWalletModal";
import { useSelector } from "react-redux";
import { selectActiveUser } from "../../../features/activeUserSlice";

const CardSection = () => {
  const [wallet, setWallet] = useState(false);
  const [fundWalletModal, setFundWalletModal] = useState(false);
  const [walletCreationLoading, setWalletCreationLoading] = useState(false);
  const activeUser = useSelector(selectActiveUser);

  const closeModal = () => {
    setFundWalletModal(false);
  };

  // Get wallet
  const handleGetWallet = async () => {
    try {
      const getWallet = await axiosInstance.get("/wallet");
      const walletAddress = getWallet.data.data;
      setWallet(walletAddress);
      // const successMessage = getWallet.data.message || "Wallet fetched";
      // ShowToast({ type: "success", text: successMessage });
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ||
        "An error occurred while fetching wallet";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  // Create wallet
  const handleWalletCreation = async () => {
    setWalletCreationLoading(true);
    try {
      const createWallet = await axiosInstance.post("/wallet");
      setWalletCreationLoading(false);
      const successMessage = createWallet.data.message || "Wallet created";
      ShowToast({ type: "success", text: successMessage });
    } catch (error) {
      setWalletCreationLoading(false);
      const errorMessage =
        error?.response?.data?.error ||
        "An error occurred during wallet creation";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  useEffect(() => {
    if (activeUser?._wallet) {
      handleGetWallet();
    }
  }, [activeUser?._wallet]);

  return (
    <>
      {fundWalletModal && (
        <FundWalletModal
          onClose={() => setFundWalletModal(false)}
          handleClick={closeModal}
        />
      )}
      <div className="wallet-card-container">
        <SingleCard
          title="Total balance"
          bgColor="#134271"
          titleColor="#FFFFFF"
          amount={`${wallet?.currency} ${parseFloat(
            wallet?.amount?.$numberDecimal
          ).toLocaleString()}`}
          icon={LogoIcon}
          cardDesign
          walletBtn
          showBal
          isLoading={walletCreationLoading}
          fundWallet={() => setFundWalletModal(true)}
          createWallet={() => handleWalletCreation()}
        />
        <SingleCard
          title="Total  Payout"
          titleColor="#1C2634"
          amount={`${wallet?.currency} ${parseFloat(
            wallet?.payout?.$numberDecimal
          ).toLocaleString()}`}
          border="1px solid #EFF3FA"
          icon={DarkLogoIcon}
        />
      </div>
    </>
  );
};

export default CardSection;
