import React from "react";
import "./styles.scss";

export default function Input({ type, placeholder, value, onChange, style }) {
  return (
    <div className="input" style={style}>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        
      />
    </div>
  );
}
