import React, { useState } from "react";
import "./styles.scss";
import { EYE_ICON, EYE_SLASH_ICON } from "../../../assets/website/constants";

export default function PasswordInput({ type, placeholder, value, onChange }) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="input">
      <input
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div>
        {showPassword && (
          <img src={EYE_ICON} alt="" onClick={() => setShowPassword(false)} />
        )}
        {!showPassword && (
          <img
            src={EYE_SLASH_ICON}
            alt=""
            onClick={() => setShowPassword(true)}
          />
        )}
      </div>
    </div>
  );
}
