import React from "react";
import Logo from "../../assets/website/icons/logo.svg";

const PostJobHeader = () => {
  return (
    <div className="postJob-nav-container">
      <img src={Logo} alt="logo" />
    </div>
  );
};

export default PostJobHeader;
