import EmptyState from "./EmptyState";
import emptyState from "../../../assets/dashboard/icons/noSavedJobs.svg";
import { useNavigate } from "react-router-dom";
import JobCard from "../find_job/card";
import { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { BounceLoader } from "react-spinners";

export default function SavedJobs() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [savedJobs, setSavedJobs] = useState([]);

  useEffect(() => {
    const getSavedJobs = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get("/job/saved");
        const res = response.data.data;
        setSavedJobs(res);
        console.log("job", res);
        setIsLoading(false);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || "Error Loading Saved Jobs";
        setIsLoading(false);
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    getSavedJobs();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="postJob-preview-loader">
          <BounceLoader size="100px" color="#134271" />
        </div>
      ) : (
        <div className="saved-jobs-container">
          {savedJobs?.length ? (
            savedJobs?.map((job, i) => {
              return (
                <div className="job-card" key={i}>
                  <JobCard job={job} fn={() => navigate(`${job._id}`)} />
                </div>
              );
            })
          ) : (
            <EmptyState
              title={"No job Saved (yet)"}
              subtitle={"All conversations will appear here"}
              img={emptyState}
              btnText={"Find Job"}
              fn={() => {
                navigate("/dashboard/jobs/find");
              }}
            />
          )}
        </div>
      )}
    </>
  );
}
