import { useState } from "react";
import ForClient from "./ForClient";
import ForTalent from "./ForTalent";
import fingerTipImage from "../../assets/website/images/fingertip img.svg";

function FindTalent({ title, SpanTitle, secondTitle }) {
  const [view, setView] = useState({
    forTalent: false,
    forClient: true,
  });

  return (
    <div className="trusted-by">
      <div className="find-talents">
        <header>
          <h1>
            {title} {view.forTalent ? "Talent" : "Client"}
            <span className="around-the-world"> {SpanTitle} </span>{" "}
            {secondTitle}
          </h1>
        </header>
      </div>

      <div className="find-talent-buttons">
        <button
          onClick={() => setView({ forTalent: false, forClient: true })}
          className={`find-talent-button-one cursor ${
            view.forClient ? "active" : ""
          }`}
        >
          For Client
        </button>
        <button
          onClick={() => setView({ forTalent: true, forClient: false })}
          className={`find-talent-button-two cursor ${
            view.forTalent ? "active" : ""
          }`}
        >
          For Talent
        </button>
      </div>
      {view.forTalent ? (
        <ForTalent />
      ) : view.forClient ? (
        <ForClient
          buttonTitle="Find Client"
          imageSrc={fingerTipImage}
          headTitle="Client At Your Fingertip"
          body="An easy way to find freelancers with extraordinary talents and skils
            to helo get your job done"
        />
      ) : null}
    </div>
  );
}
export default FindTalent;
