import React, { useEffect, useState } from "react";
import TotalAppliedIcon from "../../../assets/dashboard/icons/Icon.svg";
import TotalSavedIcon from "../../../assets/dashboard/icons/Icon-2.svg";
import TotalProjectsIcon from "../../../assets/dashboard/icons/Icon-3.svg";
import AcceptedJobsIcon from "../../../assets/dashboard/icons/Icon-2.svg";
import ViewMore from "../../../assets/dashboard/icons/Icon-1.svg";
import { useSelector } from "react-redux";
import axiosInstance from "../../../services/AxiosConfig";
import { selectActiveUser } from "../../../features/activeUserSlice";

const DashboardSummaryItem = ({ icon, number, title, viewMoreIcon }) => {
  return (
    <div className="dashboard-summary-item-container">
      <div className="dashboard-summary-item-detail">
        <div className="dashboard-summary-item-number-container">
          <img src={icon} alt={title} />
          <p>{number}</p>
        </div>
        <div className="dashboard-summary-item-view-more-container">
          <img src={viewMoreIcon} alt="View more" />
        </div>
      </div>
      <p>{title}</p>
    </div>
  );
};

export const DashboardSummary = () => {
  const activeUser = useSelector(selectActiveUser);
  const [overview, setOverview] = useState({
    totalJob: 0,
    totalApplicant: 0,
    totalApplied: 0,
    totalSaved: 0,
    totalProject: 0,
    Accepted_Job: 0,
  });

  useEffect(() => {
    const handleGetOverview = async () => {
      try {
        const response = await axiosInstance.get(`/profile/overview`);
        const res = response.data.data;
        setOverview(res);
        console.log("res", res);
      } catch (error) {
        console.log(error);
      }
    };

    handleGetOverview();
  }, []);

  return (
    <>
      {activeUser?.type === "Client" ? (
        <div className="dashboard-summary-container">
          <DashboardSummaryItem
            icon={TotalAppliedIcon}
            number={overview.totalJob}
            title={"Total posted jobs"}
            viewMoreIcon={ViewMore}
          />
          <DashboardSummaryItem
            icon={TotalProjectsIcon}
            number={overview.totalProject}
            title="Total Projects"
            viewMoreIcon={ViewMore}
          />
          <DashboardSummaryItem
            icon={AcceptedJobsIcon}
            number={0}
            title="Total completed Jobs"
            viewMoreIcon={ViewMore}
          />
        </div>
      ) : (
        <div className="dashboard-summary-container">
          <DashboardSummaryItem
            icon={TotalAppliedIcon}
            number={overview.totalApplied}
            title="Total Applied"
            viewMoreIcon={ViewMore}
          />
          <DashboardSummaryItem
            icon={TotalSavedIcon}
            number={overview.totalSaved}
            title="Total Saved"
            viewMoreIcon={ViewMore}
          />
          <DashboardSummaryItem
            icon={TotalProjectsIcon}
            number={overview.totalProject}
            title="Total Projects"
            viewMoreIcon={ViewMore}
          />
          <DashboardSummaryItem
            icon={AcceptedJobsIcon}
            number={overview.Accepted_Job}
            title="Accepted Jobs"
            viewMoreIcon={ViewMore}
          />
        </div>
      )}
    </>
  );
};
