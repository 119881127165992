import handHoldingPhone from "../../assets/website/icons/bonbon-hand-holding-camera-phone.svg";
import talentImage from "../../assets/website/images/talent-comes-to-you-image.svg";
import secureImage from "../../assets/website/images/secure-payment.svg";

export default function ThirdCard() {
  return (
    <div className="benefit-of-using-bsp-card">
      <div className="bsp-card">
        <figure className="bsp-card-top">
          <img src={handHoldingPhone} alt="" />
        </figure>
        <div className="bsp-card-buttom">
          <h4>Post A Job (It is Free)</h4>

          <p>
            Tell us what you need. Provide as many details as possible, but dont
            worry about getting it perfect
          </p>
        </div>
      </div>

      <div className="bsp-card">
        <figure className="bsp-card-top">
          <img src={talentImage} alt="" />
        </figure>
        <div className="bsp-card-buttom">
          <h4>Talent Comes To You</h4>

          <p>
            Get qualified proposal within 24 hours and meet the candidates
            you're excited about. Hire as soon as you're ready.
          </p>
        </div>
      </div>

      <div className="bsp-card">
        <figure className="bsp-card-top">
          <img src={secureImage} alt="" />
        </figure>
        <div className="bsp-card-buttom">
          <h4>Secure Payment </h4>

          <p>
            Recieve invoice and make payment through PSP. Only pay for work you
            authorize
          </p>
        </div>
      </div>
    </div>
  );
}
