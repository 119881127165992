import React from "react";
import "./styles.scss";
import { ARROW_LEFT } from "../../../assets/website/constants";

export default function BackButton({ text, fn }) {
  return (
    <div className="button-container">
      <button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          backgroundColor: "transparent",
          color: "black",
          border: "1px solid #DFE8F6",
          padding: "10px 13px",
        }}
        onClick={() => fn()}
        type="button"
        className="button"
      >
        <img src={ARROW_LEFT} alt="" />
        {text}
      </button>
    </div>
  );
}
