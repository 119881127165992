import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    countryCode: "+234",
    type: "",
    password: "",
    link: "",
    displayName: "",
    location: "",
    profilePic: "",
    goal: "",
    category: "",
    bio: "",
    skill: [],
    saved: [],
    // min: "",
    // max: "",
    // currency: "",
    //   "rates": {
    //     "min": 0,
    //     "max": 0,
    //     "currency": "USD",
    //     "time": "Hours"
    // },
    rates: {
      min: 0,
      max: 0,
      currency: "CAD",
    },
    individualHiring: false,
  },
  reducers: {
    setUserDetails: (state, action) => {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        countryCode,
        type,
        password,
        link,
        displayName,
        location,
        profilePic,
        goal,
        category,
        bio,
        skill,
        saved,
        individualHiring,
        // min,
        // max,
        // currency,
        rates,
      } = action.payload;
      state.firstName = firstName || "";
      state.lastName = lastName || "";
      state.email = email || "";
      state.phoneNumber = phoneNumber || "";
      state.countryCode = countryCode || "+234";
      state.type = type || "";
      state.password = password || "";
      state.link = link || "";
      state.displayName = displayName || "";
      state.location = location || "";
      state.profilePic = profilePic || "";
      state.goal = goal || "";
      state.category = category || "";
      state.bio = bio || "";
      // state.min = min || "";
      // state.max = max || "";
      // state.currency = currency || "";
      state.skill = skill || [];
      state.saved = saved || [];
      state.rates = rates || {};
      state.individualHiring = individualHiring || false;
    },
  },
});

export const { setUserDetails } = userSlice.actions;
export const selectUserDetails = (state) => state.user;
export default userSlice.reducer;
