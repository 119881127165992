export default function TheFutureOfHiring({ title, handleClick, buttonTitle }) {
  return (
    <div className="the-future-of-hiring">
      <div className="the-future-of-hiring-left">
        <div className="left-circle"></div>
      </div>

      <div className="the-future-of-hiring-middle">
        <header>
          <h3>{title}</h3>
        </header>
        <button className="cursor" onClick={() => handleClick}>
          {" "}
          {buttonTitle}
        </button>
      </div>

      <div className="the-future-of-hiring-right">
        <div className="right-circle"></div>
        <div className="middle-right-circle-container">
          <div className="middle-right-circle"></div>
        </div>
        <div className="right-circle"></div>
      </div>
    </div>
  );
}
