import { RxChevronLeft, RxChevronRight } from "react-icons/rx";
import ProductManagerPic from "../../assets/website/images/propuct manger image.png";

export default function ProductManager() {
  return (
    <div className="product-maneger">
      <div className="product-manager-image">
        <figure>
          <img src={ProductManagerPic} alt="" />
        </figure>
        <div>
          <h3>Makima Ackerman</h3>
          <p>Product Designer</p>
        </div>
      </div>
      <div className="slide-arrows">
        <button className="control cursor">
          <RxChevronLeft />
        </button>
        <button className="control cursor active-control">
          <RxChevronRight />
        </button>
      </div>
    </div>
  );
}
