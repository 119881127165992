import "../../../styles/dashboard/messages.scss";
import emptyState from "../../../assets/dashboard/icons/noMessages.png";
import EmptyState from "../saved/EmptyState";
import { GoDotFill } from "react-icons/go";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import send from "../../../assets/dashboard/icons/send.svg";
import { useEffect, useState } from "react";
import { selectActiveUser } from "../../../features/activeUserSlice";
import { useSelector} from 'react-redux';
import { APP_ID, APP_SECRET } from "../../../utils/constants";
import { ZIM } from 'zego-zim-web';
import ShowToast from "../../../common/website/Toast";
import { TimestampFormat, TruncateText, generateToken } from "../../../utils";
import { nameCase } from "../../../utils/FormatName";
import axiosInstance from "../../../services/AxiosConfig";
import { PulseLoader } from 'react-spinners';

ZIM.create({ appID: APP_ID });
let zim = ZIM.getInstance();

export default function Messages() {
  const activeUser = useSelector(selectActiveUser);
  const [toUser, setToUser] = useState({});
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [msg, setMsg] = useState('');
  const [messagesList, setMessagesList ] = useState([]);
  const [activeChatList, setActiveChatList ] = useState([])
  const [token, setToken] = useState();
  const [convoId, setConvoId] = useState('');
  const [chatLoading, setChatLoading] = useState(false);
  const [ userInfo, setUserInfo ] = useState({ userID: null, userName: ''})


  useEffect(() => {
    const _token = generateToken(activeUser?._id);
    setToken(_token);

    if(toUser && Object?.entries(toUser).length === 0){
      const data = localStorage.getItem('current-chat');
      const parsedData = JSON.parse(data);
      setToUser(parsedData);
    };

    let userObj = { userID: activeUser?._id, userName: `${activeUser?.firstname} ${activeUser?.lastname}`};
    setUserInfo(userObj)

  }, []);

  useEffect(() => {   
      // Set up and listen for the callback for receiving error codes. 
    if(token){ 
      zim.on('error', function (zim, errorInfo) {
        // console.log('error', errorInfo?.code, errorInfo?.message);
      });

      // Set up and listen for the callback for connection status changes.
      zim.on('connectionStateChanged', function (zim, { state, event, extendedData }) {
        // console.log('connectionStateChanged', state, event, extendedData);
        // When SDK logout occurred due to a long-time network disconnection, you will need to log in again. 
        if (state === 0 && event === 3) {
            zim.login(userInfo, token)
        }
      });

      // Set up and listen for the callback for token expires.
      zim.on('tokenWillExpire', function (zim, { second }) {
        let newToken = generateToken(activeUser?._id, 4000)
        // You can call the renewToken method to renew the token. 
        // To generate a new Token, refer to the Prerequisites.
        zim.renewToken(newToken)
          .then(function({ token }){
            zim.login(userInfo, token)
          })
          .catch(function(err){
              // Renew failed.
              const _token = generateToken(activeUser?._id);
              setToken(_token);
          })
        });

        zim.updateUserAvatarUrl(activeUser?.image)
        // updateUserAvatarUrl(userAvatarUrl: string)

        zim.login(userInfo, token)
          .then(() => {
            setIsLoggedin(true);
            let config = {
              // The conversation flag. If it is set to `null`, the flag is the latest conversation.
              nextConversation: null,
              // The number of conversations queried per page.
              count: 20
            };
    
            zim.queryConversationList(config)
              .then(({conversationList}) => {
                setActiveChatList(conversationList);
                zim.clearConversationUnreadMessageCount(
                  convoId, 0)
              }).catch((err) => {
                ShowToast({ type: 'warning' , message: err?.message })
              });       
    
            // Set up and listen for the callback for receiving one-to-one messages. 
            zim.on('receivePeerMessage', function (zim, { messageList, fromConversationID }) {
              // console.log('receivePeerMessage', messageList, fromConversationID);
              setMessagesList(messageList); 
              zim.queryConversationList(config)
              .then(({conversationList}) => {
                setActiveChatList(conversationList);
                zim.clearConversationUnreadMessageCount(
                  convoId, 0)
              }).catch((err) => { return });
            });
          })
          .catch((err) => {
            setIsLoggedin(false);
          }); 
      }

  }, [token]);

  const handleConversationClick = async(data) => {
    if(data === ''){ return }

    setChatLoading(true);
    try{
      const res = await axiosInstance.get(`/public/profile/${data}`);

      if(res?.status === 200){
        setToUser(res?.data?.data);

        let config = {
          nextMessage: null, // NextMessage is null on the first query.
          count: 30,
          reverse: false
        }

        if(isLoggedin){
          zim.queryHistoryMessage(data, 0, config )
          .then(({messageList}) => {
            setChatLoading(false);
            setMessagesList(messageList);
          }).catch((err) => {
            setChatLoading(false);
            ShowToast({ type: 'warning', message: 'Unable to get chat history at this time'})
          })
        }
      }
    }catch(err){
      setChatLoading(false);
      return
     }
  }

  const handleSendMessage = () => {
    if(!isLoggedin){
      ShowToast({ type: 'warning', text: 'Message feature temporarily unavailable'})
      return;
    }

    if(msg === ''){ return }
    const toConversationID = toUser?._id; // Peer user's ID.
    const conversationType = 0; // Message type; 1-on- chat: 0, in-room chat: 1, group chat:2 
    const config = { priority: 1 }; // Set priority for the message. 1: Low (by default). 2: Medium. 3: High.

    let messageTextObj = { type: 1, message: msg, extendedData: '' };
    let notification = {
        onMessageAttached: function(message) {
            // todo: Loading
        }
    }

    zim.sendMessage(messageTextObj, toConversationID, conversationType, config)
      .then(function ({ message }) {
          setMessagesList([...messagesList, message ]);
          setMsg('');
      })
      .catch(function (err) { return });
  }

  

  function ChatBody({ message }){
    return (
      <>
        {message?.senderUserID === activeUser?._id ?
            <div className="message-block senders-end">
              <div className="text">
                  <div className="message">
                    {message?.message}
                    <p className="time">{TimestampFormat(message?.timestamp)}</p>
                  </div>
              </div>
            </div> 
          :
            <div className="message-block receivers-end">
              <div className="text">
                <div className="msg-box">
                  <div className="message">
                    {message?.message}
                    <p className="time">{TimestampFormat(message?.timestamp)}</p>
                  </div>
                </div>
              </div>
            </div>
        }   
      </>
            
    )
  }


  return (
    <div className="messages-container job-details-container">
      <div className="details-row">
        <div className="message-summaries ">
            <div className="search-section">
              <input
                className="search-messages"
                type="search"
                placeholder="Search messages"
              />
            <div>
              {activeChatList?.map((activeChat, i) => (  
                <div className={activeChat?.conversationID === toUser?._id ? "active-user-message" :"user-message-active"} 
                key={activeChat?.conversationID} 
                onClick={() => {
                  handleConversationClick(activeChat?.conversationID);
                  setConvoId(activeChat?.conversationID);
                }}
                >
                    <div className="avatar">
                      <img src={activeChat?.conversationAvatarUrl} alt="user.avatar" />
                    </div>
                    <div className="text">
                      <div className="name-time">
                        <div className="name">
                          <span> {nameCase(activeChat?.conversationName)} </span>
                        </div>
                        <div className="time">{TimestampFormat(activeChat?.lastMessage?.timestamp)}</div>
                      </div>
                      <div className="msg-unread">
                        <p className="message col-span-6">{TruncateText(activeChat?.lastMessage?.message, 20)}</p>
                        {activeChat?.unreadMessageCount > 0 && <p className="message col-span-1" style={{ textAlign: "center"}}>{activeChat?.unreadMessageCount}</p>}
                      </div>
                    </div>
                </div>
              ))}
            </div>
          </div>        
        </div>

        <div className="job-details message-body">
          <div className="message-body-content">
            {chatLoading ? <div style={{ width: 'fit-content', margin: '35% auto 0 auto'}}><PulseLoader /></div> :
            <>
              {messagesList.length > 0 ? (
                <div>
                  {toUser &&
                  <div className="user-message">
                      <div className="avatar">
                        <img src={toUser?.image} alt="user.avatar" />
                        {/* <div className="online-sign"></div> */}
                      </div>
                      <div className="text">
                        <div className="name-time">
                          <div className="name">
                            <span> {nameCase(`${toUser?.firstname} ${toUser?.lastname}`)} </span>
                          </div>
                        </div>
                        <p className="message">{nameCase(toUser?.category || toUser?._company.name)}</p>
                      </div>
                      {/* <center className="date">Today</center> */}
                  </div>
                  }
                  <div className="chat-body">
                    {messagesList.map((message) => {
                      return (
                        <ChatBody 
                          message={message}
                          key={message.messageID} 
                          />
                      )
                    })}
                  </div>
                </div>
              ) : (
                <EmptyState
                  img={emptyState}
                  title={"No messages"}
                  subtitle={"All conversations will appear here"}
                />
              )}
            </>
            }
       
            <div className="text-box-container">
              <div className="text-box">
                <input type="text" value={msg} onChange={(e) => setMsg(e.target.value)} placeholder="Reply message" />

                <div className="emoji-button-section">
                  <div className="emoji pointer">
                    <HiOutlineEmojiHappy />
                  </div>
                  <div className="send-btn pointer" onClick={handleSendMessage}>
                    <img src={send} alt="send message" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


      

       














