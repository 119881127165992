import { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import "../../styles/website/FAQ.css";
export default function FAQforClient() {
  const [openItemIndex, setOpenItemIndex] = useState(null);

  let list = [
    {
      heading: "General Payments",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 1,
    },
    {
      heading: "Profile & Accounts",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 2,
    },
    {
      heading: "Client onboarding",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 3,
    },
    {
      heading: "Jobs",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 4,
    },
    {
      heading: "Paid Projects",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 5,
    },
    {
      heading: "General payment",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 6,
    },
    {
      heading: "General payment",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 7,
    },
    {
      heading: "General payment",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 8,
    },
    {
      heading: "General payment",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 9,
    },
    {
      heading: "General payment",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 10,
    },
    {
      heading: "General payment",
      title: "What is web design, and why is it important?",
      content:
        "Web design refers to the process of creating and arranging the visual element of a website . It's essential because it directly impacts user experience. A well-designed website not only looks appealing but also enhance usability and helps convey your brand's masssage effecietly ",
      id: 11,
    },
  ];

  const handleItemClick = (index) => {
    if (index === openItemIndex) {
      setOpenItemIndex(null);
    } else {
      setOpenItemIndex(index);
    }
  };
  return (
    <div className="faq-for-client">
      <div className="payment-for-client">
        <h4> Payment for client </h4>
        <h4> Payment for client </h4>
      </div>
      <div>
        <div className="heading-question-and-answers">
          {list.map((item, i) => (
            <>
              <div
                className="payment-for-client-left"
                onClick={() => handleItemClick(i)}
                key={item.id}
              >
                <div className="payment-for-client-left-heading">
                  <h4>{item.heading} </h4>
                </div>
                <div className="payment-for-client-right">
                  <div>
                    <div
                      className="question-with-answers-forclient"
                      onClick={() => handleItemClick(i)}
                    >
                      <button className="cursor">
                        {/* click */}{" "}
                        {i === openItemIndex ? (
                          <AiOutlineMinus className="arrowUp" />
                        ) : (
                          <AiOutlinePlus className="arrowDown" />
                        )}
                      </button>
                      <h4>{item.title}</h4>
                    </div>

                    <div>
                      <div className="answers-for-client">
                        {i === openItemIndex && <p>{item.content}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
