import { Link } from "react-router-dom";
import fingerTipImage from "../../assets/website/images/clientFingertip.png";
import { AiOutlineRight } from "react-icons/ai";

export default function ForTalent() {
  return (
    <div className="talent-at-your-finngertip-container-two">
      <figure className="talent-at-your-finngertip-right">
        <img src={fingerTipImage} alt="fingerTip" />
      </figure>
      <div className="talent-at-your-finngertip-left">
        <button className="forClient-button">For Talents</button>
        <div className="talent-at-your-finngertip-details-talent">
          <header>
            <h1 className="talent-at-fingertip-header">
              Find Amazing Opportunities
            </h1>
          </header>
          <p className="talent-at-fingertip-ptags-talent">
            {" "}
            Meet clients you're excited to work with and take your career or
            business to new heights
          </p>
        </div>

        <div className="talent-at-your-finngertip-left-details">
          <div>
            <header>
              <h6>Post a job</h6>{" "}
            </header>
            <p className="talent-at-fingertip-ptags-two">
              Register then verify after that post the job
            </p>
          </div>
          <div>
            <header>
              <h6>Choose a talents</h6>
            </header>
            <p className="talent-at-fingertip-ptags-two">
              Choose best talent who send proposal to you
            </p>
          </div>

          <div>
            <header>
              <h6>Start giving work</h6>{" "}
            </header>
            <p className="talent-at-fingertip-ptags-two">
              Give a job to the best takent you have choosen
            </p>
          </div>
        </div>

        <Link className="talent-learn-more">
          <p>Learn More</p>
          <AiOutlineRight />
        </Link>
      </div>
    </div>
  );
}
