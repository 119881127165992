import { useEffect, useRef, useState } from "react";

const TalentSearch = ({ searchTalent, setSearchTalent }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };
  const handleCheckboxClick = (event) => {
    // Prevent the click event from propagating to the dropdown container
    event.stopPropagation();
  };

  // Add a separate click handler for the select options
  const handleSelectOptionClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);


  return (
    <div className="dashboard-search-and-location-container-dashboard">
      <div className="dashboard-search-and-location">
        <div className="search-talent-container">
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7119 19.6225C15.5465 19.6225 19.4658 15.7033 19.4658 10.8686C19.4658 6.034 15.5465 2.11475 10.7119 2.11475C5.87726 2.11475 1.95801 6.034 1.95801 10.8686C1.95801 15.7033 5.87726 19.6225 10.7119 19.6225Z"
              stroke="#7F879E"
              stroke-width="1.38219"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.3872 20.5441L18.5443 18.7012"
              stroke="#7F879E"
              stroke-width="1.38219"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <input
            type="text"
            placeholder="Search talents by skill"
            value={searchTalent.searchByName}
            onChange={(e) =>
              setSearchTalent({ ...searchTalent, searchByName: e.target.value })
            }
            style={{
              width: "100%",
              backgroundColor: "#fafcff",
            }}
          />
        </div>

        <div className="vertical-line"></div>
        
        <div className="location-container">
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0886 12.6474C12.6764 12.6474 13.9636 11.3602 13.9636 9.77242C13.9636 8.18463 12.6764 6.89746 11.0886 6.89746C9.50085 6.89746 8.21368 8.18463 8.21368 9.77242C8.21368 11.3602 9.50085 12.6474 11.0886 12.6474Z"
              stroke="#7F879E"
              stroke-width="1.38219"
            />
            <path
              d="M3.36682 8.09504C5.1821 0.115176 17.0045 0.124391 18.8105 8.10425C19.8702 12.7853 16.9584 16.7476 14.4059 19.1987C12.5538 20.9863 9.62354 20.9863 7.76219 19.1987C5.21896 16.7476 2.30714 12.7761 3.36682 8.09504Z"
              stroke="#7F879E"
              stroke-width="1.38219"
            />
          </svg>
          <input
            type="text"
            placeholder="Location"
            value={searchTalent.searchByLocation}
            onChange={(e) =>
              setSearchTalent({
                ...searchTalent,
                searchByLocation: e.target.value,
              })
            }
            style={{
              width: "100%",
              backgroundColor: "#fafcff",
            }}
          />
        </div>
        <div className="dashboard-search-and-location-button">
          <button className="cursor">Search</button>
        </div>
      </div>
      {/* <div className="rate-and-flter-container">
        <div
          className="select-rate"
          onClick={handleSelectOptionClick}
          ref={dropdownRef}
        >
          <p>Rate</p>
          <svg
            width="9"
            height="6"
            viewBox="0 0 9 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.19997 0.612305H4.30621H0.799965C0.199965 0.612305 -0.100035 1.3373 0.324965 1.7623L3.56246 4.9998C4.08122 5.51855 4.92497 5.51855 5.44372 4.9998L6.67496 3.76855L8.68122 1.7623C9.09997 1.3373 8.79997 0.612305 8.19997 0.612305Z"
              fill="#1C2634"
            />
          </svg>
        </div>

        <div className="select-filter">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z"
              fill="#292D32"
            />
            <path
              d="M18 12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z"
              fill="#292D32"
            />
            <path
              d="M14 17.75H10C9.59 17.75 9.25 17.41 9.25 17C9.25 16.59 9.59 16.25 10 16.25H14C14.41 16.25 14.75 16.59 14.75 17C14.75 17.41 14.41 17.75 14 17.75Z"
              fill="#292D32"
            />
          </svg>
          <p>Filter</p>
        </div>
      </div>

      <div>
        {dropdownOpen && (
          <>
            <div className="popup-for-rate" onClick={handleCheckboxClick}>
              <div className="checkbox-and-price ">
                <input type="checkbox" value="" />
                <label>$25 - $50/hr</label>
              </div>
              <div className="checkbox-and-price">
                <input type="checkbox" value="" />
                <label>$50 - $75/hr</label>
              </div>
              <div className="checkbox-and-price">
                <input type="checkbox" value="" />
                <label>$27 - $120/hr</label>
              </div>
              <div className="checkbox-and-price">
                <input type="checkbox" value="" />
                <label>$120 - $150/hr</label>
              </div>
              <div className="checkbox-and-price">
                <input type="checkbox" value="" />
                <label>$150 - $200/hr</label>
              </div>

              <div className="checkbox-and-price">
                <input type="checkbox" value="" />
                <label>$200 +- $50/hr</label>
              </div>
            </div>
          </>
        )}
      </div> */}
    </div>
  );
};

export default TalentSearch;
