import BspNewsAndUpdateImage from "../../assets/website/images/bsp-news-and-image.svg";
import "../../styles/website/SingleBlogPage.css";

export default function SingleBblogHero() {
  return (
    <div>
      {/* <SingleBogPageSidebar/> */}
      <div className="single-blog-hero-container">
        <figure className="single-blog-hero-left">
          <img src={BspNewsAndUpdateImage} alt="" />
        </figure>
        <div className="single-blog-hero-right">
          <h1>Can AI Assistant Make Us Faster and More Creative?</h1>
          <p>
            Generate AI can increase productivity in some cases - here's how to
            make the best of it{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
