export default function StartYourTalent() {
  return (
    <div className="start-your-talent-container">
      <div className="start-your-talent-left">
        <div>
          <h4>Start your talent </h4>
          <h4>journey</h4>
          <button className="cursor">Get Started</button>
        </div>
      </div>
      <div className="start-your-talent-right">
        <div>
          <h4>Hire top </h4>
          <h4>Independents</h4>

          <button className="cursor">Hire Now</button>
        </div>
      </div>
    </div>
  );
}
