import { useNavigate } from "react-router-dom";
import ResetPasswordNavbar from "../../common/website/ResetPasswordNavbar";
import Button from "../../components/common/button";
import Input from "../../components/common/input";
import "../../components/auth/auth.scss";
import "../../styles/website/ResetPassword.css";
import ShowToast from "../../common/website/Toast";
import axiosInstance from "../../services/AxiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";

export default function ResetPassword() {
  const navigate = useNavigate();
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  async function handleResetPassword() {
    try {
      const response = await axiosInstance.post(
        "/authentication/forgot/request/",
        {
          email: userDetails.email,
        }
      );
      ShowToast({ type: "success", text: "Reset link successfully sent" });
      console.log("Reset successful", response.data, userDetails.email);
    } catch (error) {
      console.error("Error Resetting Password", error);
      const errorMessage =
        error.response?.data?.message || "Error Resetting Password";
      ShowToast({ type: "error", text: errorMessage });
    }
  }

  return (
    <div className="reset-password">
      <ResetPasswordNavbar />
      <div>
        <form className="authForm">
          <header className="authForm_header">Reset your password</header>
          <Input
            type="text"
            placeholder="Email"
            value={userDetails.email}
            onChange={(e) =>
              dispatch(
                setUserDetails({ ...userDetails, email: e.target.value })
              )
            }
          />
          <Button
            text="Send password reset link"
            fn={() => {
              handleResetPassword();
              setTimeout(() => {
                navigate("/auth/password/reset/new-password");
              }, 5000);
            }}
          />
          <div className="options">
            Don't have an account?{" "}
            <span onClick={() => navigate("/auth/register")}>Sign up</span>
          </div>
                
        </form>
      </div>
    </div>
  );
}
