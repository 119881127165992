import image from "../../assets/website/images/How to make playlist.svg";
export default function SecondCard() {
  return (
    <div className="second-card">
      <div className="second-page">
        <div className="second-page-top">
          <div>
            <button>How to</button>
            <header>
              <h3>Get started with PSP</h3>
            </header>
          </div>
        </div>
        <div className="second-page-buttom">
          <p className="date-time-section">
            <span className="date"> August 31, 2023 </span>•
            <span className="time"> 12 min read</span>
          </p>
          <div className="second-page-buttom-button-div">
            <button>Customer service </button>
            <button>Customer service </button>
          </div>
          <h4>How to get started PSP for clients</h4>
        </div>
      </div>

      <div className="second-page">
        <div className="second-page-top second-page-top2">
          <div>
            <header>
              <h3>What is Project Scope</h3>
            </header>
          </div>
        </div>
        <div className="second-page-buttom">
          <p className="date-time-section">
            <span className="date"> August 31, 2023 </span>•
            <span className="time"> 12 min read</span>
          </p>
          <div className="second-page-buttom-button-div">
            <button>Customer service </button>
            <button>Customer service </button>
          </div>
          <h4>What is project scope? 8 steps to define yours</h4>
        </div>
      </div>

      <div className="second-page">
        <div className="second-page-top second-page-top3">
          <img src={image} alt="" />
        </div>
        <div className="second-page-buttom">
          <p className="date-time-section">
            <span className="date"> August 31, 2023 </span>•
            <span className="time"> 12 min read</span>
          </p>
          <div className="second-page-buttom-button-div">
            <button>Customer service </button>
            <button>Customer service </button>
          </div>
          <h4>How to make a Makelist on TikTok and get more views</h4>
        </div>
      </div>
    </div>
  );
}
