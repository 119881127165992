import { createSlice } from "@reduxjs/toolkit";

const jobSlice = createSlice({
  name: "job",
  initialState: {
    role: "",
    location: "",
    tags: [],
    // tags: "",
    notice: "",
    duration: "",
    min: "",
    max: "",
    currency: "CAD",
    description: "",
    responsibilities: "",
    requirements: "",
    brief: "",
    display: true,
  },
  reducers: {
    setJobDetails: (state, action) => {
      const {
        role,
        location,
        tags,
        notice,
        duration,
        min,
        max,
        currency,
        description,
        responsibilities,
        requirements,
        brief,
        display,
      } = action.payload;
      state.role = role || "";
      state.location = location || "";
      state.tags = tags || [];
      // state.tags = tags || "";
      state.notice = notice || "";
      state.duration = duration || "";
      state.min = min || "";
      state.max = max || "";
      state.currency = currency || "";
      state.description = description || "";
      state.responsibilities = responsibilities || "";
      state.requirements = requirements || "";
      state.brief = brief || "";
      state.display = display || true;
    },
  },
});

export const { setJobDetails } = jobSlice.actions;
export const selectJobDetails = (state) => state.job;
export default jobSlice.reducer;
