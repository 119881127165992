import React from "react";
import VerticalLabelInput from "../common/input/verticalLabelInput";
import BackButton from "../common/button/backButton";
import Button from "../common/button";
import Location from "../../assets/website/icons/location.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";
import ShowToast from "../../common/website/Toast";

export default function ClientLocation({ prevStep, onNextStep }) {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  const handlePrev = () => {
    prevStep();
  };

  const handleNext = () => {
    if (userDetails.location.trim() === "") {
      ShowToast({ type: "error", text: "Please fill in a location" });
      return;
    }
    onNextStep();
  };

  return (
    <div>
      <div className="enterDetails_container">
        <div className="steps">Step 2/3</div>
        <h1>Where are you located?</h1>
        <span>Enter company location</span>
        <VerticalLabelInput
          label="location"
          icon={Location}
          placeholder="Enter location"
          value={userDetails.location}
          onChange={(e) =>
            dispatch(
              setUserDetails({ ...userDetails, location: e.target.value })
            )
          }
        />

        <div className="enterDetails_buttons">
          <BackButton fn={handlePrev} text="Back" />
          <Button fn={handleNext} text="Next" />
        </div>
      </div>
    </div>
  );
}
