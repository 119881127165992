import React, { useState } from "react";
import { InlineBorderLabel } from "../inlineBorderLabel";
import { GOOGLE_ICON } from "../../assets/website/constants";
import Button from "../common/button";
import Input from "../common/input";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../services/AxiosConfig";
import { ScaleLoader } from "react-spinners";
import ShowToast from "../../common/website/Toast";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";

export default function EmailSignUpForm({ onNextStep }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  const handleNext = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userDetails.email)) {
      ShowToast({ type: "error", text: "Kindly write a valid email address" });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        "/authentication/verify/email",
        {
          email: userDetails.email,
          type: userDetails.type,
        }
      );

      const successMessage = response.data.message || "OTP Sent Successful";

      console.log(
        "Email submitted successfully",
        response.data,
        userDetails.email,
        userDetails.type
      ); //Remove console

      ShowToast({ type: "success", text: successMessage });
      onNextStep();
    } catch (error) {
      setIsLoading(false);
      // if (error.response && error.response.data) {
      //   const responseData = error.response.data;
      //   const errorMessage = responseData.error || responseData.message;
      //   if (errorMessage) {
      //     // If the error response contains an error message, display it in a toast
      //     ShowToast({ type: "error", text: errorMessage });
      //     return;
      //   }
      //   ShowToast({ type: "error", text: "An error occurred" });
      // }
      const errorMessage =
        (error.response && error.response.data && error.response.data.error) ||
        "Please enter a valid Email address";
      ShowToast({ type: "error", text: errorMessage });
      console.error("Error submitting email", errorMessage); //Remove console
      console.log("error", error);
    }
    // setIsLoading(false);
  };
  return (
    <form className="authForm">
      <header>
        <div className="authForm_header">Let's get you started</div>
        {userDetails.type === "Client" ? (
          <div className="authForm_sub_header">
            Sign up with your company email address
          </div>
        ) : (
          <div className="authForm_sub_header">
            Sign up with your email address
          </div>
        )}
      </header>
      <Input
        type="text"
        value={userDetails.email}
        onChange={(e) =>
          dispatch(setUserDetails({ ...userDetails, email: e.target.value }))
        }
        placeholder={userDetails.type === "Client" ? "Company email" : "Email"}
      />
      <Button
        fn={handleNext}
        text={
          isLoading ? <ScaleLoader height={15} color="#ffffff" /> : "Continue"
        }
      />
      <section>
        <InlineBorderLabel label="or" />
        <div className="withGoogle cursor">
          <img src={GOOGLE_ICON} alt="" /> Continue with google
        </div>
        <div className="options">
          Already have an account?
          <span onClick={() => navigate("/auth/login")}>Log in</span>
        </div>
      </section>
    </form>
  );
}
