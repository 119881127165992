import { useState } from "react";
import Image from "../../assets/website/images/hiring.svg";
import SignUpModal from "./SignUpModal";

export default function Hire() {
  const [isModalVisible, setModalVisible] = useState(false);
  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  return (
    <div className="hire-container">
      {isModalVisible && <SignUpModal handleClick={closeModal} />}

      <div className="hire-container-detail">
        <header>
          <h4>Start Hiring Freelancer</h4>
        </header>
        <p className="subtitle">Begin your Hiring Journey with us </p>
        <button className="cursor" onClick={openModal}>
          Get started
        </button>
      </div>
      <div className="banner-img">
        <img src={Image} alt="" />
      </div>
    </div>
  );
}
