import React, { useState } from "react";
import { USER_ICON } from "../../assets/website/constants";
import Button from "../common/button";
import BorderIdentityTag from "../borderIdentityTag";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";
import ShowToast from "../../common/website/Toast";

export default function IdentityForm({ onNextStep }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedIdentity, setSelectedIdentity] = useState(null);
  const userDetails = useSelector(selectUserDetails);

  const handleNext = () => {
    if (!userDetails.type) {
      ShowToast({ type: "error", text: "Kindly select an option" });
      return;
    }
    onNextStep();
  };

  const handleSelect = (identityType) => {
    setSelectedIdentity(identityType);
    dispatch(setUserDetails({ type: identityType }));
  };

  return (
    <form className="authForm">
      <header className="authForm_header">What brings you to PSP</header>
      <BorderIdentityTag
        image={USER_ICON}
        header="I'm a client"
        description="Post opportunity and discover talents"
        isSelected={selectedIdentity === "Client"}
        onSelect={() => handleSelect("Client")}
        // onClick={() => dispatch(setUserDetails({ type: "Client" }))}
      />
      <BorderIdentityTag
        image={USER_ICON}
        header="I'm a talent"
        description="Find work and manage my freelance business"
        isSelected={selectedIdentity === "Talent"}
        onSelect={() => handleSelect("Talent")}
        // onClick={() => dispatch(setUserDetails({ type: "Talent" }))}
      />
      <Button fn={handleNext} text="Next" />
      <section>
        <div className="terms">
          By creating an account you agree with our{" "}
          <u>Terms of Service, Privacy Policy,</u>
        </div>
        <div className="options">
          Already have an account?{" "}
          <span onClick={() => navigate("/auth/login")}>Log in</span>
        </div>
      </section>
    </form>
  );
}
