import React from "react";
import "./styles.scss";
import {
  LOCATION,
  PRO,
  USER_ICON_BIG,
} from "../../../../../assets/website/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserDetails,
  setUserDetails,
} from "../../../../../features/userSlice";
import Globe from "../../../../../assets/website/icons/global.svg";
import { selectActiveUser } from "../../../../../features/activeUserSlice";

export default function DisplayDetails() {
  const userDetails = useSelector(selectUserDetails);
  const activeUser = useSelector(selectActiveUser);
  const dispatch = useDispatch();

  console.log("active", activeUser);

  const deleteSkill = (index) => {
    const updatedSkillList = userDetails.skill.filter((_, i) => i !== index);
    dispatch(
      setUserDetails({
        ...userDetails,
        skill: updatedSkillList,
      })
    );
  };

  console.log(userDetails.profilePic);

  return (
    <div className="displayDetailsBg">
      {userDetails.type === "Client" ? (
        <div className="displayDetails_container">
          <section className="displayDetails_img">
            {userDetails.profilePic ? (
              // <img
              //   src={
              //     userDetails.profilePic &&
              //     URL.createObjectURL(userDetails.profilePic)
              //   }
              //   alt=""
              //   style={{
              //     maxWidth: "106px",
              //     width: "100%",
              //     height: "106px",
              //     borderRadius: "50%",
              //   }}
              // />
              <img
                src={userDetails.profilePic}
                alt=""
                style={{
                  maxWidth: "106px",
                  width: "100%",
                  height: "106px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                src={USER_ICON_BIG}
                alt=""
                style={{
                  maxWidth: "106px",
                  width: "100%",
                  height: "106px",
                  borderRadius: "50%",
                }}
              />
            )}
            <div>
              <p>
                {userDetails.displayName === ""
                  ? "Display Name"
                  : userDetails.displayName}
              </p>
            </div>
          </section>
          <section>
            <header>Location</header>
            <span style={{ display: "flex", gap: "10px" }}>
              <img src={LOCATION} alt="" />
              <p>{userDetails.location === "" ? "" : userDetails.location}</p>
            </span>
          </section>
          <section>
            <header>Links</header>
            <span style={{ display: "flex", gap: "10px" }}>
              <img src={Globe} alt="" />
              <p>{userDetails.link === "" ? "" : userDetails.link}</p>
            </span>
          </section>
        </div>
      ) : (
        // TALENT
        <div className="displayDetails_container">
          <section className="displayDetails_img">
            {userDetails.profilePic ? (
              // <img
              //   src={
              //     userDetails.profilePic &&
              //     URL.createObjectURL(userDetails.profilePic)
              //   }
              //   alt=""
              //   style={{
              //     maxWidth: "106px",
              //     width: "100%",
              //     height: "106px",
              //     borderRadius: "50%",
              //   }}
              // />
              <img
                src={userDetails.profilePic}
                alt=""
                style={{
                  maxWidth: "106px",
                  width: "100%",
                  height: "106px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                src={USER_ICON_BIG}
                alt=""
                style={{
                  maxWidth: "106px",
                  width: "100%",
                  height: "106px",
                  borderRadius: "50%",
                }}
              />
            )}
            <div
              style={{ display: "flex", gap: "10px", justifyContent: "center" }}
            >
              <p>
                {userDetails.displayName === ""
                  ? "Display Name"
                  : userDetails.displayName}
              </p>{" "}
              <img src={PRO} alt="" />
            </div>
            <span>
              <p>
                {userDetails.category === ""
                  ? "Category"
                  : userDetails.category}
              </p>
            </span>
          </section>
          <section>
            <header>Skills</header>
            <div className="displayDetails_skill">
              {userDetails?.skill &&
                userDetails?.skill.map((skill, i) => (
                  <span
                    style={{ cursor: "pointer" }}
                    key={i}
                    onClick={() => deleteSkill(i)}
                  >
                    {skill}
                  </span>
                ))}
            </div>
          </section>
          <section>
            <header>Location</header>
            <span style={{ display: "flex", gap: "10px" }}>
              <img src={LOCATION} alt="" />
              <p>{userDetails.location === "" ? "" : userDetails.location}</p>
            </span>
          </section>
          <section>
            <header>Bio</header>
            <span>
              <p>{userDetails.bio === "" ? "" : userDetails.bio}</p>
            </span>
          </section>
        </div>
      )}
    </div>
  );
}
