import { formatTime } from "../../../utils/FormatTime";

export default function JobCard({ fn, job }) {
  return (
    <div className="job-card-container">
      <div className="card-header">
        <div className="company">
          <div className="icon">
            {" "}
            <img src={job?._company.image} alt="company.name" />{" "}
          </div>
          <div className="names">
            <p>{job?._company.name}</p>
            <h4
              style={{
                textTransform: "capitalize",
              }}
            >
              {job?.role}
            </h4>
          </div>
        </div>

        <div className="time-posted">
          Posted {formatTime(job?.createdAt)} ago
        </div>
      </div>

      <div className="skills-budget-section">
        <div className="skills">
          {job?.tags.map((tag, i) => (
            <div key={i} className="skill">
              {tag}
            </div>
          ))}
        </div>
        <div className="time-budget">
          <div className="time">{job?.notice}</div>
          <div className="budget">
            {job?.currency}
            {job?.min}/hr - {job?.currency}
            {job?.max}/hr
          </div>
        </div>
      </div>

      <div className="line"></div>

      <div className="job-card-footer buttons">
        <b></b>
        {/* <b>Not Interested</b> */}
        <button onClick={() => fn()} className="pointer">
          View job
        </button>
      </div>
    </div>
  );
}
