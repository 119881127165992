export default function EmptyState({ img, title, subtitle, btnText, fn }) {
  return (
    <div className="dashboard-profile-list-certifications-empty">
      <img src={img} alt={title} />
      <h3>{title}</h3>
      <p>{subtitle}</p>
      {btnText && <button onClick={() => fn()}>{btnText}</button>}
    </div>
  );
}
