import averter from "../../assets/website/images/Avatar Image.svg";
import verified from "../../assets/website/icons/verified.svg";
import avatar from "../../assets/website/images/avatar.jpg";
import "../../styles/website/SearchTalentpage.css";
import { AiFillStar } from "react-icons/ai";
import { useState } from "react";
import SignUpModal from "./SignUpModal";
import figma from "../../assets/website/icons/skills/figma.png";
import xd from "../../assets/website/icons/skills/xd.png";

export default function SearchTalentCard() {
  const searchResult = [
    {
      name: "Design King",
      location: "California, USA",
      rate: "$60-$70",
      skills: ["Web designer", "Figma", "Adobe XD", "Photoshop"],
      tools: [
        {
          name: "Figma",
          icon: figma,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
      ],
      image: avatar,
    },
    {
      name: "Joy Designs",
      location: "New York, USA",
      rate: "$50-$65",
      skills: ["Graphic design", "Illustrator", "InDesign"],
      tools: [
        {
          name: "Figma",
          icon: figma,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
      ],
    },
    {
      name: "Pixel Picasso",
      location: "London, UK",
      rate: "$70-$80",
      skills: ["UI/UX design", "Sketch", "InVision"],
      tools: [
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
      ],
    },
    {
      name: "Artistic Visionary",
      location: "Paris, France",
      rate: "$55-$75",
      skills: [
        "Illustrator",
        "Photoshop",
        "Concept Art",
        "Illustrator",
        "Photoshop",
      ],
      tools: [
        {
          name: "Figma",
          icon: figma,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
        {
          name: "Adobe XD",
          icon: xd,
        },
      ],
    },
    {
      name: "Digital Maestro",
      location: "Sydney, Australia",
      rate: "$65-$75",
      skills: ["Motion graphics", "After Effects", "Premiere Pro"],
    },
    {
      name: "Visual Alchemist",
      location: "Toronto, Canada",
      rate: "$50-$60",
      skills: ["Graphic design", "Logo design", "Canva"],
    },
    {
      name: "Color Wizard",
      location: "Berlin, Germany",
      rate: "$45-$55",
      skills: ["Color theory", "Palette design", "Color grading"],
    },
    {
      name: "Pixel Ninja",
      location: "Tokyo, Japan",
      rate: "$70-$85",
      skills: ["Pixel art", "Pixel animation", "Pixel sprite design"],
    },
    {
      name: "Illustration Maestro",
      location: "Rio de Janeiro, Brazil",
      rate: "$50-$60",
      skills: ["Digital illustration", "Procreate", "Clip Studio Paint"],
    },
  ];

  const [isModalVisible, setModalVisible] = useState(false);
  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="search-talent-card" style={{ marginBottom: "100px" }}>
      {isModalVisible && <SignUpModal handleClick={closeModal} />}
      <div className="fourth-card">
        {searchResult.length
          ? searchResult.map((talent, i) => {
              return (
                <div
                  className="fourth-page search-talent-card-section search-talent-card "
                  key={i}
                >
                  <button className="favorite-btn cursor">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.62 18.8101C11.28 18.9301 10.72 18.9301 10.38 18.8101C7.48 17.8201 1 13.6901 1 6.6901C1 3.6001 3.49 1.1001 6.56 1.1001C8.38 1.1001 9.99 1.9801 11 3.3401C12.01 1.9801 13.63 1.1001 15.44 1.1001C18.51 1.1001 21 3.6001 21 6.6901C21 13.6901 14.52 17.8201 11.62 18.8101Z"
                        stroke="#7F879E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <div className="fourth-card-image-section">
                    <figure className=" user-avatar">
                      <img
                        src={talent.image || averter}
                        alt=""
                        className="user-img"
                      />
                    </figure>
                    <div className="verified-symbol">
                      <img src={verified} alt="" className="" />
                    </div>
                  </div>

                  <div className="fourth-page-buttom card-details-section">
                    <div className="designer-name-and-location text-center">
                      <h4>{talent.name} </h4>
                      <p className="icon-and-location">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.5 11.6917C11.9359 11.6917 13.1 10.5276 13.1 9.0917C13.1 7.65576 11.9359 6.4917 10.5 6.4917C9.06405 6.4917 7.89999 7.65576 7.89999 9.0917C7.89999 10.5276 9.06405 11.6917 10.5 11.6917Z"
                            stroke="#7F879E"
                            stroke-width="1.5"
                          />
                          <path
                            d="M3.51666 7.57508C5.15832 0.358417 15.85 0.36675 17.4833 7.58342C18.4417 11.8167 15.8083 15.4001 13.5 17.6168C11.825 19.2334 9.17499 19.2334 7.49166 17.6168C5.19166 15.4001 2.55832 11.8084 3.51666 7.57508Z"
                            stroke="#7F879E"
                            stroke-width="1.5"
                          />
                        </svg>
                        Califonia, USA
                      </p>
                    </div>
                    <div className="designer-skill-rate-section">
                      <div className="rate-rating-section">
                        <div className="rate">
                          <p>$60 - $65/hr</p>
                        </div>
                        <div className=" skills-section  shadow">
                          <p className="centered">
                            <AiFillStar className="star" /> <span>3.0</span>
                          </p>
                        </div>
                      </div>
                      <div className="profile-content skills-section">
                        {talent.skills
                          ? talent.skills.map((skill, i) => {
                              if (i < 3) {
                                return (
                                  <div key={i}>
                                    <p>{skill} </p>
                                  </div>
                                );
                              }
                            })
                          : null}
                        {talent.skills && talent.skills.length > 3 && (
                          <div>
                            <p className="tool centered">
                              +{talent?.skills?.length - 3}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="profile-content skill-section">
                        {talent.tools?.map((tool, i) => {
                          if (i < 3) {
                            return (
                              <div key={i}>
                                <p className="tool">
                                  {" "}
                                  <img src={tool.icon} alt="" /> {tool.name}{" "}
                                </p>
                              </div>
                            );
                          }
                        })}

                        {talent.tools && talent.tools.length > 3 && (
                          <div>
                            <p className="tool centered">
                              +{talent?.tools?.length - 3}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      className="search-talent-card-button cursor"
                      onClick={openModal}
                    >
                      Get in Touch
                    </button>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
