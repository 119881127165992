import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
export default function WhyClientAndTalent() {
  return (
    <div className="whyClientAndTalent">
      <header>
        <h4>Why Clients And Talents Trust PSP</h4>
      </header>
      <div className="bigQuotation">
        <BiSolidQuoteAltLeft />
      </div>

      <div className="join-BSP">
        <p>
          <RiDoubleQuotesL />
          <span>Joining the PSP was a game-changer</span> for me. As a
          freelencer in search of diverse and meaningful opportunities, I was
          amazed by the seamless experience they privided.
          <RiDoubleQuotesR />
        </p>
      </div>
    </div>
  );
}
