import "../../../styles/dashboard/application.scss";

// Components
import MainLayout from "../../../layouts/mainLayout";
import ApplicationTable from "../../../components/dashboard/Application/ApplicationTable";

const ApplicationPage = () => {
  return (
    <MainLayout navTitle={"Application"}>
      <ApplicationTable />
    </MainLayout>
  );
};

export default ApplicationPage;
