import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { useSelector } from "react-redux";
import Successful from "../../../assets/dashboard/icons/successful.svg";
import { selectActiveUser } from "../../../features/activeUserSlice";
import { ScaleLoader } from "react-spinners";

const PayTalentModal = (props) => {
  const [amountValue, setAmountValue] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const activeUser = useSelector(selectActiveUser);

  // Pay a talent
  const handlePayTalent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.put(`/project/pay`, {
        project: props.projectId,
        amount: amountValue,
      });
      if (response) {
        setPaymentSuccess(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error?.response?.data?.error || "Error fetching Certificates";
      ShowToast({ type: "error", text: errorMessage });
    }
  };

  return (
    <div className="modal-container" onClick={props.handleClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="close-signup-modal">
          <button onClick={props.handleClick} className="pointer">
            <IoClose />
          </button>
        </div>

        {!paymentSuccess ? (
          <div className="change-password-form-container">
            <p>Pay for project</p>
            <div>
              <input
                type="text"
                defaultValue={0}
                className="fundWallet-input"
                value={amountValue}
                placeholder="Ex. 200.55"
                onChange={(e) => setAmountValue(e.target.value)}
              />
            </div>
            <div className="change-password-submit-button-container">
              <button
                type="submit"
                className="cursor"
                onClick={handlePayTalent}
              >
                {isLoading ? (
                  <ScaleLoader height={15} color="#ffffff" />
                ) : (
                  "Pay"
                )}
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="modal-image-and-writeup">
              <figure>
                <img src={Successful} alt="success" />
              </figure>
              <h2 style={{ color: "rgba(24, 23, 37, 1)" }}>
                Payment was Successful
              </h2>
            </div>
            <div className="signup-and-login-buttons">
              <button className="cursor" onClick={props.handleClick}>
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PayTalentModal;
