// import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineLogout } from "react-icons/hi";
import "../../../styles/dashboard/navBar.scss";
import { BsPatchExclamation } from "react-icons/bs";
import Prompt from "./Prompt";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { logout } from "../../../features/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { selectUserDetails, setUserDetails } from "../../../features/userSlice";
import { selectActiveUser } from "../../../features/activeUserSlice";

export default function MenuOptions() {
  const [showLogoutPrompt, setShowLogOutPrompt] = useState(false);
  const userDetails = useSelector(selectUserDetails);
  const activeUser = useSelector(selectActiveUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleLogout() {
    //process logout
    dispatch(setUserDetails(""));
    toast.success("LogOut Successful");
    navigate("/");
    setShowLogOutPrompt(false);
  }

  return (
    <>
      <div
        className="menu-options-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="profile-section">
          <div className="avatar">
            <img src={activeUser?.image} alt="Profile" />
          </div>
          <div className="names">
            <h4>
              {activeUser?.firstName} {activeUser?.lastName}
            </h4>
            <p className="subtitle">{userDetails?.type}</p>
          </div>
        </div>

        {/* <div className="item">
          <div className="icon">
            <AiOutlineSetting />
          </div>
          <div className="text">Setting</div>
        </div> */}
        <div className="item">
          <div className="icon">
            <BsPatchExclamation />
          </div>
          <div className="text">Help Center</div>
        </div>
        <div className="item" onClick={() => setShowLogOutPrompt(true)}>
          <div className="icon">
            <HiOutlineLogout />
          </div>
          <div className="text">Log out</div>
        </div>
      </div>

      <div className="modals">
        {showLogoutPrompt && (
          <Prompt
            action={"log out"}
            fn={() => {
              handleLogout();
            }}
            close={() => setShowLogOutPrompt(false)}
          />
        )}
      </div>
    </>
  );
}
