import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { IoClose } from "react-icons/io5";
// import { useNavigate } from "react-router-dom";

const ChangePassWordModal = (props) => {
  // const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .min(6, "New password must be at least 6 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  // Handle form submission
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission logic here
    console.log("Submitted values:", values);
    resetForm();
  };

  return (
    <div className="modal-container" onClick={props.handleClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="close-signup-modal">
          <button onClick={props.handleClick} className="pointer">
            <IoClose />
          </button>
        </div>
        <div className="change-password-form-container">
          <p>Change Password</p>
          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="postJob-form-container">
                  <div className="postJob-input-container">
                    <label htmlFor="oldPassword">Old Password</label>
                    <Field
                      type="password"
                      name="oldPassword"
                      id="oldPassword"
                      className="postJob-form-input"
                      placeholder="Enter your old password"
                      value={values.oldPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="oldPassword"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="postJob-input-container">
                    <label htmlFor="newPassword">New Password</label>
                    <Field
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      placeholder="Enter your new password"
                      className="postJob-form-input"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="postJob-input-container">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm your new password"
                      className="postJob-form-input"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div className="change-password-submit-button-container">
                  <button type="submit" className="cursor">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePassWordModal;
