import React from "react";
import "react-phone-number-input/style.css";
import "./styles.scss";
import PhoneInput from "react-phone-number-input";

export default function CountryCodeInput({ onChange, value }) {
  return (
    <div className="input">
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="CA"
        placeholder="Enter phone number"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
