import { useSelector } from "react-redux";
import { DashboardApplications } from "../../components/dashboard/dashboard/DashboardApplications";
import { DashboardChart } from "../../components/dashboard/dashboard/DashboardChart";
import { DashboardPayouts } from "../../components/dashboard/dashboard/DashboardPayouts";
import { DashboardProfile } from "../../components/dashboard/dashboard/DashboardProfile";
import { DashboardRequest } from "../../components/dashboard/dashboard/DashboardRequest";
import { DashboardSummary } from "../../components/dashboard/dashboard/DashboardSummary";
import { selectUserDetails } from "../../features/userSlice";
import MainLayout from "../../layouts/mainLayout";
import "../../styles/dashboard/dashboard.scss";
import { selectActiveUser } from "../../features/activeUserSlice";
import { nameCase } from "../../utils/FormatName";
import DashboardRecommendedTalents from "../../components/dashboard/dashboard/DashboardRecommendedTalents";

export default function Dashboard() {
  const userDetails = useSelector(selectUserDetails);
  const activeUser = useSelector(selectActiveUser);

  return (
    <MainLayout navTitle={"Dashboard"}>
      <p className="dashboard-main-username">
        Welcome,{" "}
        <span>
          {nameCase(activeUser?.firstname)} {nameCase(activeUser?.lastname)}
        </span>{" "}
        👋🏼
      </p>
      <DashboardSummary />
      <div className="dashboard-chart-and-profile-container">
        <DashboardChart />
        {userDetails.type.toLowerCase() === "talent" && <DashboardProfile />}
      </div>
      {userDetails.type.toLowerCase() === "client" && (
        <DashboardRecommendedTalents />
      )}
      {userDetails.type.toLowerCase() === "talent" && (
        <>
          <DashboardApplications />
          <div className="dashboard-request-and-payouts-container">
            <DashboardRequest />
            <DashboardPayouts />
          </div>
        </>
      )}
    </MainLayout>
  );
}
