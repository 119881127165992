import { Link } from "react-router-dom";
import "../../styles/website/PspForTalent.css";

export default function PspforClientHero4() {
  return (
    <div className="hero4">
      <div>
        <header>
          <h4>
            The <span className="psp-secondary">all-in-one</span> platform for
            CRM talent hiring
          </h4>
        </header>

        <p className="how-it-works-prompt">
          Are you a Talent?{" "}
          <Link className="explore-bsp-for-talents" to="/pspfortalent">
            {" "}
            Explore PSP for Talent
          </Link>
        </p>
      </div>
    </div>
  );
}
