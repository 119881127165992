import { useNavigate } from "react-router-dom";
import leftImage from "../../assets/website/images/hero-left-image.svg";
import rightImage from "../../assets/website/images/hero-right-image.svg";

function Hero() {
  const Navigate = useNavigate();
  return (
    <div className="hero-section">
      <div className="hero">
        <figure>
          <img src={leftImage} alt="pic" width="" />
        </figure>
        <div className="hero-middle">
          <div className="news">
            <p>News! </p>
            <p>Talent markeplace ✨ </p>
          </div>
          <div>
            <header>
              <h1>
                Empowering creativity with CRM talents for
                <span className="seamless"> Seamless</span> collaboration.
              </h1>
            </header>
          </div>

          <div className="subtitle">
            Elevate Your Projects with Top Talents.
          </div>

          <div className="searchBar">
            <input type="text" placeholder="What are you looking for?" />
            <button className="cursor">Search</button>
          </div>
          <div className="popular-search">
            <p>Popular searches: </p>
            <div className="popular-search-buttons">
              <button
                className="cursor"
                onClick={() => Navigate("/searchtalent")}
              >
                Admin
              </button>
              <button
                className="cursor"
                onClick={() => Navigate("/searchtalent")}
              >
                Designers
              </button>{" "}
              <button
                className="cursor"
                onClick={() => Navigate("/searchtalent")}
              >
                Consultants
              </button>{" "}
              <button
                className="cursor"
                onClick={() => Navigate("/searchtalent")}
              >
                Engineer
              </button>{" "}
              <button
                className="cursor"
                onClick={() => Navigate("/searchtalent")}
              >
                Associates
              </button>
            </div>
          </div>
        </div>
        <figure>
          <img src={rightImage} alt="" />
        </figure>
      </div>
    </div>
  );
}
export default Hero;
