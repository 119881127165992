import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Switch from "@mui/material/Switch";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectJobDetails, setJobDetails } from "../../features/jobSlice";

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
  responsibilities: Yup.string().required("Responsibilities is required"),
  requirements: Yup.string().required("Requirements is required"),
  brief: Yup.string().required("Brief is required"),
  displayPublic: Yup.boolean(),
});

const StepTwo = ({ onNextStep, onPrevStep }) => {
  const jobDetails = useSelector(selectJobDetails);
  const dispatch = useDispatch();

  const initialValues = {
    description: jobDetails.description,
    responsibilities: jobDetails.responsibilities,
    requirements: jobDetails.requirements,
    brief: jobDetails.brief,
    displayPublic: jobDetails.display,
  };

  const handleInputChange = (e, handleChange) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    handleChange(e);
    dispatch(setJobDetails({ ...jobDetails, [name]: fieldValue }));
  };

  const handleOnSubmit = (values) => {
    console.log(values);
    onNextStep();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form>
          <div className="postJob-form-container">
            <div className="postJob-input-container">
              <label htmlFor="description">Description</label>
              <div className="postJob-form-textarea">
                <Field
                  as="textarea"
                  name="description"
                  id="description"
                  rows={6}
                  cols={50}
                  value={values.description}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                  maxLength={1000}
                />
                <p>{values.description.length}/1000</p>
              </div>
              <ErrorMessage
                name="description"
                component="div"
                className="error"
              />
            </div>

            <div className="postJob-input-container">
              <label htmlFor="responsibilities">Responsibilities</label>
              <div className="postJob-form-textarea">
                <Field
                  as="textarea"
                  name="responsibilities"
                  id="responsibilities"
                  rows={6}
                  cols={50}
                  value={values.responsibilities}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                />
                <p>{values.responsibilities.length}/1000</p>
              </div>
              <ErrorMessage
                name="responsibilities"
                component="div"
                className="error"
              />
            </div>

            <div className="postJob-input-container">
              <label htmlFor="requirements">Requirements</label>
              <div className="postJob-form-textarea">
                <textarea
                  name="requirements"
                  id="requirements"
                  rows={6}
                  cols={50}
                  value={values.requirements}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                />
                <p>{values.requirements.length}/1000</p>
              </div>
              <ErrorMessage
                name="requirements"
                component="div"
                className="error"
              />
            </div>

            <div className="postJob-input-container">
              <label htmlFor="brief">Brief</label>
              <div className="postJob-form-textarea">
                <textarea
                  name="brief"
                  id="brief"
                  rows={6}
                  cols={50}
                  value={values.brief}
                  onChange={(e) => handleInputChange(e, handleChange)}
                  onBlur={handleBlur}
                />
                <p>{values.brief.length}/500</p>
              </div>
              <ErrorMessage name="brief" component="div" className="error" />
            </div>

            <div className="postJob-input-container">
              <div className="postJob-switch-input">
                <div>
                  <p>Public</p>
                  <p>Do your want job to seen by users?</p>
                </div>
                <Field name="displayPublic">
                  {({ field }) => (
                    <Switch
                      {...field}
                      // sx={{
                      //   "&.MuiSwitch-root .Mui-checked": {
                      //     color: "white",
                      //     backgroundColor: 'red',
                      //   },
                      // }}
                      defaultChecked
                      // color="#134271"
                      size="large"
                      inputProps={{ "aria-label": "toggle agree terms" }}
                      checked={values.displayPublic}
                      onChange={(e) => handleInputChange(e, handleChange)}
                    />
                  )}
                </Field>
              </div>
              <ErrorMessage
                name="displayPublic"
                component="div"
                className="error"
              />
            </div>
          </div>
          <div className="postJob-submit-button-container-2">
            <button onClick={onPrevStep} className="cursor">
              Back
            </button>
            <button type="submit" className="cursor">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepTwo;
