import React from 'react'
import RegisterForm from '../../../components/regForm'

export default function Register() {
  return (
    <div>
     <RegisterForm />
    </div>
  )
}
