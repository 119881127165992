import { Link } from "react-router-dom";
import fingerTipImage from "../../assets/website/images/fingertip img.svg";

export default function ForClient() {
  return (
    <div className="talent-at-your-finngertip-container">
      <div className="talent-at-your-finngertip-left">
        <button className="forClient-button">For Client</button>
        <div className="talent-at-your-finngertip-details">
          <header>
            <h1 className="talent-at-fingertip-header">
              Talent At Your Fingertip
            </h1>
          </header>
          <p className="talent-at-fingertip-ptags">
            {" "}
            An easy way to find freelancers with extraordinary talents and skils
            to help you get your job done
          </p>
        </div>

        <div className="talent-at-your-finngertip-left-details">
          <div>
            <header>
              <h6>Post a job</h6>{" "}
            </header>
            <p className="talent-at-fingertip-ptags">
              Register then verify after that post the job
            </p>
          </div>
          <div>
            <header>
              <h6>Choose a talent</h6>
            </header>
            <p className="talent-at-fingertip-ptags">
              Choose best talent who send proposal to you
            </p>
          </div>

          <div>
            <header>
              <h6>Start giving work</h6>{" "}
            </header>
            <p className="talent-at-fingertip-ptags">
              Give a job to the best takent you have choosen
            </p>
          </div>
        </div>

        <Link className="talent-learn-more">
          <p>Learn More</p>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.42504 17.2248C7.26671 17.2248 7.10837 17.1664 6.98337 17.0414C6.74171 16.7998 6.74171 16.3998 6.98337 16.1581L12.4167 10.7248C12.8167 10.3248 12.8167 9.67477 12.4167 9.27477L6.98337 3.84144C6.74171 3.59977 6.74171 3.19977 6.98337 2.95811C7.22504 2.71644 7.62504 2.71644 7.86671 2.95811L13.3 8.39144C13.725 8.81644 13.9667 9.39144 13.9667 9.99977C13.9667 10.6081 13.7334 11.1831 13.3 11.6081L7.86671 17.0414C7.74171 17.1581 7.58337 17.2248 7.42504 17.2248Z"
              fill="white"
            />
          </svg>
        </Link>
      </div>

      <figure className="talent-at-your-finngertip-right">
        <img src={fingerTipImage} alt="fingerTip" />
      </figure>
    </div>
  );
}
