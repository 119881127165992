import React from "react";
import CompanyLogo from "../../../assets/dashboard/icons/CompanyLogo.svg";
import EmptyState from "../saved/EmptyState";
import ColoredButton from "../../common/button/coloredButtons";
import { Link, useNavigate } from "react-router-dom";
import Request from "../../../assets/dashboard/icons/request.svg";

const request = [
  // {
  //   companyLogo: CompanyLogo,
  //   companyName: "Gojek",
  //   requestDesc: "Has  made a request to work with you",
  //   btnText: "View Offer",
  // },
  // {
  //   companyLogo: CompanyLogo,
  //   companyName: "Gojek",
  //   requestDesc: "Has  made a request to work with you",
  //   btnText: "View Offer",
  // },
  // {
  //   companyLogo: CompanyLogo,
  //   companyName: "Gojek",
  //   requestDesc: "Has  made a request to work with you",
  //   btnText: "View Offer",
  // },
  // {
  //   companyLogo: CompanyLogo,
  //   companyName: "Gojek",
  //   requestDesc: "Has  made a request to work with you",
  //   btnText: "View Offer",
  // },
];

export const DashboardRequest = () => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-request-container">
      {request.length ? (
        <>
          <div className="dashboard-request-header-container">
            <h5>Requests</h5>
            <Link to="#">View All</Link>
          </div>
          <div className="dashboard-request-list-container">
            {request.map((request, i) => (
              <div key={i} className="dashboard-request-list-item-container">
                <div className="dashboard-request-company-details">
                  <img src={request.companyLogo} alt={request.companyName} />
                  <div className="dashboard-request-company-name">
                    <h5>{request.companyName}</h5>
                    <p>{request.requestDesc}</p>
                  </div>
                </div>
                <ColoredButton
                  text={request.btnText}
                  padding="3px 12px"
                  backgroundColor="rgba(19, 66, 113, 0.1)"
                  width="90px"
                  color="#134271"
                  fontWeight="600"
                  lineHeight="21.6px"
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <EmptyState
          subtitle={"Apply for your next project"}
          img={Request}
          btnText={"Complete profile"}
          fn={() => navigate("/dashboard/profile")}
        />
      )}
    </div>
  );
};
