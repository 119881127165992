import image from "../../assets/website/images/start hiring.svg";

export default function StartHiring() {
  return (
    <div className="hire-container-secondPage">
      <div className="hire-container-detail-secondPage-left">
        <div>
          <header>
            <h3>Start Hiring and get Hired</h3>
          </header>
          <p>Begin your Hiring Journey or Secure Your Next Gig </p>
          <button className="cursor">Get started</button>
        </div>
      </div>
      <div className="hire-container-detail-secondPage-right">
        <img src={image} alt="" />
      </div>
    </div>
  );
}
