import axios from "axios";
import { ErrorHandler } from "./ErrorHandler";

// export default function AxiosConfig() {

const token = localStorage.getItem("pspUserToken");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    common: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      // Token: localStorage.getItem("psp-tks"),
    },
  },
});

axiosInstance.interceptors.response.use(
  (response) => handleSuccess(response),
  (error) => {
    ErrorHandler(error);
  }
);

function handleSuccess(response) {
  return response;
}

export default axiosInstance;
// return instanceConfig;
// }
