import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/mainLayout";
import "../../../styles/dashboard/wallet.scss";
import axiosInstance from "../../../services/AxiosConfig";
import ShowToast from "../../../common/website/Toast";
import { useLocation } from "react-router-dom";

const PaymentCheckout = () => {
  const location = useLocation();
  const [confirmPayment, setConfirmPayment] = useState("");

  useEffect(() => {
    const extractIdFromUrl = (url) => {
      const urlSearchParams = new URLSearchParams(url.search);
      return urlSearchParams.get("id");
    };

    const handleConfirmPayment = async (transactionId) => {
      try {
        const response = await axiosInstance.post("/stripe/confirm", {
          transaction_id: transactionId,
        });
        const res = response.data.data;
        setConfirmPayment(res);
        console.log("id", res.transaction_id);
        ShowToast({ type: "success", text: "Payment successful" });
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error ||
          "An error occurred during wallet funding";
        ShowToast({ type: "error", text: errorMessage });
      }
    };

    const transactionId = extractIdFromUrl(location);

    if (transactionId) {
      handleConfirmPayment(transactionId);
    }
  }, [location]);

  return (
    <MainLayout navTitle={"Payment Checkout"}>
      <div>
        {confirmPayment.status === "successful" ? (
          <p>Payment Successful</p>
        ) : (
          <p>Retry Payment</p>
        )}
      </div>
    </MainLayout>
  );
};

export default PaymentCheckout;
