import { useNavigate } from "react-router-dom";
import ResetPasswordNavbar from "../../common/website/ResetPasswordNavbar";
import Button from "../../components/common/button";
import PasswordInput from "../../components/common/input/passwordInput";
import "../../styles/website/ResetPassword.css";
import { useState } from "react";
import ShowToast from "../../common/website/Toast";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../features/userSlice";
import axiosInstance from "../../services/AxiosConfig";
import { ScaleLoader } from "react-spinners";

export default function ResetYourPassword() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [indicator, setIndicator] = useState(new Array(5).fill());
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  const handleNext = async () => {
    if (userDetails.password.length < 8) {
      ShowToast({ type: "error", text: "Please fill in all required fields" });
      return;
    }
    setIsLoading(true);
    try {
      const response = await axiosInstance.put(
        "/authentication/forgot/new/password",
        {
          password: userDetails.password,
        }
      );
      ShowToast({ type: "success", text: "Password Reset Successfully" });
      console.log("Reset successful", response.data, userDetails.email);
      navigate("/auth/login");
    } catch (error) {
      console.error("Error Resetting Password", error);
      const errorMessage =
        error.response?.data?.message || "Error Resetting Password";
      ShowToast({ type: "error", text: errorMessage });
    }
    setIsLoading(false);
  };

  const calculatePasswordStrength = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const length = password.length;

    return { hasUppercase, hasLowercase, hasNumber, length };
  };

  const getStrengthLabel = () => {
    const { hasUppercase, hasLowercase, hasNumber, length } =
      calculatePasswordStrength(userDetails.password);
    const strength = (hasUppercase + hasLowercase + hasNumber) / 3;

    if (length < 8) {
      return "Very Weak";
    } else if (strength < 0.6) {
      return "Weak";
    } else if (strength < 0.8) {
      return "Strong";
    } else {
      return "Very Strong";
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;

    const { hasUppercase, hasLowercase, hasNumber, length } =
      calculatePasswordStrength(password);
    // Calculate the password strength
    const strength = (hasUppercase + hasLowercase + hasNumber) / 3;

    // Update the indicator state based on the calculated strength
    const filledIndicators = Array.from(
      { length: 5 },
      (_, i) => i < Math.ceil(strength * 5) && length >= (i + 1) * 2
    );
    setIndicator(filledIndicators);
    if (length <= 12) {
      dispatch(setUserDetails({ ...userDetails, password }));
    }
  };
  return (
    <div className="reset-password">
      <ResetPasswordNavbar />
      <div>
        <form className="authForm">
          <header>
            <div className="authForm_header">Reset your password</div>
          </header>

          <PasswordInput
            placeholder="password"
            value={userDetails.password}
            onChange={handlePasswordChange}
          />
          <div>
            <div className="passwordIndicator">
              <div className="passwordIndicator_Container">
                <div className="passwordIndicator_inputContainer">
                  {indicator.map((isFilled, i) => (
                    <div
                      key={i}
                      className={`indicator-item ${isFilled ? "filled" : ""}`}
                    />
                  ))}
                </div>
                <span>{getStrengthLabel()}</span>
              </div>
              <span>
                Password must be at least 8 characters with a combination of
                letters and numbers.
              </span>
            </div>
          </div>
          <PasswordInput
            placeholder="password"
            value={userDetails.password}
            onChange={(e) =>
              dispatch(
                setUserDetails({ ...userDetails, password: e.target.value })
              )
            }
          />

          <Button
            fn={handleNext}
            text={
              isLoading ? (
                <ScaleLoader height={15} color="#ffffff" />
              ) : (
                "Reset Password"
              )
            }
          />
          <section>
            <div className="options">
              Remembered your password?
              <span onClick={() => navigate("/auth/login")}>Log in</span>
            </div>
          </section>
        </form>
      </div>
    </div>
  );
}
