import twitter from "../../assets/website/icons/twiter.svg";
import linkedin from "../../assets/website/icons/Linkedin.svg";
import facebook from "../../assets/website/icons/facebook.svg";
import instagram from "../../assets/website/icons/instagram.svg";
import "../../styles/website/SingleBlogPage.css";

export default function SingleBlogPageSidebar() {
  return (
    <div className="single-blog-sidebar">
      <figure className="cursor">
        <img src={twitter} alt=" twitter" />
      </figure>
      <figure className="cursor">
        <img src={linkedin} alt="linkedin" />
      </figure>
      <figure className="cursor">
        <img src={facebook} alt="facebook" />
      </figure>
      <figure className="cursor">
        <img src={instagram} alt="instagram" />
      </figure>
    </div>
  );
}
