import React from "react";
import "./styles.scss"

export function InlineBorderLabel({label}) {
  return (
    <div className=" labelContainer py-2">
      <div className="line"></div>
      <span className="label">{label}</span>
      <div className="line"></div>
    </div>
  );
}
