import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../../features/userSlice";
import GalleryAdd from "../../../assets/website/icons/galleryadd.svg";
import ShowToast from "../../../common/website/Toast";
// import { Image, Transformation, CloudinaryContext } from "cloudinary-react";

const ImgInput = ({ label, title }) => {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const api_key = "322777641976667";
  const cloud_name = "dwnhhyp1p";
  const preset_key = "business_solution";
  // const [image, setImage] = useState("");

  // const handleProfilePicUpload = (event) => {
  //   const file = event.target.files[0];
  //   dispatch(setUserDetails({ ...userDetails, profilePic: file }));
  //   console.log("Selected file:", file);
  // };

  const handleProfilePicUpload = () => {
    const cloudinaryWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloud_name,
        apiKey: api_key,
        uploadPreset: preset_key,
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info.secure_url);
          dispatch(
            setUserDetails({
              ...userDetails,
              profilePic: result.info.secure_url,
            })
          );
        } else if (error) {
          const errorMessage = error || "Error uploading image";
          ShowToast({ type: "error", text: errorMessage });
        }
      }
    );
    cloudinaryWidget.open();
  };

  return (
    // <div>
    //   <label
    //     style={{
    //       fontSize: "14px",
    //       letterSpacing: "-2%",
    //       fontWeight: "500",
    //       color: "#848FAC",
    //     }}
    //   >
    //     Add a photo to help build connection
    //     <div
    //       id="imageUpload"
    //       style={{
    //         display: "flex",
    //         gap: "10px",
    //         color: "#1C2634",
    //         border: "1px solid #1C2634",
    //         borderRadius: "12px",
    //         padding: "12px 10px",
    //         margin: "15px 0",
    //         width: "40%",
    //         cursor: "pointer",
    //       }}
    //       onChange={handleProfilePicUpload}
    //     >
    //       <img src={GalleryAdd} alt="upload" />
    //       <p>Upload profile photo</p>
    //       <input
    //         type="file"
    //         name="image"
    //         accept="image/*"
    //         style={{ display: "none" }}
    //       />
    //     </div>
    //   </label>
    // </div>
    <div>
      <label
        style={{
          fontSize: "14px",
          letterSpacing: "-2%",
          fontWeight: "500",
          color: "#848FAC",
        }}
        onClick={handleProfilePicUpload}
      >
        {label}
        <div
          id="imageUpload"
          style={{
            display: "flex",
            gap: "10px",
            color: "#1C2634",
            border: "1px solid #1C2634",
            borderRadius: "12px",
            padding: "12px 10px",
            margin: "15px 0",
            width: "40%",
            cursor: "pointer",
          }}
        >
          <img src={GalleryAdd} alt="upload" />
          <p>{title}</p>
          {/* <input
            type="file"
            name="image"
            accept="image/*"
            style={{ display: "none" }}
          /> */}
        </div>
      </label>
      {/* {userDetails.profilePic && (
        <CloudinaryContext cloudName={cloud_name}>
          <Image publicId={userDetails.profilePic}>
            <Transformation width="150" height="150" crop="thumb" />
          </Image>
        </CloudinaryContext>
      )} */}
    </div>
  );
};

export default ImgInput;
