import Footer from "../../common/website/Footer";
import Navigation from "../../common/website/Navbar";
import OurBlog from "../../components/website/OurBlog";
import TheFutureOfHiring from "../../components/website/TheFutureOfHiring";
import PspforClientHero4 from "../../components/website/PspforClientHero4";
import PspForClientCentent from "../../components/website/PspForClientContent";

export default function PspForClient() {
  return (
    <div>
      <Navigation />
      <PspforClientHero4 />
      <PspForClientCentent />
      {/* <PspForClientContent/> */}
      <TheFutureOfHiring
        title="Step into the future of hiring innovation."
        buttonTitle=" Start hiring"
      />
      <OurBlog leftHeader="Our Blog" rightHeader="See more" />
      <Footer />
    </div>
  );
}
