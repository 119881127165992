import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import { selectUserDetails } from "../../../features/userSlice";
import axiosInstance from "../../../services/AxiosConfig";
import { selectActiveUser } from "../../../features/activeUserSlice";

const DashboardChartFilter = ({ filterDate, onSelect, isSelected }) => {
  return (
    <div
      onClick={onSelect}
      className={`${
        isSelected
          ? "dashboard-chart-filter-selected"
          : "dashboard-chart-filter"
      }`}
    >
      <p>{filterDate}</p>
    </div>
  );
};

export const DashboardChart = () => {
  const userDetails = useSelector(selectUserDetails);
  const activeUser = useSelector(selectActiveUser);
  const [selectedFilter, setSelectedFilter] = useState("1M");
  const [data, setData] = useState([
    { name: "01", value: 0 },
    { name: "02", value: 0 },
    { name: "03", value: 0 },
    { name: "04", value: 0 },
    { name: "05", value: 0 },
    { name: "06", value: 0 },
    { name: "07", value: 0 },
    { name: "08", value: 0 },
    { name: "09", value: 0 },
    { name: "10", value: 0 },
    { name: "11", value: 0 },
    { name: "12", value: 0 },
    { name: "13", value: 0 },
  ]);

  const handleSelectFilter = (filter) => {
    setSelectedFilter(filter);
  };

  // Get Chart
  useEffect(() => {
    const handleGetChart = async () => {
      try {
        const response = await axiosInstance.get(`/visit`);
        const responseData = response.data;
        const newData = data.map((item, index) => ({
          ...item,
          value: responseData?.data[index]?.count,
        }));
        setData(newData);
      } catch (error) {
        console.log(error);
      }
    };

    handleGetChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="dashboard-chart-container"
      style={{
        width: `${
          activeUser?.type.toLowerCase() === "client" ? "100% !important" : ""
        }`,
      }}
    >
      <div className="dashboard-chart-heading">
        <h5>
          {activeUser?.type.toLowerCase() === "client"
            ? "Profile Viewed"
            : "Job Viewed"}
        </h5>
        <div className="dashboard-chart-filter-container">
          <DashboardChartFilter
            filterDate="1M"
            isSelected={selectedFilter === "1M"}
            onSelect={() => handleSelectFilter("1M")}
          />
          <DashboardChartFilter
            filterDate="3M"
            isSelected={selectedFilter === "3M"}
            onSelect={() => handleSelectFilter("3M")}
          />
          <DashboardChartFilter
            filterDate="6M"
            isSelected={selectedFilter === "6M"}
            onSelect={() => handleSelectFilter("6M")}
          />
          <DashboardChartFilter
            filterDate="1Y"
            isSelected={selectedFilter === "1Y"}
            onSelect={() => handleSelectFilter("1Y")}
          />
        </div>
      </div>
      <BarChart
        width={688}
        height={256}
        padding={{ left: 30, right: 30 }}
        data={data}
        barSize={30}
        // className="custom-bar-chart"
      >
        <XAxis
          dataKey="name"
          scale="point"
          padding={{ left: 10, right: 10 }}
          axisLine={false}
          tickMargin={20}
          tickLine={false}
        />
        <YAxis
          orientation="left"
          axisLine={false}
          tickMargin={30}
          domain={[0, 400]}
          tickLine={false}
        />
        <Tooltip />
        <Bar
          dataKey="value"
          fill="#3276FA"
          background={{ fill: "#F0F7FF" }}
          radius={8}
        />
      </BarChart>
    </div>
  );
};
