import AuthForm from "../../../components/auth";
import "./styles.scss";

const Login = () => {
  return (
    <div>
      <AuthForm />
    </div>
  );
};

export default Login;
