import postToday from "../../assets/website/images/post today.svg";

export default function PostToday() {
  return (
    <div className="post-today-container">
      <div className="post-today-left">
        <div className="post-today-details">
          <header>
            <h3 className="post-today-details-header">
              Post A Job Listing Today, Hire Tomorrow
            </h3>
          </header>

          <p className="">
            {" "}
            We're Here To Support You Every Step Of The Way.From Your Initial
            Idea To The Final Delivery.Simply Post Your Job And You'll Begin
            Recieving Proposals
          </p>
        </div>

        <button className="post-a-job cursor">Post a job</button>
      </div>

      <figure className="post-today-right">
        <img src={postToday} alt="fingerTip" />
      </figure>
    </div>
  );
}
