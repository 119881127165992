import React, { useEffect, useState } from "react";
import CompanyLogo from "../../../assets/dashboard/icons/CompanySettingsLogo.svg";
import axiosInstance from "../../../services/AxiosConfig";

const BasicInfo = () => {
  const [profileData, setProfileData] = useState(null); // State variable to hold the fetched profile data

  const settingsInput = [
    {
      inputName: "First Name",
      inputDesc: "Your first name",
      placeholder: "John",
      inputType: "text",
      key: "firstname",
    },
    {
      inputName: "Last Name",
      inputDesc: "Your last name",
      placeholder: "Osusu",
      inputType: "text",
      key: "lastname",
    },
    {
      inputName: "Company name",
      inputDesc: "Your company name",
      placeholder: "Tech Cloud",
      inputType: "text",
      key: "companyName",
    },
    {
      inputName: "Location",
      inputDesc: "Your company Location",
      placeholder: "Nigeria",
      inputType: "text",
      key: "location",
    },
    {
      inputName: "Phone Number",
      inputDesc: "Your registered phone number",
      placeholder: "234 9078908902",
      inputType: "tel",
      key: "phoneNumber",
    },
    {
      inputName: "Email",
      inputDesc: "Your registered email address",
      placeholder: "hubb@gmail.com",
      inputType: "email",
      key: "email",
    },
  ];

  // Get profile
  useEffect(() => {
    const handleGetProfile = async () => {
      try {
        const response = await axiosInstance.get(`/profile`);
        const res = response.data.data;
        setProfileData(res); // Set the fetched profile data to the state variable
      } catch (error) {
        console.log(error);
      }
    };

    handleGetProfile();
  }, []);

  // Handler for updating input values
  const handleInputChange = (e, key) => {
    const { value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  return (
    <div className="basic-info-container">
      <div>
        <img src={CompanyLogo} alt="company logo" />
        {settingsInput.map((input, i) => (
          <div key={i} className="basic-info-input-container">
            <div className="basic-info-field-container">
              <p>{input.inputName}</p>
              <p>{input.inputDesc}</p>
            </div>
            <div className="basic-info-input">
              <input
                type={input.inputType}
                placeholder={input.placeholder}
                value={profileData ? profileData[input.key] : ""}
                onChange={(e) => handleInputChange(e, input.key)} // Call handleInputChange with the key as an argument
                disabled={!profileData} // Disable input until data is fetched
              />
            </div>
          </div>
        ))}
        <button>Save changes</button>
      </div>
    </div>
  );
};

export default BasicInfo;
