import React from "react";
import MainLayout from "../../../layouts/mainLayout";
import ProjectsTable from "../../../components/dashboard/projects/ProjectsTable";
import "../../../styles/dashboard/project.scss";

export const Projects = () => {
  return (
    <MainLayout navTitle={"Projects"}>
      <ProjectsTable />
    </MainLayout>
  );
};
