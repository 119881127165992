import React from "react";
import "./styles.scss";

export default function GoalCard({ icon, text, value, onChange, checked }) {
  return (
    <div className="card_container">
      <div className="icon_holder">
        {/* <label> */}
          <img src={icon} alt="" />
          <input
            type="radio"
            name="goal"
            value={value}
            checked={checked}
            onChange={onChange}
          />
        {/* </label> */}
      </div>
      <h4>{text}</h4>
    </div>
  );
}
