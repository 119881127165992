import React, { useState } from "react";
import MainLayout from "../../../layouts/mainLayout";
import BasicInfo from "../../../components/dashboard/settings/BasicInfo";
import Notification from "../../../components/dashboard/settings/Notification";
import Security from "../../../components/dashboard/settings/Security";
import "../../../styles/dashboard/settings.scss";

export const Settings = () => {
  const [currentTabs, setCurrentTabs] = useState(
    {
      basicInfo: true,
    },
    {
      notification: false,
    },
    {
      security: false,
    }
  );
  return (
    <MainLayout navTitle={"Settings"}>
      <div className="settings-tabs-container">
        <span
          style={{
            borderBottom: currentTabs.basicInfo ? "1px solid #134271" : "",
          }}
          onClick={() => setCurrentTabs({ basicInfo: true })}
        >
          <p>Basic information </p>
        </span>
        <span
          style={{
            borderBottom: currentTabs.notification ? "1px solid #134271" : "",
          }}
          onClick={() => setCurrentTabs({ notification: true })}
        >
          <p>Notification</p>
        </span>
        <span
          style={{
            borderBottom: currentTabs.security ? "1px solid #134271" : "",
          }}
          onClick={() => setCurrentTabs({ security: true })}
        >
          <p>Security</p>
        </span>
      </div>
      {currentTabs.basicInfo &&
        !currentTabs.notification &&
        !currentTabs.security && <BasicInfo />}
      {!currentTabs.basicInfo &&
        currentTabs.notification &&
        !currentTabs.security && <Notification />}
      {!currentTabs.basicInfo &&
        !currentTabs.notification &&
        currentTabs.security && <Security />}
    </MainLayout>
  );
};
