import getPaidCommission from "../../assets/website/images/get-paid-commision.svg";
import nextOpportunity from "../../assets/website/images/find-next-opportunity.svg";
import manageContract from "../../assets/website/images/manage-contract.svg";

export default function PspForTalentContent() {
  return (
    <div className="psp-For-Talent-Centent">
      <div className="talent-content-rows">
        <div className="telent-content-rows-right writeups">
          <h3>Seamless payment withdrawal</h3>

          <p>
            Post your opportunity in 5 easy steps and we'll match you with the
            perfect freelancers for your project.
          </p>
          <button className="cursor">Create account</button>
        </div>
        <figure className="telent-content-rows-left">
          <img src={getPaidCommission} alt="get paid commission-free" />
        </figure>
      </div>

      <div className="middle-lines">
        <svg
          width="440"
          height="152"
          viewBox="0 0 440 152"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.927795 151.04C0.927795 109.742 34.3946 76.1821 75.692 75.9956L364.215 75.0634C405.605 74.9702 439.072 41.4102 439.072 0.0195312"
            stroke="#718AAD"
            stroke-width="1.39833"
            stroke-dasharray="1.86 1.86"
          />
        </svg>
      </div>

      <div className="talent-content-rows">
        <figure className="telent-content-rows-right">
          <img src={nextOpportunity} alt="get matches" />
        </figure>
        <div className="telent-content-rows-left writeups">
          <h3>Find your next opportunity</h3>
          <p>
            Get connected with flexible, remote opportunities, tailored to what
            you're looking for.
          </p>
          <button className="cursor">Find opportunities</button>
        </div>
      </div>

      <div className="middle-lines">
        <svg
          width="442"
          height="152"
          viewBox="0 0 442 152"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_871_30611)">
            <path
              d="M440.072 151.02C440.072 109.723 406.605 76.1626 365.308 75.9761L76.7852 75.0439C35.3946 74.9507 1.92781 41.3907 1.92781 0"
              stroke="#718AAD"
              stroke-width="1.39833"
              stroke-dasharray="1.86 1.86"
            />
          </g>
          <defs>
            <clipPath id="clip0_871_30611">
              <rect
                width="440.009"
                height="151.02"
                fill="white"
                transform="translate(0.995544)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div className="talent-content-rows">
        <div className="telent-content-rows-right writeups">
          <h3>Manage contracts and payments</h3>
          <p>
            Built-in contracts to streamline your workflow , and keep all of
            your projects in one place.
          </p>
          <button className="cursor">Discover talents</button>
        </div>
        <figure className="telent-content-rows-left">
          <img src={manageContract} alt="manage contract" />
        </figure>
      </div>
    </div>
  );
}
